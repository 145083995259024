PersistentConnection = Class.create();
PersistentConnection.interrupted = false;
PersistentConnection.closing = false;
PersistentConnection.prototype = {
	initialize: function(url, options) {
		this.url = url;
		this.options = Object.assign({
			onDisconnect:		null,
			onConnect:			null,
			connectionRetry:	5
		}, options);

		this.observers = {};

		this.last = null;
		this.timestamp = 0;
		this.enabled = false;
		this.aborted = false;
		this.busy = false;

		this.retries = 0;

		this.start = null;
		this.ping = null;

		this.offline = false;

		window.addEventListener('offline', () => {
			if (this.busy) {
				this.disable();

				if (this.interval) window.clearInterval(this.interval);
				if (this.request) this.request.abort();

				this.ping = null;
				this.last = null;
			}
		});

		window.addEventListener('online', () => {
			this.connect();
		});

		window.addEventListener('beforeunload', () => {
			if (Settings.debug) console.debug('PersistentConnection', 'closing due to navigation');
			PersistentConnection.closing = true;
		});

		window.addEventListener('pagehide', () => {
			if (Settings.debug) console.debug('PersistentConnection', 'closing due to page is being hidden');
			PersistentConnection.closing = true;
		})
	},

	observe: function(name, callback) {
		this.observers[name] = callback;
	},

	connect: function() {
		if (this.busy) {
			return;
		}

		if (this.url) {
			if (Settings.debug) console.debug('PersistentConnection', 'open', this.timestamp, this.url);

			this.busy = true;

			this.request = new Ajax.Request(this.url, {
				parameters:		{ timestamp: this.timestamp },
				method: 		'post',
				evalJSON: 		'force',

				onSuccess: 		this.response.bind(this),
				onAbort:		this.abort.bind(this),
				onFailure: 		this.failure.bind(this),
				onInteractive: 	function(transport) {
									this.retries = 0;
									this.update();

//									if (Settings.debug) console.debug('PersistentConnection', 'interactive', this.timestamp, new Date().getTime());
									this.last = new Date().getTime();
									this.enable();
								}.bind(this),
			});

			this.aborted = false;

			this.start = new Date().getTime();

			this.ping = null;
			this.interval = window.setInterval(this.check.bind(this), 1000);
		}
	},

	check: function() {
		var end = new Date().getTime();

		if (this.last) {
			if (end - this.last > 6 * 1000) {
				if (Settings.debug) console.debug('PersistentConnection', 'data', (end - this.last) + 'ms since last data was received');
				this.disable();

				if (this.interval) window.clearInterval(this.interval);
				if (this.request) this.request.abort();

				this.ping = null;
				this.last = null;
				return;
			}
		}

		if (this.ping) {
			if (end - this.ping > 15 * 1000) {
				if (Settings.debug) console.debug('PersistentConnection', 'sleep detected', (end - this.ping) + 'ms since last ping');
				this.disable();

				if (this.interval) window.clearInterval(this.interval);
				if (this.request) this.request.abort();

				this.ping = null;
				this.last = null;
				return;
			}

			if (end - this.start > 2 * 60 * 1000) {
				if (Settings.debug) console.debug('PersistentConnection', 'stalled connection detected', (end - this.start) + 'ms since last open');
				this.disable();

				if (this.interval) window.clearInterval(this.interval);
				if (this.request) this.request.abort();

				this.ping = null;
				this.last = null;
				return;
			}
		}

		this.ping = end;
	},

	enable: function() {
		if (Settings.debug) console.debug('PersistentConnection', 'enable');

		if (!this.enabled) {
			if (Settings.debug) console.debug('PersistentConnection', 'interrupted=false');
			PersistentConnection.interrupted = false;
			Application.connectionStatusUpdate(_CONNECTION_SUCCESS, 'long-polling');

			if (this.options.onConnect) {
				this.options.onConnect();
			}

			this.enabled = true;
		}
	},

	disable: function() {
		if (Settings.debug) console.debug('PersistentConnection', 'disable');

		if (this.enabled) {
			if (Settings.debug) console.debug('PersistentConnection', 'interrupted=true');
			PersistentConnection.interrupted = true;
			Application.connectionStatusUpdate(_CONNECTION_OFFLINE);

			if (this.options.onDisconnect) {
				this.options.onDisconnect();
			}

			this.enabled = false;
		}
	},

	response: function(transport) {
		this.busy = false;

		if (this.interval) window.clearInterval(this.interval);

		if (transport.status == 200) {
			if (Settings.debug) console.debug('PersistentConnection', 'done');

			var response = transport.responseJSON;
			if (response) {
				if (response.timestamp) {
					if (Settings.debug) console.debug('PersistentConnection', 'timestamp received', response.timestamp);
					this.timestamp = response.timestamp;
				}

				if (response.commands) {
					for (var i = 0; i < response.commands.length; i++) {
						if (Settings.debug) console.debug('PersistentConnection', 'command received', response.commands[i].command);

						if (this.observers[response.commands[i].command]) {
							this.observers[response.commands[i].command](typeof response.commands[i].payload != 'undefined' ? response.commands[i].payload : null);
						}
					}
				}
			}

			if (Settings.debug) console.debug('PersistentConnection', 'reconnecting...');
			this.connect();
		} else {
			/* 
				Because some browsers will fire this event when closing the page, with a status of 0
				delay the handling of this case until the pagehide event can fire so we can detect
				if it is due to the page closing or a network error.  
			*/

			if (Settings.debug) console.debug('PersistentConnection', 'response with status=0', transport);

			setTimeout(() => {
				if (PersistentConnection.closing) {
					if (Settings.debug) console.debug('PersistentConnection', 'closed');
					return;
				}

				if (Settings.debug) console.debug('PersistentConnection', 'error', 'status = ' + transport.status + ', retries = ' + this.retries + ', delay = ' + Math.min(60, this.options.connectionRetry * (this.retries + 1)));

				this.retries++;
				this.update();

				this.disable();
				window.setTimeout(this.connect.bind(this), Math.min(60, this.options.connectionRetry * this.retries) * 1000);
			}, 100);
		}
	},

	abort: function() {
		this.busy = false;

		if (!this.aborted) {
			this.aborted = true;

			if (Settings.debug) console.debug('PersistentConnection', 'aborted');
			this.failure();
		}
	},

	failure: function() {
		this.busy = false;

		if (this.interval) window.clearInterval(this.interval);

		if (Settings.debug) console.debug('PersistentConnection', 'failure, retries = ' + this.retries + ', delay = ' + Math.min(60, this.options.connectionRetry * (this.retries + 1)));

		this.retries++;
		this.update();
		this.disable();
		window.setTimeout(this.connect.bind(this), Math.min(60, this.options.connectionRetry * this.retries) * 1000);
	},

	update: function() {
		if (Settings.debug) console.debug('PersistentConnection', 'checking network state');

		if (this.retries > 3) {
			if (this.offline == false) {
				Application.connectionStatusUpdate(_CONNECTION_OFFLINE);
				Application.connectionStatusShow();

				if (Settings.debug) console.debug('PersistentConnection', 'show offline warning');
			}

			this.offline = true;
		}

		else {
			if (this.offline == true) {
				Application.connectionStatusUpdate(_CONNECTION_SUCCESS, 'long-polling');
				if (Settings.debug) console.debug('PersistentConnection', 'hide offline warning');
			}

			this.offline = false;
		}
	}
};