Widgets.Rate = Class.create(Widgets.Base, {
    name:		'rate',

    defaults:	{
        type:		'percentage',
        grading:    ''
    },

    css: `
        body .widget.rate { position: relative; }

        body .widget.rate .value {
            font-size: 2em;
            padding: 6px;
            text-align: center;
        }

        body .widget.rate .indicator {
            background: #e6e6e6;      
            border-radius: 4px;
            min-height: 6px;       
        }

        body .widget.rate .needle {
            height: 12px;
            background: var(--colors-named-accent);
            border-radius: 4px;      
            width: var(--percentage);       
        }

        body .widget.rate[data-grading="green"] .needle {
            background: var(--colors-green);
        }

        body .widget.rate[data-grading="orange"] .needle {
            background: var(--colors-orange);
        }

        body .widget.rate[data-grading="red"] .needle {
            background: var(--colors-red-darker);
        }

        body .widget.rate h3 {
            text-align: center;
            padding: 8px;
            font-size: 0.9em;            
        }

    `,

    initWidget: function() {
        this.dataset.grading = this.options.grading;
        this.dataset.type = this.options.type;

        this.value = new Element('div');
        this.value.classList.add('value');
        this.value.textContent = this.options.type == 'percentage' ? Formatter.percentage(this.options.value) : this.options.value;
        this.insert(this.value);

        this.indicator = new Element('div');
        this.indicator.classList.add('indicator');
        this.insert(this.indicator)

        if (this.options.type == 'percentage') {
            this.indicator.style.setProperty('--percentage', (this.options.value > 2 ? this.options.value : 0) + '%');
            this.indicator.innerHTML = `<div class='needle'></div>`;
        }

        if (this.options.title) {
            this.title = new Element('h3').update(this.options.title);
            this.insert(this.title);
        }
    }
});
