(function() {
	var buffer = '';
	var timer = null;
	var first = null;

	function onKeyPress(e) {
		function trim(s) {
			var l=0; var r=s.length -1;
			while(l < s.length && s[l] == ' ')
			{	l++; }
			while(r > l && s[r] == ' ')
			{	r-=1;	}
			return s.substring(l, r+1);
		}

		var target = e.target.tagName.toLowerCase();
		if (target == 'input' || target == 'textarea') {
			return;
		}

		if (timer) {
			window.clearTimeout(timer);
		}

		if (!first) {
			first = typeof e.timeStamp == 'number' ? e.timeStamp : e.timeStamp.getTime();
		}

		var timestamp = typeof e.timeStamp == 'number' ? e.timeStamp : e.timeStamp.getTime();

		if (timestamp - first < 400) {
			if (e.keyCode == Event.KEY_RETURN) {
				if (buffer.length > 4) {
					document.fire("input:barcode", buffer.trim());
				}

				buffer = '';
				first = null;

				return;
			}

			buffer += String.fromCharCode(e.charCode);
		}

		timer = window.setTimeout(onTimeout, 500);
	}

	function onTimeout() {
		var timestamp = (new Date()).getTime();

		/* On Chrome 50+ the event.timeStamp is relative to performance.navigationStart */
		if (window.performance) {
			if (first < performance.timing.navigationStart) {
				timestamp = performance.now();
			}
		}

		if (timestamp - first < 400 + 500) {
			if (buffer.length > 4) {
				document.fire("input:barcode", buffer.trim());
			}
		}

		buffer = '';
		first = null;
		timer = null;
	}

	Event.observe(document, 'keypress', onKeyPress);
})();