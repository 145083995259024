
CSS.insert(`
    .widget.dropdownPanel { display: inline-block; }
    .widget.dropdownPanel > .button { position: relative; cursor: pointer; -webkit-user-select: none; }
    .widget.dropdownPanel > .frame { display: none; position: absolute; z-index: 60; }
    .widget.dropdownPanel.open > .frame { display: block; }
    .widget.dropdownPanel.open > .frame > .panel { overflow: hidden; min-height: 60px; min-width: 200px; outline: none; }
    
    .widget.dropdownPanel.defaultStyle > .button { font-size: 0.8em; font-weight: var(--font-weight-bold); text-transform: var(--font-transform); text-align: left; border: 1px solid #eaeaea; border-radius: 4px; background: #fff; color: #000; }
    .widget.dropdownPanel.open.defaultStyle > .button, .widget.dropdownPanel.defaultStyle > .button:hover { border: 1px solid #d0d0d0; background: linear-gradient(to top, rgb(230,230,230) 16%, rgb(245,245,245) 100%); }
    .widget.dropdownPanel.defaultStyle > .button:active { background: #eaeaea; }
    .widget.dropdownPanel.defaultStyle > .button > div > .container { display: block; padding: 8px 10px; margin: 0 20px 0 0; text-shadow: 0px -1px 0px rgba(255,255,255,0.5); }
    .widget.dropdownPanel.defaultStyle > .button > div > .container::before { position: absolute; top: 50%; right: 0; width: 20px; line-height: 20px; height: 20px; margin-top: -10px; display: block; color: #888; text-align: center; text-shadow: 0px -1px 0px rgba(255,255,255,0.5); content: '⌵'; font-family: SalonWidgets; font-size: 12px; }
    .widget.dropdownPanel.defaultStyle > .button > div > .container::after { position: absolute; top: 0; right: 0; bottom: 0; width: 20px; display: block; content: ''; border-left: 1px solid #eaeaea; }
    .widget.dropdownPanel.open.defaultStyle > .button > div > .container::after, .widget.dropdownPanel .button:hover .container::after { border-left: 1px solid #d0d0d0; }
    .widget.dropdownPanel.open.defaultStyle > .button { border-bottom-left-radius: 0; border-bottom-right-radius: 0; border-bottom: 1px solid #e6e6e6; background: linear-gradient(to bottom, rgb(230,230,230) 0%, rgb(245,245,245) 16%); z-index: 11; }
    .widget.dropdownPanel.defaultStyle .frame { min-width: 30%; margin-top: -1px; margin-left: 0px; }
    .widget.dropdownPanel.defaultStyle .panel { min-height: 200px; border: 1px solid #d0d0d0; border-top-right-radius: 4px; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; background: linear-gradient(to bottom, rgb(255,255,255) 60%, rgb(230,230,230) 100%); color: #000; }
    
    .widget.dropdownPanel.filterStyle { margin: 0 20px -4px 0; }
    .widget.dropdownPanel.filterStyle > .button { font-size: 0.8em; padding: 0 20px 0 0; border-right: 1px solid #ddd; height: 60px; display: table; }
    .widget.dropdownPanel.filterStyle > .button > div { display: table-row; }
    .widget.dropdownPanel.filterStyle > .button > div > .container { display: table-cell; vertical-align: middle; height: 100%; padding-right: 12px; }
    .widget.dropdownPanel.filterStyle > .button > div > .container::before { position: absolute; top: 50%; right: 0; width: 20px; line-height: 20px; height: 20px; margin-top: -10px; display: block; color: #888; text-align: center; content: '⌵'; font-family: SalonWidgets; font-size: 12px; }
    .widget.dropdownPanel.filterStyle > .button > .focusring { display: block; position: absolute; top: 6px; bottom: 6px; right: 0; width: 20px; }
    .widget.dropdownPanel.filterStyle > .frame { box-shadow: 0 0 6px rgba(0,0,0,0.15); filter: drop-shadow(0px 0px 8px rgba(0,0,0,0.25)); border-radius: 6px; }
    .widget.dropdownPanel.filterStyle > .frame:after { position: absolute; top: -8px; left: 12px; content: ' '; display: block; border: 10px solid rgba(0,0,0,0); border-bottom: 10px solid #fff; border-top: none; }
    .widget.dropdownPanel.filterStyle > .frame > .panel { background: #fff; border-radius: 6px; padding: 10px; }
    
    .widget.dropdownPanel.filterStyle.smallSize > .button { height: 30px; padding: 0 10px; }
    .widget.dropdownPanel.filterStyle.smallSize > .frame { margin-top: 6px; }
    
    .widget.dropdownPanel > .button:focus { outline: none; }
    [data-input-mode=keyboard] .widget.dropdownPanel > .button:focus > .focusring { outline: 3px auto var(--colors-named-focus); }
    @supports (-webkit-hyphens:none) { [data-input-mode=keyboard] .widget.dropdownPanel > .button:focus > .focusring { outline: 2px solid var(--colors-named-focus); } }
`);

Widgets.DropdownPanel = Class.create();
Widgets.DropdownPanel.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            className: 		null,
            style:			'default',
            size:			'regular',
            distance:       0,
            onOpen:			null,
            onClose:		null
        }, options || {});

        if (typeof parent.container != 'undefined') parent = parent.container;
        this.widget = new Element('div', { 'class': 'widget dropdownPanel' });
        this.widget.observe('click', this.onClick.bindAsEventListener(this));
        this.widget.observe('keypress', this.onKeyPress.bindAsEventListener(this));
        this.widget.observe('keyup', this.onKeyUp.bindAsEventListener(this));

        if (this.options.className) {
            this.widget.classList.add(this.options.className);
        }
        parent.appendChild(this.widget);

        if (this.options.style) {
            this.widget.classList.add(this.options.style + 'Style');
        }

        if (this.options.size) {
            this.widget.classList.add(this.options.size + 'Size');
        }

        this.button = new Element('div', { 'class': 'button' });
        this.button.tabIndex = 0;
        this.widget.appendChild(this.button);

        this.focusring = new Element('div', { 'class': 'focusring' });
        this.button.appendChild(this.focusring);

        this.wrapper = new Element('div');
        this.button.appendChild(this.wrapper);

        this.container = new Element('div', { 'class': 'container' });
        this.wrapper.appendChild(this.container);

        var frame = new Element('div', { 'class': 'frame' });
        this.widget.appendChild(frame);

        if (this.options.distance) {
            frame.style.marginTop = this.options.distance + 'px';
        }

        this.panel = new Element('div', { 'class': 'panel' });
        this.panel.tabIndex = -1;
        this.panel.observe('click', function(e) { e.stop(); });
        frame.appendChild(this.panel);
    },

    onKeyUp: function(event) {
        if (event.key == 'Escape') {
            event.stopPropagation();
            this.close();
        }
    },

    onKeyPress: function(event) {
        if (event.key == 'Enter') {
            this.toggle();
        }
    },

    onClick: function(event) {
        if (event.target.closest('.widget') == this.widget) {
            event.stop();
            this.toggle();
        }
    },

    toggle: function(e) {
        if (this.widget.classList.contains('open'))
            this.close();
        else
            this.open();
    },

    open: function() {
		this.previousFocus = document.activeElement;

        this.widget.classList.add('open');

        Widgets.registerSheet(this, [ this.widget ]);

        if (this.options.onOpen) {
            this.options.onOpen();
        }

        this.panel.focus({ preventScroll: true });
    },

    close: function() {
        this.widget.classList.remove('open');

        if (this.options.onClose) {
            this.options.onClose();
        }

        if (this.previousFocus) {
            this.previousFocus.focus();
        }
    },

    isOpen: function() {
        return this.widget.classList.contains('open');
    },

    show: function() {
        this.widget.show();
    },

    hide: function() {
        this.widget.hide();
    }
}