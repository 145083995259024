Widgets.Button = Class.create(Widgets.Base, {
    name:		'button',

    defaults:	{
        title: 			'',
        caret:          false,
        size:			'normal',
        style:			null,
        active:			false,
        immediate:		false,
        onFocus:		function() {},
        onClick:		function() {},
        onRightClick:	function() {},
    },

    css: `
        body .widget.button { display: inline-block; margin-right: 6px; vertical-align: middle; position: relative; -webkit-user-select: none; }
        body .widget.button button { display: flex; align-items: center; justify-content: center; clear: both; min-width: var(--button-min-width, 90px); max-width: var(--button-max-width, auto); height: 28px; background: var(--button-background); font-size: 0.8rem; font-weight: var(--font-weight-bold); text-transform: var(--font-transform); white-space: nowrap; cursor: pointer; padding: 0 9px 0 9px; border: 1px solid var(--button-border); border-radius: 4px; color: var(--button-text); }
        body .widget.button button[data-icon] button-label { display: var(--button-display-label, inline); }
        body .widget.button button[data-icon] button-label:empty { display: none; }

        [data-input-mode=mouse] .widget.button button:focus { outline: none; }
        body .widget.button button:hover, body .widget.button button.active { background: var(--button-hover-background); }
        body .widget.button button:active { background: var(--button-active-background); }
        body .widget.button.active button { background: var(--button-active-background); }
        body .widget.button button:disabled { border: 1px solid var(--button-disabled-border); background: var(--button-disabled-background); color: var(--button-disabled-text); }
        body .widget.button button[data-icon]::before { font-size: 18px; margin-right: 4px; }
        body .widget.button button[data-icon-align="right"]::before { order: 1; margin-right: 0; margin-left: 4px; }
        body .widget.button button[data-icon-size="small"]::before { font-size: 16px; }

        body .widget.button button.hasCaret { padding-right: 30px; }
        body .widget.button button .caret { position: absolute; right: 0; text-shadow: none; border-left: 1px solid var(--button-border); margin: 0; padding: 0 5px; color: #888; line-height: 24px; font-family: SalonWidgets; font-size: 12px; content: '⌵'; } 
        body .widget.button button:disabled .caret { color: var(--button-disabled-text); }

        body .widget.button[data-style=none] button { background: none; border: none; min-width: auto; padding: 0 4px 0 4px; }
        body .widget.button[data-style=none] button.hasCaret { padding-right: 20px; }
        body .widget.button[data-style=none] button .caret { border-left: none; } 

        body .widget.button[data-style=back] button { padding: 0 8px 0 13px; min-width: auto;}
        body .widget.button[data-style=back] button::after { position: absolute; top: 0; left: 0; display: block; content: ''; width: 13px; height: 28px; background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2013%2028%22%3E%0A%20%20%3Cpath%20d%3D%22M13%2027C10%2027%201%2014.7%201%2014S10%201%2013%201V0c-.6%200-2.4%200-7.9%206.6C5%206.7%200%2012.8%200%2014s5%207.3%205.1%207.4C10.6%2028%2012.4%2028%2013%2028v-1z%22%20fill%3D%22%23d4d4d4%22%2F%3E%0A%20%20%3Cpath%20d%3D%22M0%200h13c-.6%200-2.4%200-7.9%206.6C5%206.7%200%2012.8%200%2014s5%207.3%205.1%207.4C10.6%2028%2012.4%2028%2013%2028H0V0z%22%20fill%3D%22%23ffffff%22%2F%3E%0A%3C%2Fsvg%3E); }

        body .widget.button[data-position=topright] { position: absolute; top: 10px; right: 10px; margin: 0 !important; }

        body .widget.button[data-color=none] button { background: none; border: none; }
        body .widget.button[data-color=blue] button { color: var(--button-blue-text); border: 1px solid var(--button-blue-border); background: var(--button-blue-background); }
        body .widget.button[data-color=blue] button:hover { background: var(--button-blue-hover-background); }
        body .widget.button[data-color=blue] button:active { background: var(--button-blue-active-background); }
        body .widget.button[data-color=blue] button:disabled { color: var(--button-blue-disabled-text); border: 1px solid var(--button-blue-disabled-border); background: var(--button-blue-disabled-background); }
        body .widget.button[data-color=red] button { color: var(--button-red-text); border: 1px solid var(--button-red-border); background: var(--button-red-background); }
        body .widget.button[data-color=red] button:hover { background: var(--button-red-hover-background); }
        body .widget.button[data-color=red] button:active { background: var(--button-red-active-background); }
        body .widget.button[data-color=red] button:disabled { color: var(--button-red-disabled-text); border: 1px solid var(--button-red-disabled-border); background: var(--button-red-disabled-background); }
        body .widget.button[data-color=green] button { color: var(--button-green-text); border: 1px solid var(--button-green-border); background: var(--button-green-background); }
        body .widget.button[data-color=green] button:hover { background: var(--button-green-hover-background); }
        body .widget.button[data-color=green] button:active { background: var(--button-green-active-background); }
        body .widget.button[data-color=green] button:disabled { color: var(--button-green-disabled-text); border: 1px solid var(--button-green-disabled-border); background: var(--button-green-disabled-background); }
        body .widget.button[data-color=orange] button { color: var(--button-orange-text); border: 1px solid var(--button-orange-border); background: var(--button-orange-background); }
        body .widget.button[data-color=orange] button:hover { background: var(--button-orange-hover-background); }
        body .widget.button[data-color=orange] button:active { background: var(--button-orange-active-background); }
        body .widget.button[data-color=orange] button:disabled { color: var(--button-orange-disabled-text); border: 1px solid var(--button-orange-disabled-border); background: var(--button-orange-disabled-background); }
        body .widget.button[data-color=white] button { color: var(--button-white-text); border: 1px solid var(--button-white-border); background: var(--button-white-background); }
        body .widget.button[data-color=white] button:hover { background: var(--button-white-hover-background); }
        body .widget.button[data-color=white] button:active { background: var(--button-white-active-background); }
        body .widget.button[data-color=white] button:disabled { color: var(--button-white-disabled-text); border: 1px solid var(--button-white-disabled-border); background: var(--button-white-disabled-background); }
        body .widget.button[data-color=gray] button { color: var(--button-gray-text); border: 1px solid var(--button-gray-border); background: var(--button-gray-background); }
        body .widget.button[data-color=gray] button:hover { background: var(--button-gray-hover-background); }
        body .widget.button[data-color=gray] button:active { background: var(--button-gray-active-background); }
        body .widget.button[data-color=gray] button:disabled { color: var(--button-gray-disabled-text); border: 1px solid var(--button-gray-disabled-border); background: var(--button-gray-disabled-background); }
        body .widget.button[data-color] button .caret { border-left: 1px solid #fff; } 
        body .widget.button[data-color=blue] button .caret { color: #fff; } 
        body .widget.button[data-color=blue] button:disabled .caret { color: var(--button-blue-disabled-text); } 
        body .widget.button[data-color=red] button .caret { color: #fff; } 
        body .widget.button[data-color=red] button:disabled .caret { color: var(--button-red-disabled-text); } 
        body .widget.button[data-color=green] button .caret { color: #fff; } 
        body .widget.button[data-color=green] button:disabled .caret { color: var(--button-green-disabled-text); } 
        body .widget.button[data-color=orange] button .caret { color: #fff; } 
        body .widget.button[data-color=orange] button:disabled .caret { color: var(--button-orange-disabled-text); } 

        body .widget.button[data-size=small] button { min-width: 70px; height: 24px; font-size: 0.7rem; }
        body .widget.button[data-size=small] button[data-icon]::before { font-size: 14px; }
        body .widget.button button.onlyIcon { width: 32px; min-width: 32px; padding: 0; }
        body .widget.button button.onlyIcon.hasCaret { width: 56px; min-width: 56px; padding-right: 24px; }
        body .widget.button button.onlyIcon::before { margin: 0 !important; }
    `,

    initWidget: function() {
        this._wait = null;

        if (this.options.position) this.dataset.position = this.options.position; 
        if (this.options.style) this.dataset.style = this.options.style; 
        if (this.options.color) this.dataset.color = this.options.color; 
        if (this.options.size) this.dataset.size = this.options.size; 

        this.button = new Element('button').update(`<button-label>${this.options.title}</button-label>`);
        this.button.tabIndex = 0;
        this.button.observe('click', this.onClick.bindAsEventListener(this));
        this.button.observe('contextmenu', this.onRightClick.bindAsEventListener(this));
        this.button.observe('focus', this.onFocus.bindAsEventListener(this));

        this.insert(this.button);

        if (this.options.width) {
            this.button.setStyle({ 'width': this.options.width, 'minWidth': '0px' });
        }

        if (this.options.height) {
            this.button.setStyle({ 'height': this.options.height });
        }

        if (this.options.align) {
            this.button.setStyle({ 'textAlign': this.options.align, 'justifyContent': this.options.align });
        }

        if (this.options.icon) {
            if (typeof this.options.icon == 'object') 
            {
                if (this.options.icon.id) {
                    this.button.dataset.icon = this.options.icon.id;
                }

                if (this.options.icon.character) {
                    this.button.dataset.icon = 'custom-character';
                    this.button.dataset.iconCharacter = this.options.icon.character;
                }

                if (this.options.icon.color) {
                    this.button.dataset.iconColor = this.options.icon.color;
                }

                if (this.options.icon.align) {
                    this.button.dataset.iconAlign = this.options.icon.align;
                }

                if (this.options.icon.size) {
                    this.button.dataset.iconSize = this.options.icon.size;
                }
            } else {
                this.button.dataset.icon = this.options.icon;
            }

            if (this.options.title == '') {
                this.button.classList.add('onlyIcon');
            }
        }

        if (this.options.active) {
            this.classList.add('active');
        }

        if (this.options.caret) {
            this.caret = new Element('div').update('⌵');
            this.caret.classList.add('caret');
            this.button.appendChild(this.caret);
            this.button.classList.add('hasCaret');
        }

        this.defineProperty('title', {
            get: function() { return this.options.title; },
            set: function(value) { this.options.title = value; this.button.update(value); }
        });

        this.defineProperty('active', {
            get: function() { return this.options.active; },
            set: function(value) { this.options.active = value; this.classList[value ? 'add' : 'remove']('active') ; }
        });
    },

    stateChange: function() {
        if (this.button) {
            this.button.disabled = ! this.enabled;
        }
    },

    onRightClick: function(e) {
        e.stop();

        if (this.enabled) {
            this.options.onRightClick(e);
        }
    },

    onClick: function(e) {
        if (this.options.immediate) return this.handleClick(e);

        if (this._wait) return;
        this._wait = window.setTimeout(function() { this._wait = null; }.bind(this), 500);
        this.handleClick(e);
    },

    onFocus: function() {
        this.options.onFocus();
    },

    handleClick: function(e) {
        if (this.enabled) {
            this.options.onClick(e);
        }
    },

    click: function() {
        this.handleClick();
    },

    focus: function() {
        this.button.focus();
    }
});