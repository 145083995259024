
CSS.insert(`
    .widget.spinner.horizontal { margin: 0; list-style-type: none; -webkit-user-select: none; display: inline-flex; }
    .widget.spinner.horizontal li { display: inline-block; vertical-align: top; height: 24px; line-height: 24px; cursor: pointer; font-family: SalonIcon; font-size: 11px; text-align: left; padding: 0 8px; border-top: 1px solid var(--button-border); border-left: 1px solid var(--button-border); border-bottom: 1px solid var(--button-border); background: var(--button-background); color: var(--spinner-text); -webkit-font-smoothing: antialiased; }
    .widget.spinner.horizontal li:focus { z-index: 1; position: relative; }
    [data-input-mode=mouse] .widget.spinner.horizontal li:focus { outline: none}
    .widget.spinner.horizontal li.home { font-size: 14px; line-height: 24px; }
    .widget.spinner.horizontal li.enabled:hover { background: var(--button-hover-background); }
    .widget.spinner.horizontal li.enabled:active { background: var(--button-active-background); }
    .widget.spinner.horizontal li.disabled { color: var(--spinner-disabled-text); }
    .widget.spinner.horizontal li:first-child { border-bottom-left-radius: 4px; border-top-left-radius: 4px; }
    .widget.spinner.horizontal li:last-child { border-right: 1px solid var(--button-border); border-bottom-right-radius: 4px; border-top-right-radius: 4px; }
    .widget.spinner.horizontal.smallSize li { height: 18px; line-height: 18px; font-size: 8px; padding: 0 6px; }
    .widget.spinner.horizontal.separatedType { display: block; }
    .widget.spinner.horizontal.separatedType li { border-radius: 4px; border: 1px solid var(--button-border); }
    .widget.spinner.horizontal.separatedType li.left { float: left; }
    .widget.spinner.horizontal.separatedType li.right { float: right; }
`);

Widgets.HorizontalSpinner = Class.create();
Widgets.HorizontalSpinner.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            className: 		null,
            size:			'normal',
            type:			'combined',
            onLeft:			null,
            onOptionLeft:	null,
            onHome:			null,
            onRight:		null,
            onOptionRight:	null,
            leftDisabled:	false,
            rightDisabled:	false
        }, options || {});

        if (typeof parent.container != 'undefined') parent = parent.container;
        this.container = new Element('ul', { 'class': 'widget horizontal spinner ' + this.options.size + 'Size ' + this.options.type + 'Type' });
        parent.appendChild(this.container);

        if (this.options.className) {
            this.container.classList.add(this.options.className);
        }

        this.leftSpinner = new Element('li', { 'class': 'left' }).update('←');
        this.leftSpinner.observe('click', this.onLeft.bindAsEventListener(this));
        this.leftSpinner.observe('keypress', this.onLeftKeyPress.bindAsEventListener(this));
        this.container.appendChild(this.leftSpinner);

        if (this.options.leftDisabled) {
            this.leftSpinner.tabIndex = -1;
            this.leftSpinner.classList.add('disabled');
        } else {
            this.leftSpinner.tabIndex = 0;
            this.leftSpinner.classList.add('enabled');
        }

        if (this.options.onHome) {
            this.homeButton = new Element('li', { 'class': 'home' }).update('\uE94a');
            this.homeButton.tabIndex = 0;
            this.homeButton.observe('click', this.onHome.bindAsEventListener(this));
            this.homeButton.observe('keypress', this.onHomeKeyPress.bindAsEventListener(this));
            this.container.appendChild(this.homeButton);
        }

        this.rightSpinner = new Element('li', { 'class': 'right' }).update('→');
        this.rightSpinner.observe('click', this.onRight.bindAsEventListener(this));
        this.rightSpinner.observe('keypress', this.onRightKeyPress.bindAsEventListener(this));
        this.container.appendChild(this.rightSpinner);

        if (this.options.rightDisabled) {
            this.rightSpinner.tabIndex = -1;
            this.rightSpinner.classList.add('disabled');
        } else {
            this.rightSpinner.tabIndex = 0;
            this.rightSpinner.classList.add('enabled');
        }
    },

    onLeft: function(e) {
        if (!this.options.leftDisabled && this.options.onLeft) {
            if (e.altKey && this.options.onOptionLeft)
                this.options.onOptionLeft();
            else
                this.options.onLeft();
        }
    },

    onLeftKeyPress: function(event) {
        if (event.key == 'Enter') {
            if (!this.options.leftDisabled && this.options.onLeft) {
                this.options.onLeft();
            }
        }
    },

    onHome: function(e) {
        if (this.options.onHome) {
            this.options.onHome();
        }
    },

    onHomeKeyPress: function(event) {
        if (event.key == 'Enter') {
            if (this.options.onHome) {
                this.options.onHome();
            }
        }
    },

    onRight: function(e) {
        if (!this.options.rightDisabled && this.options.onRight) {
            if (e.altKey && this.options.onOptionRight)
                this.options.onOptionRight();
            else
                this.options.onRight();
        }
    },

    onRightKeyPress: function(event) {
        if (event.key == 'Enter') {
            if (!this.options.rightDisabled && this.options.onRight) {
                this.options.onRight();
            }
        }
    },

    disableLeft: function() {
        this.options.leftDisabled = true;
        this.leftSpinner.tabIndex = -1;
        this.leftSpinner.classList.add('disabled');
        this.leftSpinner.classList.remove('enabled');
    },

    enableLeft: function() {
        this.options.leftDisabled = false;
        this.leftSpinner.tabIndex = 0;
        this.leftSpinner.classList.remove('disabled');
        this.leftSpinner.classList.add('enabled');
    },

    disableRight: function() {
        this.options.rightDisabled = true;
        this.rightSpinner.tabIndex = -1;
        this.rightSpinner.classList.add('disabled');
        this.rightSpinner.classList.remove('enabled');
    },

    enableRight: function() {
        this.options.rightDisabled = false;
        this.rightSpinner.tabIndex = 0;
        this.rightSpinner.classList.remove('disabled');
        this.rightSpinner.classList.add('enabled');
    }
};
