CSS.insert(`
    .widget.dataTable table { border-spacing: 0; width: 100%; color: var(--table-body-text); font-size: 0.75em; }
    .widget.dataTable.smallSize table { font-size: 0.7em; }
    .widget.dataTable table tr th { border-top: 1px solid var(--table-body-line); line-height: 130%; padding: 5px 10px; }
    .widget.dataTable table tr td { border-bottom: 1px solid var(--table-body-line); line-height: 130%; padding: 5px 10px; }
    .widget.dataTable table tr td input[type=checkbox] { vertical-align: middle; }
    .widget.dataTable table tr td a { color: inherit; text-decoration: none; }
    .widget.dataTable table tr td a:hover { text-decoration: underline; }

    .widget.dataTable table thead tr th input[type=checkbox] { margin-left: -1px; }
    .widget.dataTable table tr.group td { padding-top: 20px; }
    .widget.dataTable table tr.disabled td { color: #bbb; }
    .widget.dataTable table tr td.nowrap { white-space: nowrap; }
    .widget.dataTable table tr td.strike { text-decoration: line-through; }
    .widget.dataTable table tr td.disabled { color: #888; }
    .widget.dataTable table tr td.tabularNumerals { font-variant-numeric: tabular-nums; }
    .widget.dataTable table tr td.monoSpace { font-family: var(--font-stack-mono); font-weight: var(--font-weight-mono); }
    .widget.dataTable table tr td.nowrap { white-space: nowrap; }
    .widget.dataTable table tr td.textSelectable { user-select: text; }
    .widget.dataTable table tr td.break { border-right: 1px solid var(--table-body-line); }
    .widget.dataTable table tr th.break { border-right: 1px solid var(--table-body-line); }
    .widget.dataTable table thead tr th { font-weight: var(--font-weight-bold); text-align: left; color: var(--table-header-text); background: var(--table-header-background); white-space: nowrap; }
    .widget.dataTable table thead tr th[align=right] { text-align: right; }
    .widget.dataTable table thead tr th:first-child { border-left: 1px solid var(--table-header-border); border-top-left-radius: 4px; }
    .widget.dataTable table thead tr th:last-child { border-right: 1px solid var(--table-header-border); border-top-right-radius: 4px; }
    .widget.dataTable table thead tr th.checkbox span { margin-left: 6px; }
    .widget.dataTable table tbody tr:first-child td { border-top: 1px solid var(--table-header-border); }
    .widget.dataTable table tbody tr.even td, .widget.dataTable tbody tr.even th { background: var(--table-body-stripe); }
    .widget.dataTable table.showHover tbody tr:hover td { background: var(--table-body-hover); cursor: pointer; }
    .widget.dataTable table tbody tr.selected td { border: 1px solid var(--colors-named-accent-darker); color: #fff; text-shadow: 0px -1px 1px var(--colors-named-accent-darker); background: var(--colors-named-accent-lighter); background: linear-gradient(to bottom, var(--colors-named-accent-lighter) 0%, var(--colors-named-accent-darker) 100%); }
    .widget.dataTable table tfoot tr td { font-weight: var(--font-weight-bold); border-top: 1px solid var(--table-body-line); border-bottom: none; }
    .widget.dataTable table tfoot tr.subtotal td { font-weight: normal; }
    .widget.dataTable.inlineStyle table { border-top: 8px solid rgba(0,0,0,0); border-right: 8px solid rgba(0,0,0,0); border-bottom: none; }
    .widget.dataTable.inlineStyle table tr td { border-bottom: 1px solid var(--table-header-border); border-top: 1px solid rgba(0,0,0,0); line-height: 170%; padding: 0 10px; }
    .widget.dataTable.fixedStyle { overflow-y: scroll; }
    .widget.dataTable.fixedStyle::-webkit-scrollbar-thumb:vertical { background-color: var(--scroll-thumb); }
    .widget.dataTable.fixedStyle::-webkit-scrollbar-track-piece { background-color: var(--scroll-track); margin: 0; }
    .widget.dataTable.fixedStyle table { border-right: 12px solid rgba(0,0,0,0); }
    .widget.dataTable table.sortable thead tr th.sortcol { cursor: pointer }
    .widget.dataTable table.sortable thead tr th:not([align=right]).sortcol { padding-right: 0; }
    .widget.dataTable table.sortable thead tr th:not([align=right]).sortcol > span::after { display: inline-block; width: 12px; content: ''; font-family: SalonWidgets; font-size: 12px; color: #888; margin-left: 4px; line-height: 100%; }
    .widget.dataTable table.sortable thead tr th:not([align=right]).sortasc > span::after { content: '⌵'; }
    .widget.dataTable table.sortable thead tr th:not([align=right]).sortdesc > span::after { content: '⌃'; }
    .widget.dataTable table.sortable thead tr th[align=right].sortcol { padding-left: 0; }
    .widget.dataTable table.sortable thead tr th[align=right].sortcol > span::before { display: inline-block; width: 12px; content: ''; font-family: SalonWidgets; font-size: 12px; color: #888; margin-right: 4px; line-height: 100%; }
    .widget.dataTable table.sortable thead tr th[align=right].sortasc > span::before { content: '⌵'; }
    .widget.dataTable table.sortable thead tr th[align=right].sortdesc > span::before { content: '⌃'; }
    .widget.dataTable table tr[data-group-top] td { border-bottom: 1px solid var(--table-body-stripe); }
    .widget.dataTable table tr[data-group-top].even td { border-bottom: 1px solid #f3f3f3; }
    .widget.dataTable table tr[data-group-top]:has(+ tr[data-group-top='true']) td { border-bottom: 1px solid var(--table-body-line); }
    .widget.dataTable table tr[data-group-top]:last-of-type td { border-bottom: 1px solid var(--table-body-line); }
    .widget.dataTable table .hidden { display: none; }
    .widget.dataTable table.limited tbody tr { display: none; }
    .widget.dataTable table.limited.limitBy5 tbody tr:nth-child(-n+5) { display: table-row; }
    .widget.dataTable table.limited.limitBy10 tbody tr:nth-child(-n+10) { display: table-row; }
    .widget.dataTable table.limited.limitBy15 tbody tr:nth-child(-n+15) { display: table-row; }
    .widget.dataTable table.limited.limitBy20 tbody tr:nth-child(-n+20) { display: table-row; }
    .widget.dataTable .more { text-align: center; font-size: 0.7em; color: #888; padding: 10px 0; border-bottom: 1px solid var(--table-body-line); cursor: pointer; }
    .widget.dataTable .more:hover { color: #444; }

    .widget.dataTable table tr td.bullet { width: 12px; }
    .widget.dataTable table tr td.bullet span { font-size: 28px; font-family: Arial, sans-serif; line-height: 0px; vertical-align: sub; }
    .widget.dataTable table tr td.bullet[data-color=green] span { color: var(--colors-green); }
    .widget.dataTable table tr td.bullet[data-color=orange] span { color: var(--colors-orange); }
    .widget.dataTable table tr td.bullet[data-color=red] span { color: var(--colors-red); }
    .widget.dataTable table tr td.bullet[data-color=blue] span { color: var(--colors-blue); }
    .widget.dataTable table tr td.bullet[data-color=purple] span { color: var(--colors-purple); }
    .widget.dataTable table tr td.bullet[data-color=gray] span { color: var(--colors-gray-lighter); }
    .widget.dataTable table tr td.bullet[data-color=transparent] span { color: rgba(0,0,0,0); }

    .widget.dataTable table input[type=checkbox] { accent-color: var(--colors-blue); }
`);

Widgets.DataTable = Class.create();
Widgets.DataTable.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            className:		null,
            showHeader:		true,
            onEdit:			null,
            onSelect:		null,
            onInitialize:	null,
            onFooter:		null,
            value:			null,
            style:			null,
            height:			null,
            lineHeight:		null,
            size:			'normal',
            enabled:		true,
            columns:		[],
            rows:			[],
            groups:			null,
            sortable:		false,
            limit:			null
        }, options || {});

        this._wait = null;

        this.unique = uniqueid();

        this.selectable = !! this.options.onSelect;
        this.selected = null;

        this.data = this.options.rows || [];
        this.hash = [];
        this.deleted = [];
        this.customized = [];
        this.checkboxes = [];

        for (var r = 0; r < this.data.length; r++) {
            this.data[r].state = 'original';
        }

        if (typeof parent.container != 'undefined') parent = parent.container;

        this.container = new Element('div', { 'class': 'widget dataTable ' + this.options.size + 'Size' });
        parent.appendChild(this.container);

        if (this.options.height) {
            this.container.setStyle({ 'height': this.options.height });
        }

        if (this.options.style) {
            this.container.classList.add(this.options.style + 'Style');
        }

        if (this.options.className) {
            this.container.classList.add(this.options.className);
        }

        this.table = new Element('table');
        this.container.appendChild(this.table);

        for (var i = 0; i < this.options.columns.length; i++) {
            var visible = typeof this.options.columns[i].visible == 'undefined' ? true : !! this.options.columns[i].visible;
            this.options.columns[i].visible = visible;
            this.options.columns[i].defaultVisible = visible;
        }

        if (this.options.showHeader) {
            var head = new Element('thead');
            this.table.appendChild(head);

            var row = new Element('tr');
            head.appendChild(row);

            for (var i = 0; i < this.options.columns.length; i++) {
                var type = typeof this.options.columns[i].type == 'undefined' ? 'cell' : this.options.columns[i].type;
                var enabled = typeof this.options.columns[i].enabled == 'undefined' ? true : !! this.options.columns[i].enabled;

                if (enabled || type == 'checkbox') {
                    var column = new Element('th');

                    if (!this.options.columns[i].visible) {
                        column.classList.add('hidden');
                    }

                    if (typeof this.options.columns[i].sortable != 'undefined' && ! this.options.columns[i].sortable) {
                        column.classList.add('nosort');
                    }

                    if (type == 'cell' || type == 'checkbox' || type == 'field' || type == 'custom' || type == 'bullet') {
                        column.innerHTML = "<span>" + (this.options.columns[i].title ? this.options.columns[i].title : '') + "</span>";
                        if (this.options.columns[i].align) {
                            column.align = this.options.columns[i].align;
                        }
                    }

                    if (type == 'break') {
                        column.classList.add('break');
                    }

                    if (type == 'checkbox') {
                        column.classList.add('checkbox');
                        column.classList.add('nosort');
                        column.innerHTML = "<input type='checkbox' data-column='" + i + "'>";
                        column.innerHTML += "<span>" + (this.options.columns[i].title ? this.options.columns[i].title : '') + "</span>";
                    }

                    row.appendChild(column);
                }
            }

            row.observe('click', this.onClickHeader.bind(this));
            row.observe('contextmenu', this.showMenu.bind(this));
        }

        this.body = new Element('tbody');
        this.body.observe('click', this.onClick.bindAsEventListener(this));
        this.table.appendChild(this.body);

        if (this.options.onEdit || this.options.onSelect) {
            this.table.classList.add('showHover');
        }

        if (this.options.onFooter) {
            this.footer = new Element('tfoot');
            this.table.appendChild(this.footer);
        }

        if (this.options.limit) {
            this.table.classList.add('limited');
            this.table.classList.add('limitBy' + this.options.limit);

            this.more = new Element('div');
            this.more.hide();
            this.more.classList.add('more');
            this.more.observe('click', this.showMore.bind(this));
            this.container.appendChild(this.more);
        }

        this.build();

        if (this.options.sortable) {
            this.table.classList.add('sortable');
            this.createSortable();
        }
    },

    destroy: function() {
        this.container.remove();
    },

    showMore: function() {
        this.more.hide();
        this.table.classList.remove('limited');
    },

    createSortable: async function() {
        await requireAsync([ '../core/lib/tablekit' ]);

        TableKit.unloadTable(this.table);
        TableKit.Sortable.init(this.table, {
            rowEvenClass:	'even',
            rowOddClass:	'odd',
            noSortClass:    'nosort',
        });
    },

    build: function() {
        var body = '';
        var footer = '';

        if (this.options.onFooter) {
            this.footer.update('');

            var row = new Element('tr');
            this.footer.appendChild(row);

            for (var i = 0; i < this.options.columns.length; i++) {
                var enabled = typeof this.options.columns[i].enabled == 'undefined' ? true : !! this.options.columns[i].enabled;

                if (enabled) {
                    var column = new Element('td');
                    this.options.columns[i].footer = column;

                    if (!this.options.columns[i].visible) {
                        column.classList.add('hidden');
                    }

                    if (this.options.columns[i].align) {
                        column.align = this.options.columns[i].align;
                    }

                    this.options.onFooter(this.options.columns[i], this.data);
                    row.appendChild(column);
                }
            }
        }

        if (this.options.groups) {
            var r = 0;

            for (var g = 0; g < this.data.length; g++) {
                var group = this.data[g][this.options.groups];

                for (var i = 0; i < group.length; i++) {
                    this.hash[r] = group[i];

                    var selected = false;

                    if (this.selectable && this.value) {
                        if (this.options.value == group[i].id) {
                            selected = true;
                            this.selected = r;
                        }
                    }

                    var enabled;
                    if (typeof this.options.enabled == 'function') {
                        enabled = this.options.enabled(group[i]);
                    } else {
                        enabled = this.options.enabled;
                    }

                    body += "<tr data-id='" + r + "' class='" + (selected ? " selected" : "") + (enabled ? "" : " disabled") + (g % 2 ? " even" : " odd") + (i != 0 ? "" : " group") + "'>";
                    body += this.row(r, i, group[i], this.data[g]);
                    body += "</tr>";

                    r++;
                }
            }
        } else {
            let g = 0;

            for (var r = 0; r < this.data.length; r++) {
                var selected = false;

                if (this.selectable && this.value) {
                    if (this.options.value == this.data[r].id) {
                        selected = true;
                        this.selected = r;
                    }
                }

                var enabled;
                if (typeof this.options.enabled == 'function') {
                    enabled = this.options.enabled(this.data[r]);
                } else {
                    enabled = this.options.enabled;
                }

                let styles = [];

                if (this.options.lineHeight) {
                    styles.push('line-height: ' + this.options.lineHeight);
                }

                let even;
                let group = '';

                if (this.options.grouped) {
                    this.table.classList.add('grouped');

                    let data = this.options.grouped(this.data[r]);
                    group = ` data-group-id='${data[0]}' data-group-top='${data[1]}'`;

                    if (data[1] && r != 0) {
                        g++;
                    }

                    even = g % 2;
                }
                else {
                    even = r % 2;

                    if (typeof this.options.even == 'function') {
                        even = this.options.even(this.data[r]);
                    }
                }


                if (typeof this.data[r].type != 'undefined' && (this.data[r].type == 'footer' || this.data[r].type == 'total' || this.data[r].type == 'subtotal')) {
                    footer += "<tr data-id='" + r + "' class='" + this.data[r].type + (selected ? " selected" : "") + (enabled ? "" : " disabled") + "'" + (styles.length ? " '" + styles.join('; ') + "'" : "") + ">";
                    footer += this.row(r, r, this.data[r], null);
                    footer += "</tr>";
                }
                else {
                    body += "<tr data-id='" + r + "'" + group + " class='" + (selected ? " selected" : "") + (enabled ? "" : " disabled") + (even ? " even" : " odd") + "'" + (styles.length ? " '" + styles.join('; ') + "'" : "") + ">";
                    body += this.row(r, r, this.data[r], null);
                    body += "</tr>";
                }
            }
        }

        this.body.innerHTML = body;

        if (!this.options.onFooter && footer) {
            this.footer = new Element('tfoot');
            this.table.appendChild(this.footer);

            this.footer.innerHTML = footer;
        }

        if (this.options.onInitialize) {
            this.options.onInitialize(this.body);
        }

        while(this.customized.length) {
            var custom = this.customized.pop();
            custom[3](document.getElementById(custom[1]), custom[2], custom[0]);
        }

        if (this.options.limit) {
            if (this.data.length > this.options.limit) {
                this.more.show();
                this.more.innerHTML = (this.data.length - this.options.limit) + ' meer...';
            }
        }
    },

    row: function(r, g, data, group) {
        var html = '';

        for (var i = 0; i < this.options.columns.length; i++) {
            var wrap = typeof this.options.columns[i].wrap == 'undefined' || this.options.columns[i].wrap;
            var type = typeof this.options.columns[i].type == 'undefined' ? 'cell' : this.options.columns[i].type;
            var escape = typeof this.options.columns[i].escape == 'undefined' ? true : this.options.columns[i].escape;
            var visible = this.options.columns[i].visible;

            var enabled = true;
            if (typeof this.options.columns[i].enabled == 'function') {
                enabled = this.options.columns[i].enabled(data);
            }
            else if (typeof this.options.columns[i].enabled != 'undefined') {
                enabled = this.options.columns[i].enabled;
            }

            if (enabled || type == 'checkbox') {
                var classes = [];

                if (!visible) {
                    classes.push('hidden');
                }

                if (type == 'cell') {
                    if (!wrap) classes.push('nowrap');

                    if (this.options.columns[i].style) {
                        let styles = [];

                        if (this.options.columns[i].style instanceof Function) {
                            styles = this.options.columns[i].style(data);
                        }
                        else if (this.options.columns[i].style instanceof Array) {
                            styles = this.options.columns[i].style;
                        }
                        else if (typeof this.options.columns[i].style == 'string') {
                            styles = [ this.options.columns[i].style ];
                        }

                        if (styles.length) {
                            for (var s = 0; s < styles.length; s++) {
                                classes.push(styles[s]);
                            }
                        }
                    }

                    html += "<td" + (this.options.columns[i].align ? " align='" + this.options.columns[i].align + "'" : "");
                    html += (this.options.columns[i].width ? " width='" + this.options.columns[i].width + "'" : "");
                    html += classes.length ? " class='" + classes.join(' ') + "'" : "";

                    if (this.options.columns[i].value) {
                        var value = '';

                        if (this.options.columns[i].contents) {
                            if (this.options.columns[i].group) {
                                if (g == 0) value = this.options.columns[i].value(group[this.options.columns[i].contents]);
                            } else {
                                value = this.options.columns[i].value(data[this.options.columns[i].contents]);
                            }
                        } else {
                            value = this.options.columns[i].value(data);
                        }

                        html += " data-value='" + value + "'"
                    }

                    html += ">";

                    if (this.options.columns[i].prefix) html += this.options.columns[i].prefix;

                    var content = '';

                    if (this.options.columns[i].format) {
                        if (this.options.columns[i].contents) {
                            if (this.options.columns[i].group) {
                                if (g == 0) content = this.options.columns[i].format(group[this.options.columns[i].contents]);
                            } else {
                                content = this.options.columns[i].format(data[this.options.columns[i].contents]);
                            }
                        } else {
                            content = this.options.columns[i].format(data);
                        }
                    } else {
                        if (this.options.columns[i].group) {
                            if (g == 0) content = group[this.options.columns[i].contents];
                        } else {
                            content = data[this.options.columns[i].contents] ? data[this.options.columns[i].contents] : '';
                        }
                    }
                    
                    if (content instanceof Hole) {
                        html += String.raw(content.template, content.values);
                    }
                    else {
                        html += escape ? escapeHTML(content) : content;
                    }

                    if (this.options.columns[i].postfix) html += this.options.columns[i].postfix;

                    html += "</td>";
                }

                if (type == 'checkbox') {
                    classes.push('checkbox');

                    if (typeof data[this.options.columns[i].contents] == 'undefined') {
                        data[this.options.columns[i].contents] = false;
                    }

                    html += "<td";
                    html += classes.length ? " class='" + classes.join(' ') + "'" : "";
                    html += (this.options.columns[i].width ? " width='" + this.options.columns[i].width + "'" : "");
                    html += ">";

                    html += "<input type='checkbox' id='table_checkbox_" + this.unique + '_' + r + "_" + i + "' data-column='" + i + "'" + (!!data[this.options.columns[i].contents] ? ' checked' : '') + (enabled ? '' : 'disabled ') + ">";

                    html += "</td>";

                    this.checkboxes.push([ 'table_checkbox_' + this.unique + '_' + r + '_' + i, i, this.options.columns[i].onChange ]);
                }

                if (type == 'field') {
                    classes.push('field');

                    html += "<td";
                    html += classes.length ? " class='" + classes.join(' ') + "'" : "";
                    html += ">";

                    html += "<input type='text' data-column='" + i + "' value='" + data[this.options.columns[i].contents] + "'>";

                    html += "</td>";
                }

                if (type == 'bullet') {
                    classes.push('bullet');

                    let color = this.options.columns[i].color || '';
                    let bullet = this.options.columns[i].bullet || '●';

                    if (typeof this.options.columns[i].color == 'function') {
                        color = this.options.columns[i].color(data);
                    }

                    html += `
                        <td ${classes.length ? `class='${classes.join(' ')}'` : ''} ${color ? `data-color='${color}'` : ''}>
                            <span>${bullet}</span>
                        </td>
                    `;
                }

                if (type == 'custom') {
                    classes.push('custom');

                    html += "<td";
                    html += classes.length ? " class='" + classes.join(' ') + "'" : "";
                    html += (this.options.columns[i].width ? " width='" + this.options.columns[i].width + "'" : "");
                    html += ">";

                    html += "<div id='table_custom_" + this.unique + '_' + r + "_" + i + "'><div>";

                    html += "</td>";

                    this.customized.push([ r, 'table_custom_' + this.unique + '_' + r + '_' + i, data, this.options.columns[i].onInitialize ]);
                }

                if (type == 'break') {
                    classes.push('break');

                    html += "<td";
                    html += classes.length ? " class='" + classes.join(' ') + "'" : "";
                    html += "></td>";
                }
            }
        }

        return html;
    },

    clear: function() {
        this.table.querySelectorAll('thead input[type=checkbox]').forEach(el => el.checked = false);

        this.selected = null;
        this.hash = [];
        this.customized = [];
        this.checkboxes = [];
        this.body.update('');
    },

    newItem: function(data, front) {
        if (this.options.groups)
            return;

        data.state = 'new';
        this.data.push(data);

        var id = this.data.length - 1;
        var row = new Element('tr', { 'data-id': id });

        row.innerHTML = this.row(id, id, this.data[id], null);

        if (front) {
            this.body.insertBefore(row, this.body.firstChild);
        } else {
            this.body.appendChild(row);
        }

        while(this.customized.length) {
            var custom = this.customized.pop();
            custom[3](document.getElementById(custom[1]), custom[2], custom[0]);
        }

        if (this.options.onUpdate) {
            this.options.onUpdate(this.body);
        }

        if (this.options.onFooter) {
            for (var i = 0; i < this.options.columns.length; i++) {
                this.options.onFooter(this.options.columns[i], this.data);
            }
        }

        return row;
    },

    editItem: function(id, data) {
        if (this.options.groups)
            return;

        var state = this.data[id].state;
        if (state != 'new') {
            state = 'modified';
        }

        this.data[id] = data;
        this.data[id].state = state;

        var enabled;
        if (typeof this.options.enabled == 'function') {
            enabled = this.options.enabled(data);
        } else {
            enabled = this.options.enabled;
        }

        var row = this.body.querySelector('[data-id="' + id + '"]');

        if (enabled) {
            row.classList.remove('disabled');
        } else {
            row.classList.add('disabled');
        }

        row.innerHTML = this.row(id, id, this.data[id], null);

        if (this.options.onFooter) {
            for (var i = 0; i < this.options.columns.length; i++) {
                this.options.onFooter(this.options.columns[i], this.data);
            }
        }
    },

    deleteItem: function(id) {
        if (this.options.groups)
            return;

        var data = this.data[id];

        if (data) {
            data.state = 'deleted';
            this.deleted.push(data);
            this.data[id] = null;

            var row = this.body.querySelector('[data-id="' + id + '"]');

            for (var i = 0; i < this.options.columns.length; i++) {
                if (this.options.columns[i].type == 'checkbox') {
                    let checkbox = row.querySelector('td.checkbox input[type=checkbox]').id;

                    if (checkbox) {
                        this.checkboxes = this.checkboxes.filter(i => i[0] != checkbox);
                    }
                }
            }

            row.remove();

            if (this.options.onFooter) {
                for (var i = 0; i < this.options.columns.length; i++) {
                    this.options.onFooter(this.options.columns[i], this.data);
                }
            }
        }
    },

    update: function(data) {
        this.data = data;

        this.clear();
        this.build();

        if (this.options.sortable) {
            this.createSortable();
        }
    },

    showMenu: function(e) {
        e.stop();

        var items = [];

        for (var i = 0; i < this.options.columns.length; i++) {
            var enabled = true;
            if (typeof this.options.columns[i].enabled != 'function' && typeof this.options.columns[i].enabled != 'undefined') {
                enabled = this.options.columns[i].enabled;
            }

            var toggle = typeof this.options.columns[i].toggle == 'undefined' ? false : !! this.options.columns[i].toggle;

            if (toggle && enabled) {
                items.push({
                    title:		this.options.columns[i].title ? this.options.columns[i].title : '',
                    checked:	this.options.columns[i].visible,
                    onSelect:	this.options.columns[i].visible ? this.hideColumn.bind(this, i) : this.showColumn.bind(this, i)
                })
            }
        }

        if (items.length) {
            new Widgets.ContextMenu({
                x:			e.clientX,
                y:			e.clientY,
                items:		items
            });
        }
    },

    showColumn: function(i) {
        if (typeof i != 'number') i = this.getColumnById(i);

        if (i != null) {
            this.options.columns[i].visible = true;
            this.table.querySelectorAll('tr > :nth-child(' + (i + 1) + ')').forEach(el => el.classList.remove('hidden'));
        }
    },

    hideColumn: function(i) {
        if (typeof i != 'number') i = this.getColumnById(i);

        if (i != null) {
            this.options.columns[i].visible = false;
            this.table.querySelectorAll('tr > :nth-child(' + (i + 1) + ')').forEach(el => el.classList.add('hidden'));
        }
    },

    showDefaultColumns: function(i) {
        for (var i = 0; i < this.options.columns.length; i++) {
            if (this.options.columns[i].defaultVisible != this.options.columns[i].visible) {
                if (this.options.columns[i].defaultVisible) {
                    this.showColumn(i);
                } else {
                    this.hideColumn(i);
                }
            }
        }
    },

    getColumnById: function(id) {
        for (var i = 0; i < this.options.columns.length; i++) {
            if (typeof this.options.columns[i].id != 'undefined') {
                if (this.options.columns[i].id == id) {
                    return i;
                }
            }
        }

        return null;
    },

    onClick: function(e) {
        if (this._wait) return;
        this._wait = window.setTimeout(function() { this._wait = null; }.bind(this), 300);

        var element = e.findElement('input[type=checkbox]');
        if (element) {
            var column = parseInt(element.getAttribute('data-column'), 10);
            var row = e.findElement('tr');
            if (row) {
                var id = parseInt(row.getAttribute('data-id'), 10);

                this.data[id][this.options.columns[column].contents] = element.checked

                if (this.options.columns[column].onChange) {
                    this.options.columns[column].onChange(this.data[id], this.options.columns[column].contents, element.checked);
                }
            }
            return;
        }

        var element = e.findElement('tr');
        if (element) {
            e.stop();

            var id = parseInt(element.getAttribute('data-id'), 10);

            if (this.selectable) {
                if (this.selected != null) {
                    this.body.querySelector('[data-id="' + this.selected + '"]').classList.remove('selected');
                }

                element.classList.add('selected');
                this.selected = id;

                if (this.options.onSelect) {
                    this.options.onSelect(id, this.options.groups ? this.hash[id] : this.data[id], element);
                }
            } else {
                if (this.options.onEdit) {
                    this.options.onEdit(id, this.options.groups ? this.hash[id] : this.data[id], element, e);
                }
            }
        }
    },

    onClickHeader: function(e) {
        if (this._wait) return;
        this._wait = window.setTimeout(function() { this._wait = null; }.bind(this), 300);

        var element = e.findElement('input[type=checkbox]');
        if (element) {
            var column = parseInt(element.getAttribute('data-column'), 10);

            for (var i = 0; i < this.checkboxes.length; i++) {
                if (this.checkboxes[i][1] == column) {
                    var checkbox = document.getElementById(this.checkboxes[i][0]);
                    if (checkbox) {
                        var row = checkbox.up('tr');
                        var id = parseInt(row.getAttribute('data-id'), 10);

                        if (checkbox && !checkbox.disabled) {
                            checkbox.checked = element.checked;
                            this.data[id][this.options.columns[column].contents] = element.checked;

                            if (this.options.columns[column].onChange) {
                                this.options.columns[column].onChange(this.data[id], this.options.columns[column].contents, element.checked);
                            }
                        }
                    }
                }
            }

            return;
        }
    },

    getIdFromValue: function(key, value) {
        let id = null;

        this.data.forEach((d, i) => {
            if (d[key] == value) {
                id = i;
            }
        });

        return id;
    },

    getRow: function(id) {
        return this.body.querySelector('[data-id="' + id + '"]');
    },

    getData: function() {
        return this.data.concat(this.deleted);
    },

    exportToCsv: function() {
        var csv = "";
        var rows = this.table.getElementsByTagName('tr');

        for (var r = 0; r < rows.length; r++) {
            var row = "";

            var cells = rows.item(r).cells;
            for (var c = 0; c < cells.length; c++) {
                var cellData = cells.item(c).innerText;
                cellData = "\"" + cellData + "\"";
                row += ";" + cellData;
            }
            if (row != "") {
                row = row.substring(1,row.length);
            }
            csv += row + "\n";
        }
        return csv;
    },

    exportToHtml: function() {
        return "<div class='dataTable " + this.options.size + "Size'>" + this.container.innerHTML + "</div>";
    }
};
