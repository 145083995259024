
Widgets.Tree = Class.create();
Widgets.Tree.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            id:				'element',
            title:			null,
            value:			null,
            onInitialize:	null,
            onSelect:		null,
            onSorted:		null,
            style:			'default',
            interactive:	true,
            state:			'closed',
            items:			[],
            only:			false,
            target:			false
        }, options || {});

        if (typeof parent.container != 'undefined') parent = parent.container;

        this.selected = null;
        this.items = [];

        this.element = new Element('div', { 'class' : 'widget tree ' + this.options.style + 'Style' });
        parent.appendChild(this.element);

        this.tree = new TreeList(this, null, this.element, this.options.items, {
            value:		  this.options.value,
            interactive:  this.options.interactive,
            state:		  this.options.state,
            onInitialize: this.options.onInitialize
        })

        if (this.options.onSorted) {
            this.tree.list.id = this.options.id;
            this.createSortable();
        }

        if (this.selected) {
            Fx.scrollIntoView(this.selected.item);
        }
    },

    destroy: function() {
        this.clear();
        this.element.remove();
    },

    focus: function() {
        this.tree.focus();
    },

    deactivate: function() {
        this.tree.deactivate();
    },

    activate: function() {
        this.tree.activate();
    },

    clear: function() {
        this.tree.destroy();
    },

    update: function(items) {
        this.tree = new TreeList(this, null, this.element, items, {
            interactive:  this.options.interactive,
            state:		  this.options.state,
            onInitialize: this.options.onInitialize
        })
    },

    deselect: function() {
        if (this.selected) {
            this.selected.deselect();
        }
    },

    createSortable: function() {
        Sortable.create(this.tree.list, {
            tree:			true,
            dropOnEmpty:	true,
            hoverclass:		'hover',
            onMove:			this.onMove.bind(this),
            onUpdate:		this.onSort.bind(this),
            only:			this.options.only ? 'dragable' : false,
            target:			this.options.target ? 'dropable' : false
        })
    },

    onSort: function() {
        if (this.options.onSorted) {
            this.options.onSorted(Sortable.parents(this.tree.list));
        }
    },

    onMove: function() {
        if (this.options.onMove) {
            this.options.onMove();
        }
    },

    getItem: function(id) {
        var item = this.tree.findItem(id);

        if (item) {
            return item;
        }
    },

    newItem: function(data) {
        var parent = this.tree.findParent(data.parent);

        if (parent) {
            parent.newItem(data);
        } else {
            this.tree.newItem(data);
        }

        if (this.options.onSorted) {
            Sortable.destroy(this.tree.list);
            this.createSortable();
        }
    },

    editItem: function(data) {
        var item = this.tree.findItem(data.id);

        if (item) {
            item.edit(data);
        }
    },

    deleteItem: function(id) {
        var item = this.tree.findItem(id);

        if (item) {
            item.remove();
        }
    }
};

TreeList = Class.create();
TreeList.prototype = {
    initialize: function(base, up, parent, items, options) {
        this.base = base;
        this.up = up;
        this.parent = parent;
        this.items = items;
        this.options = Object.assign({
            value:			null,
            interactive:	false,
            state:			'open',
            onInitialize:	null
        }, options);


        this.list = new Element('ul');
        this.parent.appendChild(this.list);

        for (var i = 0; i < this.items.length; i++) {
            if (typeof this.items[i].enabled == 'undefined' || this.items[i].enabled) {
                this.items[i].item = new TreeListItem(this.base, this.up, this.list, this.items[i], this.options);
            }
        }
    },

    destroy: function() {
        this.list.remove();
    },

    show: function() {
        this.list.show();
    },

    hide: function() {
        this.list.hide();
    },

    deactivate: function() {
        this.items.forEach(i => i.item.deactivate());
    },

    activate: function() {
        this.items.forEach(i => i.item.activate());
    },

    focus: function() {
        let item = this.items.filter(i => typeof i.item != 'undefined').shift();
        if (item) {
            item.item.focus();
        }
    },

    newItem: function(data) {
        this.items.push(data);
        this.items[this.items.length - 1].item = new TreeListItem(this.base, this.up, this.list, this.items[this.items.length - 1], this.options);
    },

    findParent: function(parent) {
        var list;

        for (var i = 0; i < this.items.length; i++) {
            if (list = this.items[i].item.findParent(parent)) {
                return list;
            }
        }
    },

    findItem: function(id) {
        var item;

        for (var i = 0; i < this.items.length; i++) {
            if (item = this.items[i].item.findItem(id)) {
                return item;
            }
        }
    }
}

TreeListItem = Class.create();
TreeListItem.prototype = {
    initialize: function(base, up, parent, data, options) {
        this.base = base;
        this.up = up;
        this.parent = parent;
        this.data = data;
        this.options = Object.assign({
            value:			null,
            interactive:	false,
            state:			'open',
            onInitialize:	null
        }, options);

        this.toggle = null;
        this.state = 'open';
        if (this.options.interactive) {
            this.state = this.options.state;
        }

        this.item = new Element('li', { id: this.base.options.id + '_' + this.data.id });
        this.item.observe('click', this.onSelect.bindAsEventListener(this))
        this.item.observe('mousedown', this.onFocus.bindAsEventListener(this))
        this.item.observe('mouseup', this.onBlur.bindAsEventListener(this))
        this.item.observe('keypress', this.onKeyPress.bindAsEventListener(this))
        this.item.observe('keydown', this.onKeyDown.bindAsEventListener(this))
        this.parent.appendChild(this.item);

        if (this.base.options.only) {
            if (this.base.options.only(this.data)) this.item.classList.add('dragable');
        }

        if (this.base.options.target) {
            if (this.base.options.target(this.data)) this.item.classList.add('dropable');
        }

        this.container = new Element('div', { 'class': 'container' });
        this.container.tabIndex = 0;
        this.item.appendChild(this.container);

        this.options.onInitialize(this.container, this.data);

        if (typeof this.data.children != 'undefined' && this.data.children) {
            if (this.options.interactive) {
                this.toggle = new Element('div', { 'class': 'toggle ' + this.state }).update('–');
                this.toggle.observe('click', this.onToggle.bind(this));
                this.item.appendChild(this.toggle);
            }

            this.list = new TreeList(base, this, this.item, this.data.children, this.options);
        } else {
            this.list = new TreeList(base, this, this.item, [], this.options);

            if (this.base.options.only) {
                if (this.base.options.only(this.data)) {
                    this.list.list.classList.add('dropable');
//						this.list.list.classList.add('dragable');
                }
            }
        }

        if ((this.options.value != null && this.data.id == this.options.value) || this.data.selected) {
            this.select(true);

            if (this.options.interactive && this.up && this.up.toggle) {
                window.setTimeout(function() {
                    this.up.toggleOpen();
                }.bind(this), 0);
            }
        }

        if (this.options.interactive && this.toggle && this.state == 'closed') {
            this.toggle.update('+');
            this.list.hide();
        }
    },

    deactivate: function() {
        this.container.tabIndex = -1;
    },

    activate: function() {
        this.container.tabIndex = 0;
    },
    
    focus: function() {
        this.container.focus();
    },

    onFocus: function(e) {
        if (e.target == this.toggle) {
            return;
        }

        this.item.classList.add('focus');
    },

    onBlur: function(e) {
        if (e.target == this.toggle) {
            return;
        }

        this.item.classList.remove('focus');
    },

    onKeyDown: function(event) {
        if (event.key == 'ArrowLeft' && this.toggle) {
            this.toggleClose();
            event.stopPropagation();
        }

        if (event.key == 'ArrowRight' && this.toggle) {
            this.toggleOpen();
            event.stopPropagation();
        }
    },

    onKeyPress: function(event) {
        if (event.key == 'Enter') {
            if (this.base.selected != this) {
                this.select();

                if (this.options.interactive && this.toggle) {
                    this.toggleOpen();
                }
            }
        }

        event.stopPropagation();
    },

    onSelect: function(e) {
        if (e.target == this.toggle) {
            return;
        }

        e.stop();

        if (this.base.selected != this) {
            this.select();

            if (this.options.interactive && this.toggle) {
                this.toggleOpen();
            }
        } else {
            if (this.options.interactive && this.toggle) {
                this.onToggle();
            }
        }
    },

    onToggle: function() {
        if (this.state == 'closed') {
            this.toggleOpen();
        } else {
            this.toggleClose();
        }
    },

    toggleOpen: function() {
        this.state = 'open';
        this.list.show();
        this.list.focus();
        this.toggle.classList.remove('closed');
        this.toggle.classList.add('open');
        this.toggle.update('–');

        if (this.up) {
            this.up.toggleOpen();
        }
    },

    toggleClose: function() {
        this.state = 'closed';
        this.list.hide();
        this.focus();
        this.toggle.classList.remove('open');
        this.toggle.classList.add('closed');
        this.toggle.update('+');
    },

    select: function(silent) {
        if (this.base.selected) {
            this.base.selected.deselect();
        }

        this.base.selected = this;

        if (this.base.options.onSelect && !silent) {
            this.base.options.onSelect(this.data);
        }

        this.item.classList.add('selected');
    },

    deselect: function() {
        this.base.selected = null;
        this.item.classList.remove('selected');
    },

    edit: function(data) {
        this.data = data;

        this.container.update('');
        this.options.onInitialize(this.container, this.data);
    },

    remove: function() {
        this.item.remove();
    },

    findParent: function(parent) {
        if (parent == this.data.id) {
            return this.list;
        }

        return this.list.findParent(parent);
    },

    findItem: function(id) {
        if (id == this.data.id) {
            return this;
        }

        return this.list.findItem(id);
    },
};
