
CSS.insert(`
    body .widget.slidingPanels.inlineType > ul { margin: 0; padding: 0; list-style-type: none; width: 190px; border-radius: 4px; background: var(--tab-vertical-background); }
    body .widget.slidingPanels.inlineType > ul > li > div.title { display: block; cursor: pointer; background: none; line-height: 100%; height: auto; font-size: 0.9em; color: var(--tab-vertical-text); padding: 6px 0 6px 10px; border-left: 1px solid var(--tab-vertical-border); border-right: 1px solid var(--tab-vertical-border); font-size: 0.8em; font-weight: var(--font-weight-bold); text-transform: var(--font-transform); text-align: left; }
    body .widget.slidingPanels.inlineType > ul > li:first-child > div.title { padding-top: 5px; border-top: 1px solid var(--tab-vertical-border); border-top-left-radius: 4px; border-top-right-radius: 4px; }
    body .widget.slidingPanels.inlineType > ul > li:last-child > div.title { padding-bottom: 5px; border-bottom: 1px solid var(--tab-vertical-border); border-bottom-right-radius: 4px; border-bottom-left-radius: 4px; }
    body .widget.slidingPanels.inlineType > ul > li.hasContainer > div.title {	border-bottom-right-radius: 0px; border-bottom-left-radius: 0px; }
    body .widget.slidingPanels.inlineType > ul > li.hasContainer:last-child > div.title {	border-bottom: none; }
    body .widget.slidingPanels.inlineType > ul > li.selected > div.title { padding-top: 5px; padding-bottom: 5px; border-style: solid; border-width: 1px 1px 1px 1px; border-color: var(--tab-vertical-selected-border) var(--tab-vertical-selected-border) var(--tab-vertical-selected-border) var(--tab-vertical-selected-border) !important; border-radius: 4px; color: var(--tab-vertical-selected-text); background: var(--tab-vertical-selected-background); }
    body .widget.slidingPanels.inlineType > ul > li > div.container { position: relative; background: #fff; border-left: 1px solid var(--tab-vertical-border); border: 1px solid var(--tab-vertical-border); padding: 10px 10px 0 10px; }
    body .widget.slidingPanels.inlineType > ul > li:last-child > div.container { border-bottom-right-radius: 4px; border-bottom-left-radius: 4px; }
    body .widget.slidingPanels.inlineType > ul > li > div.container:before { display: block; content: ' '; position: absolute; left: 86px; top: -7px; border-top: none; border-left: 6px solid transparent; border-right: 6px solid transparent; border-bottom: 6px solid var(--tab-vertical-border); width: 0; height: 0; }
    body .widget.slidingPanels.inlineType > ul > li > div.container:after { display: block; content: ' ';  position: absolute; left: 86px; top: -5px; border-top: none; border-left: 6px solid transparent; border-right: 6px solid transparent; border-bottom: 6px solid #fff; width: 0; height: 0; }
    body .widget.slidingPanels.inlineType > ul > li.selected > div.container:before { border-bottom: 6px solid var(--tab-vertical-selected-border); }
    body .widget.slidingPanels.inlineType > ul > li.separator { border-left: 1px solid var(--tab-vertical-border); border-right: 1px solid var(--tab-vertical-border); height: 3px; padding-top: 2px; }
    body .widget.slidingPanels.inlineType > ul > li.separator::after { border-top: 1px solid #e5e5e5; content: ' '; display: block; }
    
    body .widget.slidingPanels.panelsType > ul { margin: 0; padding: 0; list-style-type: none; }
    body .widget.slidingPanels.panelsType > ul > li { cursor: pointer; padding: 6px 0 6px 8px; margin: 0  0 16px; border: 1px solid var(--button-border); background: var(--button-background); border-radius: 4px; }
    body .widget.slidingPanels.panelsType > ul > li.selected { border-color: var(--colors-named-accent) var(--colors-named-accent) var(--colors-named-accent) var(--colors-named-accent) !important; border-radius: 4px; color: #fff; background: var(--colors-named-accent); }
    body .widget.slidingPanels.panelsType > ul > li > div.title { margin-bottom: 2px; font-weight: var(--font-weight-bold); font-size: 1.1em; text-transform: var(--font-transform); color: #888; }
    body .widget.slidingPanels.panelsType > ul > li.selected > div.title { color: #fff; }
    body .widget.slidingPanels.panelsType > ul > li > div.container { font-weight: var(--font-weight-bold); font-size: 0.8em; text-transform: var(--font-transform); }
    
    [data-input-mode=mouse] .widget.slidingPanels > ul > li:focus { outline: none; }
`);


Widgets.SlidingPanels = Class.create();
Widgets.SlidingPanels.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            className: 	null,
            type:		'inline',
            value:		null,
            items:		[]
        }, options || {});

        this.items = [];
        this._value = null;

        if (typeof parent.container != 'undefined') parent = parent.container;
        this.container = new Element('div', { 'class': 'widget slidingPanels ' + this.options.type + 'Type' });
        if (this.options.className) {
            this.container.classList.add(this.options.className);
        }
        parent.appendChild(this.container);

        if (this.options.label) {
            var label = new Element('label').update(this.options.label);
            this.container.appendChild(label);
        }

        this.tabs = new Element('ul');
        this.container.appendChild(this.tabs);

        for (var i = 0; i < this.options.items.length; i++) {
            this.add(this.options.items[i]);
        }
    },

    add: function(item) {
        var i = new Widgets.SlidingPanelsItem(this, item);
        this.items.push(i);

        return i;
    },

    clear: function() {
        for (var i = 0; i < this.items.length; i++) {
            this.items[i].destroy();
        }
    },

    select: function(item) {
        for (var i = 0; i < this.items.length; i++) {
            if (this.items[i] == item) {
                this.items[i].select();

            } else {
                this.items[i].unselect();
            }
        }
    },

    unselect: function() {
        for (var i = 0; i < this.items.length; i++) {
            this.items[i].unselect();
        }

        this._value = null;
    },

    get value() { return this._value; },
    set value(value) {
        for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].options.value == value) {
                this.items[i].select();
            } else {
                this.items[i].unselect();
            }
        }
    }
};

Widgets.SlidingPanelsItem = Class.create();
Widgets.SlidingPanelsItem.prototype = {
    initialize: function(parent, options) {
        this.parent = parent;
        this.options = Object.assign({
            type:			'',
            title: 			'',
            className:		null,
            value:			null,
            selected: 		false,
            alwaysVisible:	false,
            onSelect:		null,
            onInitialize:	null
        }, options || {});

        this.element = new Element('li');
        this.parent.tabs.appendChild(this.element);

        if (this.options.className) {
            this.element.classList.add(this.options.className);
        }

        if (this.options.type == 'separator') {
            this.element.classList.add('separator');
            return;
        }

        this.element.observe('click', this.click.bind(this));
        this.element.observe('keypress', this.keypress.bind(this));
        this.element.tabIndex = this.options.onInitialize ? -1 : 0;

        this.title = new Element('div', { 'class': 'title' }).update(escapeHTML(this.options.title));
        this.element.appendChild(this.title);

        if (this.options.onInitialize) {
            this.container = new Element('div', { 'class': 'container' });
            this.element.appendChild(this.container);
            if (!this.options.alwaysVisible) {
                this.container.hide();
            }

            this.element.classList.add('hasContainer');
        }

        if (this.options.value != null) {
            if (this.options.value == this.parent.options.value) {
                this.options.selected = true;
            }
        }

        if (this.options.selected) {
            this.element.classList.add('selected');
            this.parent._value = this.options.value;

            if (this.container && !this.options.alwaysVisible) {
                this.container.show();
            }
        } else {
            if (this.container && !this.options.alwaysVisible) {
                this.container.hide();
            }
        }

        if (this.options.onInitialize) {
            this.options.onInitialize(this.container);
        }
    },

    destroy: function() {
        this.element.stopObserving();
        this.element.remove();
    },

    keypress: function(event) {
        if (event.key == 'Enter') {
            this.parent.select(this);
        }
    },

    click: function() {
        this.parent.select(this);
    },

    select: function() {
        this.element.classList.add('selected');
        this.parent._value = this.options.value;

        if (this.options.onSelect) {
            this.options.onSelect();
        }

        document.fire("dom:update");

        if (this.container && !this.options.alwaysVisible) {
            this.container.show();
        }
    },

    unselect: function() {
        this.element.classList.remove('selected');

        if (this.container && !this.options.alwaysVisible) {
            this.container.hide();
        }
    },

    setTitle: function(title) {
        this.options.title = title;
        this.title.update(escapeHTML(this.options.title));
    }
};
