
CSS.insert(`
    body .widget.field { position: relative; }
    body .widget.field label { font-size: 0.8em; display: inline-block; }
    body .widget.field label.withPlaceholder { position: absolute; z-index: -1; margin-top: 3px; max-width: 100%; overflow: hidden; }
    body .widget.field input { width: 100%; box-sizing: border-box; margin: 2px 0; padding: 4px 5px; border: 1px solid var(--field-border); background: var(--field-background); font-size: 13px; line-height: 17.6px; -webkit-appearance: none; border-radius: 2px; }
    body .widget.field.suggestion input { color: #888; }
    body .widget.field textarea { width: 100%; box-sizing: border-box; margin: 2px 0; padding: 5px; border: 1px solid var(--field-border); background: var(--field-background); font-size: 13px; resize: none; -webkit-appearance: none; border-radius: 2px; }
    body .widget.field input::-moz-placeholder { font-size: 11px; font-style: italic; line-height: 15.6px; }
    body .widget.field input:-ms-input-placeholder { font-size: 11px; font-style: italic; line-height: 15.6px; }
    body .widget.field input::-webkit-input-placeholder { font-size: 11px; font-style: italic; line-height: 15.6px; }
    body .widget.field.required::after { content: ''; position: absolute; background: var(--field-required-color); width: 8px; top: 2px; bottom: 2px; right: 0px; border-top-right-radius: 2px; border-bottom-right-radius: 2px; opacity: 0.3; }
    body .widget.field.invalid input { border-color: var(--field-error-border); background: var(--field-error-background); }
    body .widget.field.disabled input { opacity: 0.5; }
    body .widget.field.disabled textarea { opacity: 0.5 ; }
    body .widget.field input:read-only, body .widget.field textarea:read-only { color: #666; }
    body .widget.field input:read-only:focus, body .widget.field textarea:read-only:focus { outline-color: #aaa; }
    body .widget.field.columnsStyle label { display: block; width: 100px; vertical-align: top; margin: 8px 0 0; }
    body .widget.field.columnsStyle div {  display: block; flex: 1; vertical-align: top; margin: 0 12px; }
    body .widget.field.columnsStyle :last-child { margin-right: 0; }
    body .widget.field.columnsStyle { width: 100%; display: flex; flex-direction: row; justify-content: space-between; }
    body .widget.field div span.description { display: block; font-size: 0.7em; color: #888; margin-top: 6px; }
    body .widget.field.inlineStyle { margin: 0; display: inline-block; }
    body .widget.field.inlineStyle input { font-size: 12px; line-height: 13px; padding: 3px; margin: 0; }
    body .widget.field.noneStyle { margin: 0; display: inline-block; }
    body .widget.field.noneStyle input { border:none; outline: none; background: none; font-size: 12px; line-height: 13px; padding: 3px; margin: 0; }
    body .widget.field.titleStyle { margin: 0; display: inline-block; width: 100%; }
    body .widget.field.titleStyle input { border:none; outline: none; background: none; font-size: 16px; line-height: 17px; font-weight: bold; padding: 0; margin: 0; }
    body .widget.field.cellStyle { margin: 0; display: inline-block; }
    body .widget.field.cellStyle input { width: 100%; height: 100%; box-sizing: border-box; padding: 4px; outline: none; }
    body .widget.field input[inputmode="decimal"] { font-variant-numeric: tabular-nums; }
    body .widget.field input[inputmode="numeric"] { font-variant-numeric: tabular-nums; }
`);

Widgets.Field = Class.create();
Widgets.Field.counter = 0;
Widgets.Field.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            name:           null,
            className:		null,
            style:			null,
            width:			null,
            height:			null,
            label: 			null,
            description:	null,
            allow:			null,
            type:			'text',
            hint:           null,
            lines:			1,
            maxLength:		null,
            readOnly:		false,
            selectOnFocus:	false,
            autoComplete:	false,
            autoCapitalize:	false,
            spellCheck:     true,
            lowercase:		false,
            uppercase:		false,
            autoCorrect:	true,
            prefix:         null,
            onChange:		null,
            onEnter:		null,
            onFormat:		null,
            onValidate:		null,
            onBlur:			null,
            onFocus:        null,
            onClick:        null,
            onActivate:     null,
            allowInvalid:   false,
            value:			'',
            frequency:		0.4,
            minChars:		1,
            enabled:		true,
            required:		false,
            placeholder:	'',
        }, options || {});

        this._value = this.options.value != null ? String(this.options.value) : '';
        this._suggestable = this._value != '' ? false : true;
        this._valid = true;

        if (typeof parent.container != 'undefined') parent = parent.container;
        this.container = new Element('div', { 'class': 'widget field' });
        parent.appendChild(this.container);

        if (this.options.name) {
            this.container.dataset.name = this.options.name;
        }

        if (this.options.style) {
            this.container.classList.add(this.options.style + 'Style');
        }

        if (this.options.width) {
            this.container.setStyle({ width: this.options.width });
        }

        if (this.options.label || this.options.placeholder) {
            this.label = new Element('label').update(this.options.label ? this.options.label : this.options.placeholder);
            this.container.appendChild(this.label);
        }

        if (this.options.className) {
            this.container.classList.add(this.options.className);
        }

        if (this.options.required) {
            this.container.classList.add('required');
        }

        var wrapper = new Element('div');
        this.container.appendChild(wrapper);

        if (this.options.lines == 1) {
            this.field = new Element('input', { type: this.options.type, maxLength: this.options.maxLength, value: this._value, placeholder: this.options.placeholder });

            if (!this.options.autoCapitalize) {
                this.field.setAttribute("autocapitalize", "none");
            }

            if (!this.options.autoCorrect) {
                this.field.setAttribute("autocorrect", "off");
            }

            if (!this.options.spellCheck) {
                this.field.setAttribute('spellcheck','false');
            }

            if (this.options.allow && !this.options.hint) {
                switch (this.options.allow) {
                    case '1234567890': 
                        this.options.hint = 'numeric';
                        break;      
                    case '1234567890,.':
                        this.options.hint = 'decimal';
                        break;      
                    case '1234567890,.-':
                        this.options.hint = 'decimal';
                        break;      
                }
            }

            if (this.options.hint) {
                this.field.setAttribute('inputmode', this.options.hint);
            }

            if (this.options.type == 'text') {
                if (this.options.autoComplete) {
                    this.field.setAttribute("autocomplete", typeof this.options.autoComplete === 'string' ? this.options.autoComplete : 'on');
                } else {
                    this.field.setAttribute("autocomplete", 'off');
                }
            }
        } else {
            this.field = new Element('textarea', { rows: this.options.lines }).update(this._value);

            if (this.options.height) {
                this.field.setStyle({ height: this.options.height });
            }
        }
        
        if (this.options.name) {
            this.field.name = this.options.name;
        }

        this.field.readOnly = this.options.readOnly;

        this.field.observe('click', this.onClick.bind(this));
        this.field.observe('keydown', this.onKeyDown.bindAsEventListener(this));
        this.field.observe('keypress', this.onKeyPress.bindAsEventListener(this));
        this.field.observe('input', this.onInput.bindAsEventListener(this));
        this.field.observe('change', this.onObserverEvent.bind(this));
        this.field.observe('blur', this.onBlur.bind(this));
        this.field.observe('focus', this.onFocus.bind(this));
        wrapper.appendChild(this.field);

        if (this.options.description) {
            this.description = new Element('span', { 'class': 'description' }).update(this.options.description);
            wrapper.appendChild(this.description);
        }

        if (this.options.label || this.options.placeholder) {
            Widgets.Field.counter = Widgets.Field.counter + 1;
            this.field.id = 'widget_field_' + Widgets.Field.counter;
            this.label.setAttribute('for', 'widget_field_' + Widgets.Field.counter);

            if (!this.options.label) {
                this.label.classList.add('withPlaceholder');
            }
        }

        this.options.enabled ? this.enable() : this.disable();
    },

    destroy: function() {
        this.field.stopObserving();
        this.container.remove();
    },

    focus: function() {
        this.field.focus();
    },

    blur: function() {
        this.field.blur();
    },

    select: function() {
        this.field.select(); 
    },

    suggest: function(value) {
        if (this._suggestable) {
            this.container.classList.add('suggestion');
            this.value = value;
        }
    },

    confirm: function() {
        this._suggestable = false;
        this.container.classList.remove('suggestion');
    },

    validate: function(value) {
        if (this.options.onValidate) {
            this._valid = this.options.onValidate(this._value);

            if (this._valid) {
                this.container.classList.remove('invalid');
            } else {
                this.container.classList.add('invalid');
            }
        }
    },

    onClick: function(e) {
        if (this.options.onClick) {
            this.options.onClick();
        }

        if (this.options.onActivate) {
            this.options.onActivate();
        }
    },

    onKeyDown: function(event) {
        if (this.options.hint == 'decimal') {
            this.preventCommaFromBeingDeleted(event);
        }
    },

    preventCommaFromBeingDeleted: function(event) {
        if (event.code == 'Backspace' || event.code == 'Delete') {
            let selected = '';

            if (this.field.selectionStart == this.field.selectionEnd) {
                if (event.code == 'Backspace') {
                    selected = this.field.value.substr(this.field.selectionStart - 1, 1);
                } else {
                    selected = this.field.value.substr(this.field.selectionStart, 1);
                }
            }
            else {
                selected = this.field.value.substr(this.field.selectionStart, this.field.selectionEnd - this.field.selectionStart);
            }

            if (selected == ',') {
                event.preventDefault();

                if (this.field.selectionStart == this.field.selectionEnd) {
                    if (event.code == 'Backspace') {
                        this.field.selectionStart = this.field.selectionStart - 1
                        this.field.selectionEnd = this.field.selectionStart;
                    } else {
                        this.field.selectionEnd = this.field.selectionEnd + 1;
                        this.field.selectionStart = this.field.selectionEnd;
                    }
                }
                else {
                    if (event.code == 'Backspace') {
                        this.field.selectionEnd = this.field.selectionStart;
                    } else {
                        this.field.selectionStart = this.field.selectionEnd;
                    }
                }
            }
        }

        if (event.code == 'Backspace') {
            
            /* We are deleting the last remaining character and the remainder is just zero's... */
            
            if (this.field.selectionStart == 1 && this.field.selectionEnd == 1) {
                if (this.field.value.substr(1).match(/^0*,00/)) {
                    this.field.select();
                }
            }
        }
    },

    onKeyPress: function(event) {
        this.confirm();

        if (event.keyCode == Event.KEY_RETURN) {

            /* onEnter is often used to submit values, so we need to make sure _value and _valid are up to date. */

            this.onObserverEvent();

            if (this.options.onEnter) {
                this.options.onEnter();
            }
        }

        if (this.options.allow) {
            if (event.charCode != 0 && this.options.allow.indexOf(String.fromCharCode(event.charCode)) < 0) {
                event.stop();
                return;
            }
        }

        if (this.observer)
            clearTimeout(this.observer);

        this.observer = setTimeout(this.onObserverEvent.bind(this), this.options.frequency * 1000);
    },

    onInput: function(event) {
        this.confirm();

        if (this.options.autoCapitalize && this.field.value.length) {
            var value = this.field.value[0].toUpperCase() + this.field.value.substr(1);

            if (value != this.field.value) {
                var selectionStart = this.field.selectionStart;
                var selectionEnd = this.field.selectionEnd;

                this.field.value = value;

                this.field.selectionStart = selectionStart;
                this.field.selectionEnd = selectionEnd;
            }
        }

        if (this.options.uppercase && this.field.value.length) {
            var value = this.field.value.toUpperCase();

            if (value != this.field.value) {
                var selectionStart = this.field.selectionStart;
                var selectionEnd = this.field.selectionEnd;

                this.field.value = value;

                this.field.selectionStart = selectionStart;
                this.field.selectionEnd = selectionEnd;
            }
        }

        if (this.options.lowercase && this.field.value.length) {
            var value = this.field.value.toLowerCase();

            if (value != this.field.value) {
                var selectionStart = this.field.selectionStart;
                var selectionEnd = this.field.selectionEnd;

                this.field.value = value;

                this.field.selectionStart = selectionStart;
                this.field.selectionEnd = selectionEnd;
            }
        }

        if (this.options.onFormat && this.field.value.length) {
            var value = this.options.onFormat(this.field.value);

            if (value != this.field.value) {
                var selectionStart = this.field.selectionStart;
                var selectionEnd = this.field.selectionEnd;

                this.field.value = value;

                this.field.selectionStart = selectionStart;
                this.field.selectionEnd = selectionEnd;
            }
        }

        if (this.options.prefix && this.field.value.length) {
            let usesPrefix = this.field.value.startsWith(this.options.prefix);

            if (!usesPrefix) {
                var selectionStart = this.field.selectionStart;
                var selectionEnd = this.field.selectionEnd;

                this.field.value = this.options.prefix + this.field.value;

                this.field.selectionStart = selectionStart + this.options.prefix.length;
                this.field.selectionEnd = selectionEnd + this.options.prefix.length;
            }
        }

        if (this.observer)
            clearTimeout(this.observer);

        this.observer = setTimeout(this.onObserverEvent.bind(this), this.options.frequency * 1000);
    },

    onObserverEvent: function() {
        if (this.field.value != this._value) {
            this._value = this.field.value;

            this.validate();

            if (this.options.onChange) {
                this.options.onChange(this.value);
            }

            if (this.options.onActivate) {
                this.options.onActivate();
            }
        }
    },

    onFocus: function() {
        if (this.options.selectOnFocus) {
            this.field.select();
        }	

        if (this.options.onFocus) {
            this.options.onFocus();
        }

        if (this.options.onActivate) {
            this.options.onActivate();
        }
    },

    onBlur: function() {
        this.onFormat();

        if (this.options.onBlur) {
            this.options.onBlur(this.value);
        }

        this._suggestable = this.field.value != '' ? false : true;
    },

    onFormat: function() {
        if (this.options.onFormat) {
            this._value = this.options.onFormat(this._value);
        }
    },

    get valid() { return this._valid; },
    set valid(value) {
        this._valid = value;

        if (this._valid) {
            this.container.classList.remove('invalid');
        } else {
            this.container.classList.add('invalid');
        }
    },

    get value() { return this._valid || this.options.allowInvalid ? this._value : null; },
    set value(value) {
        this._value = String(value);
        this.field.value = this._value;
        this.validate();
    },

    enable: function() {
        this.options.enabled = true;
        this.container.classList.remove('disabled');
        this.field.disabled = false;
    },

    disable: function() {
        this.options.enabled = false;
        this.container.classList.add('disabled');
        this.field.disabled = true;
    },

    get enabled() { return this.options.enabled; },
    set enabled(value) { value ? this.enable() : this.disable(); },
    get disabled() { return !this.options.enabled; },
    set disabled(value) { !value ? this.enable() : this.disable(); }
};
