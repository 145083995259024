
Formatter = (function() {
	function pad(number, length) {
	    var str = '' + number;
	    while (str.length < length) {
	        str = str + '0';
	    }

	    return str;
	}

	function formatNumber(nStr, decimals, dot) {
		nStr += '';
		x = nStr.split('.');
		x1 = x[0];
		x2 = '';

		if (decimals > 0) {
			if (x.length > 1) {
				x2 = ',' + pad(x[1], decimals);
			} else {
				x2 = ',' + pad('', decimals);
			}
		}

		if (typeof dot == 'undefined' || dot) {
			var rgx = /(\d+)(\d{3})/;
			while (rgx.test(x1)) {
				x1 = x1.replace(rgx, '$1' + '.' + '$2');
			}
		}

		return x1 + x2;
	}

	function roundNumber (value, decimals) {
		return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
	}

	return {
		number:	formatNumber,
		fraction: roundNumber,

		currency: function(value, decimals, sign) {
			if (!decimals) {
				decimals = 0;
			}

			value = roundNumber(value, decimals);

			if (sign) {
				return '€\u00A0' + (value > 0 ? '+' : '') + formatNumber(value, decimals);
			}

			return '€\u00A0' + formatNumber(value, decimals);
		},

		percentage: function(value, decimals, sign) {
			if (!decimals) {
				decimals = 0;
			}

			value = roundNumber(value, decimals);

			if (isNaN(value)) {
				return '-';
			}

			if (value == 'Infinity') {
				return '∞';
			}

			if (sign) {
				return (value > 0 ? '+' : '') + formatNumber(value, decimals) + '%';
			}

			return formatNumber(value, decimals) + '%';
		},

		yearweek: function(yearweek) {
			return 'Week ' + parseInt(yearweek.substr(4), 10) + ', ' + yearweek.substr(0, 4);
		},

		hours: function(d, options) {
			return Formatter.minutes(d * 60, options);
		},

		minutes: function(d, options) {
			options = Object.assign({
				useDashForZero:	true,
				minutesAreOptional: false,
				formatAsSentence: false
			}, options || {});

			var negative = false;

			if (!d && options.useDashForZero) return '-';

			if (d < 0) {
				d = 0 - d;
				negative = true;
			}

			var h = Math.floor(d / 60);
			var m = parseInt(d % 60, 10);

			if (options.formatAsSentence) {
				return (negative ? '-' : '') + h + ' uur' + (options.minutesAreOptional && !parseInt(m,10) ? '' : ' en ' + m + ' minuten');
			} else {
				if (m < 10) m = '0' + m;
				return (negative ? '-' : '') + h + (options.minutesAreOptional && !parseInt(m,10) ? '' : ':' + m);
			}
		},

		time: function(hour, minute) {
			if (minute == undefined) {
				minute = hour;
				hour = Math.floor(minute / 60);
				minute = minute - (hour * 60);
			}

			return hour + ':' + (minute < 10 ? '0' : '') + minute;
		},

		weekday: function(weekday) {
			if (weekday == 7) weekday = 0;
			return moment.localeData()._weekdays[weekday];
		}
	};
})();


Formatter.email = t => {
	if (t) {
		return String(t).toLowerCase().trim();
	}

	return '';
};

Formatter.telephone = function(t) {
	if (!t) {
		return '';
	}

	t = t.trim();

	if (t.substr(0,1) == '+') {
		t = t.slice(1);
		t = t.replace(/\(0\)/g, '');
	}

	if (t.substr(0,3) == '(00') {
		t = t.slice(3);
	}

	if (t.substr(0,2) == '00') {
		t = t.slice(2);
	}

	if (t.substr(0,1) == '0') {
		return Formatter.telephoneDutch(t);
	}

	if (t.substr(0,2) == '31') {
		return Formatter.telephoneDutch('0' + t.slice(2));
	}

	let digits = t.replace(/[^0-9]/g, '');
	
	if (digits.length > 7 && digits.substr(0,1) >= '1' && digits.substr(0,1) <= '9') {
		return Formatter.telephoneInternational(digits);
	}

	return t.trim();
}

Formatter.telephoneInternational = function(t) {
	let callingCode = 3;

	let oneDigit = parseInt(t.substr(0,1), 10);
	if ([ 1, 7 ].includes(oneDigit)) {
		callingCode = 1;
	}

	let twoDigits = parseInt(t.substr(0,2), 10);
	if ([ 20, 30, 31, 32, 33, 34, 36, 39, 40, 41, 43, 44, 45, 46, 47, 48, 49, 51, 52, 53, 54, 55, 56, 57, 58, 60, 61, 62, 63, 64, 65, 66, 81, 82, 84, 86, 90, 91, 92, 93, 94, 95, 98 ].includes(twoDigits)) {
		callingCode = 2;
	}

	return '+' + t.substr(0,callingCode) + ' ' + t.substr(callingCode);
}

Formatter.telephoneDutch = function(t) {
	t = t.replace(/[^0-9]/g, '');

	if (t.substr(0,2) == '06') {
		return '06-' + t.slice(2);
	}

	if (t.substr(0,1) != '0' || t.length != 10) {
		return t;
	}

	var check = t.substr(0,3);
	var valid = [ '010', '013', '015', '020', '023', '024', '026', '030', '033', '035', '036', '038', '040', '043', '045', '046', '050', '053', '055', '058', '070', '071', '072', '073', '074', '075', '076', '077', '078', '079', '082', '084', '085', '087', '088', '091' ];

	var found = false;
	for (var i = 0; i < valid.length; i++) {
		if (check == valid[i]) {
			found = true;
		}
	}

	if (found) {
		return '(' + t.substr(0,3) + ') ' + t.slice(3);
	}

	return '(' + t.substr(0,4) + ') ' + t.slice(4);
}


Formatter.paymentMethod = function(m) {
	let results = [];
	let methods = m;

	if (!(methods instanceof Array)) {
	 	methods = String(methods).split(',');
	}

	for (var i = 0; i < methods.length; i++) {
		switch(parseInt(methods[i],10)) {
			case _PAYMENT_NONE_:		results.push('Niet te innen'); break;
			case _PAYMENT_CASH_:		results.push('Contant'); break;
			case _PAYMENT_DEBITCARD_:	results.push('PIN'); break;
			case _PAYMENT_DELAYED_:		results.push('Uitgesteld'); break;
			case _PAYMENT_INVOICE_:		results.push('Factuur'); break;
			case _PAYMENT_BANK_:		results.push('Per bank'); break;
			case _PAYMENT_CREDITCARD_:	results.push('Creditcard'); break;
			case _PAYMENT_VVV_:			results.push('VVV bon'); break;
			case _PAYMENT_OTHER_:		results.push('Anders'); break;
			case _PAYMENT_REQUEST_:		results.push('Betaalverzoek'); break;
			case _PAYMENT_MUTATION_:	results.push('Op andere bon'); break;
			case _PAYMENT_MUTATION_:	results.push('Op andere bon'); break;
			case _PAYMENT_WRITEOFF_:	results.push('Niet te innen'); break;
		}
	}

	return results.join(', ');
}

Formatter.stockStatus = function(m) {
	switch(parseInt(m, 10)) {
		case _STOCK_STATUS_STARTED_:		return ''; break;
		case _STOCK_STATUS_BACKORDERED_:	return '<span class="isIcon" style="color: var(--colors-gray);"></span> &nbsp;Gedeeltelijk naleveren'; break;
		case _STOCK_STATUS_DELIVERED_:		return '<span class="isIcon" style="color: var(--colors-green);"></span> &nbsp;Geleverd'; break;
		case _STOCK_STATUS_SEND_:			return '<span class="isIcon" style="color: var(--colors-gray);">●</span> &nbsp;Opdracht verzonden'; break;
		case _STOCK_STATUS_RECEIVED_:		return '<span class="isIcon" style="color: var(--colors-gray);">✓</span> &nbsp;Opdracht ontvangen'; break;
		case _STOCK_STATUS_CONFIRMED_:		return '<span class="isIcon" style="color: var(--colors-green);">✓</span> &nbsp;Opdracht bevestigd'; break;
		case _STOCK_STATUS_FAILED_:			return '<span class="isIcon" style="color: var(--colors-red);">⚠</span> &nbsp;Niet te leveren'; break;
	}

	return '';
}

Formatter.reasonAsType = function(d) {
	switch(parseInt(d, 10)) {
		case 0: case 210: case 211: case 212: case 213: case 214: case 215:
		case 220: case 221:
			return 'Gewerkt';

		case 10: case 13: case 14: case 12: case 11: case 20: case 21:
		case 22: case 23: case 30: case 31:	case 32: case 40: case 41:
		case 42: case 50: case 51: case 52: case 60: case 61: case 70:
		case 71: case 72: case 73: case 112: case 113: case 111: case 110:
			return 'Afwezig';
	}

	return '';
};

Formatter.reason = function(d) {
	switch(parseInt(d, 10)) {
		case 0:		return 'Volgens rooster';

		case 10:	return 'Snipperdag';
		case 13:	return 'Uitlenen aan andere salon';
		case 14:	return 'Alternatieve werkzaamheden';
		case 15:	return 'Training volgen binnen werktijd';
		case 12:	return 'Wisseldienst';
		case 11:	return 'Ziekte';
		case 20:	return 'Ondertrouw';
		case 21:	return 'Sluiten samenlevingscontract';
		case 22:	return 'Huwelijk, eigen';
		case 23:	return 'Huwelijk, directe familie';
		case 30:	return 'Zwangerschapsverlof';
		case 31:	return 'Geboorteverlof partner';
		case 32:	return 'Betaald ouderschapsverlof';
		case 40:	return '12,5-jarig dienstjubileum';
		case 41:	return '12,5-, 25- of 40-jarig huwelijk, eigen';
		case 42:	return '25-, 40-, 50- of 60-jarig huwelijk, directe familie';
		case 50:	return 'Overlijden partner of kind';
		case 51:	return 'Overlijden directe familie';
		case 52:	return 'Begrafenis directe familie';
		case 60:	return 'Dokters- of tandartsbezoek';
		case 61:	return 'Bezoek medisch specialist';
		case 70:	return 'Vakexamen';
		case 71:	return 'Verhuizing';
		case 72:	return 'Nieuwe baan zoeken';
		case 73:	return 'Vakbondsverlof';
		case 112:	return 'Salon gesloten (verbouwing)';
		case 113:	return 'Salon gesloten (overige)';
		case 111:	return 'Verplichte snipperdag';
		case 110:	return 'Feestdag volgens CAO';

		case 210:	return 'Extra uren';
		case 211:	return 'Inhalen opgenomen uren';
		case 212:	return 'Inlenen van andere salon';
		case 213:	return 'Wisseldienst';
		case 214:	return 'Training volgen buiten werktijd';
		case 215:	return 'Therapeutisch werken';

		case 220:	return 'Overwerk (25% toeslag)';
		case 221:	return 'Overwerk (100% toeslag)';
	}

	return '';
};


Formatter.bytes = function(bytes) {
	var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes == 0) return '0 Byte';
	var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};


Formatter.tax = function(m, zeroAsDigit) {
	switch(parseInt(m, 10)) {
		case _TAX_0_:	return zeroAsDigit ? '0%' : '-'; break;
		case _TAX_6_:	return '6%'; break;
		case _TAX_9_:	return '9%'; break;
		case _TAX_19_:	return '19%'; break;
		case _TAX_21_:	return '21%'; break;
	}

	return '';
};




Formatter.relativeTime = function(ts, base) {
	var diff = ts - base;
	
	if (diff < 10) {
		return 'Direct';
	}
	
	if (diff < 60) {
		return 'Binnen een minuut';
	}
	
	if (diff < 60 * 60) {
		return 'Na ' + Math.floor(diff / 60) + ' minuten';
	}

	if (diff < 60 * 60 * 24) {
		return 'Na ' + Math.floor(diff / (60 * 60)) + ' uur';
	}
	
	return 'Na meer dan een dag';
}


Formatter.delay = function(ts, base) {
	var diff = ts - base;
	
	if (diff < 60) {
		return '-';	
	}
	
	if (diff < 120) {
		return '1 minuut';	
	}
	
	if (diff < 60 * 60) {
		return Math.floor(diff / 60) + ' minuten';
	}

	if (diff < 60 * 60 * 24) {
		return Math.floor(diff / (60 * 60)) + ' uur';
	}
	
	if (diff < 60 * 60 * 48) {
		return '1 dag';
	}

	return Math.floor(diff / (60 * 60 * 24)) + ' dagen';
}



Formatter.messageState = function(m) {
	switch(m) {
		case 'created': 		return 'Bezig...';
		case 'send':			return 'Afgeleverd';
		case 'bounced':			return 'Niet gelukt';
		case 'rejected':		return 'Niet gelukt';
		case 'unsub':			return 'Uitgeschreven';
		case 'spam':			return 'Gemarkeerd als spam';
		case 'suppressed': 		return 'Tegengehouden';
	}	

	return '-';
}

Formatter.messageReason = function(m) {
	switch(m) {
		case 'blocked':			return 'Geblokkeerd als spam';
		case 'server-unknown': 	return 'Ongeldige domeinnaam';
		case 'server-offline': 	return 'Ontvangende server offline';
		case 'user-unknown':	return 'Ongeldige gebruiker';
		case 'user-quota':		return 'Mailbox zit vol';
		case 'number-unknown':	return 'Ongeldig nummer';
		case 'number-offline':	return 'Niet bereikbaar';
	}	

	return '-';
}

Formatter.messageType = function(m) {
	switch(m) {
		case 'account': 	    return 'Account gegevens';
		case 'salon': 	        return 'Salon gegevens';
		case 'widget': 	        return 'Widget gegevens';
		case 'audit': 	        return 'Auditbestand';
		case 'export': 	        return 'Exportbestand';
		case 'data-online': 	return 'GDPR verzoek widget/app';
		case 'data-customer':   return 'GDPR verzoek salon';
		case 'onlineconfirm': 	return 'Online bevestiging';
		case 'onlinecode':		return 'Online code';
		case 'confirmation':	return 'Bevestiging';
		case 'reschedule':		return 'Wijziging';
		case 'cancelation':	    return 'Annulering';
		case 'reminder':		return 'Herinnering';
        case 'invitation':		return 'Uitnodiging';
        case 'purchaseorder':	return 'Bestelling';
		case 'campaign':		return 'Campagne';
		case 'campaign-test':	return 'Test voor campagne';
		case 'receipt':		    return 'Kassabon';
		case 'paymentrequest':	return 'Betaalverzoek';
	}	

	return '-';
}

Formatter.emailProvider = function(m) {
	switch(m) {
		case 'sparkpost|eu|dedicated': 	    return 'Sparkpost (EU, Dedicated)';
		case 'sparkpost|eu': 	            return 'Sparkpost (EU)';
		case 'sparkpost|dedicated': 	    return 'Sparkpost (US, Dedicated)';
		case 'sparkpost': 	                return 'Sparkpost (US)';
		case 'sendgrid': 	                return 'Sendgrid';
		case 'mandrill': 	                return 'Mandrill';
	}	

	return '-';
}

Formatter.smsProvider = function(m) {
	switch(m) {		
        case 'messagebird': 	        return 'Messagebird';
        case 'spryng': 	                return 'Spryng';
        case 'twilio': 	                return 'Twilio';
	}	

	return '-';
}
