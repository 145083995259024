
CSS.insert(`
    .widget.dropdownCalendar > label { display: block; font-size: 0.8em; color: #666; margin: 0 0 8px; } 
    .widget.dropdownCalendar > button { position: relative; display: inline-flex; height: 24px; line-height: 24px; cursor: pointer; font-size: 0.8rem; font-weight: var(--font-weight-bold); text-transform: var(--font-transform); text-align: left; border: 1px solid var(--button-border); border-radius: 4px; background: var(--button-background); color: var(--button-text); flex-direction: row; align-items: center; flex-wrap: nowrap; } 
    .widget.dropdownCalendar > button:hover { background: var(--button-hover-background); } 
    .widget.dropdownCalendar > button:active { background: var(--button-active-background); } 
    .widget.dropdownCalendar > button.open { background: var(--button-hover-background);} 
    .widget.dropdownCalendar > button .title { text-shadow: 0px -1px 0px rgba(255,255,255,0.5); white-space: nowrap; padding: 0 10px 0 10px; flex-grow: 1; } 
    .widget.dropdownCalendar > button .toggle { text-shadow: none; border-left: 1px solid var(--button-border); margin: 0; padding: 0 5px; color: #888; line-height: 24px; font-family: SalonWidgets; font-size: 12px; content: '⌵'; } 
    .widget.dropdownCalendar > button .panel { position: absolute; top: -8px; left: 9px; display: none; border-radius: 6px; border: 1px solid var(--menu-border); z-index: 100; clear: both; margin: 1px 7px 0 -10px; padding: 3px 10px 0 3px; background: #fff; line-height: 130%;  box-shadow: 0 0 6px rgba(0,0,0,0.15); filter: drop-shadow(0px 0px 8px rgba(0,0,0,0.25)); font-size: 1rem; } 
    .widget.dropdownCalendar > button.open > .panel { display: block; } 
    .widget.dropdownCalendar.columnsStyle > label { display: inline-block; width: 100px; color: #000; } 
    .widget.dropdownCalendar.columnsStyle > button { margin-left: 12px; } 
`);

Widgets.DropdownCalendar = Class.create();
Widgets.DropdownCalendar.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            className: 	null,
            value:		new Date(),
            minDate:	null,
            maxDate:	null,
            label:		null,
            format:		'D MMMM YYYY',
            unknown:	'',
            style:		null,
            onChange:	null
        }, options || {});

        this.value = this.options.value; 
                        
        document.body.observe('click', this.close.bindAsEventListener(this))

        if (typeof parent.container != 'undefined') parent = parent.container;

        this.container = new Element('div', { 'class': 'widget dropdownCalendar' });
        parent.appendChild(this.container);
        
        if (this.options.style) {
            this.container.classList.add(this.options.style + 'Style');
        }

        if (this.options.label) {
            var label = new Element('label').update(this.options.label);
            this.container.appendChild(label);
        }

        this.widget = new Element('button');
        this.widget.observe('click', this.toggle.bindAsEventListener(this));
        if (this.options.className) {
            this.widget.classList.add(this.options.className);
        }
        this.container.appendChild(this.widget);
        
        this.current = new Element('div').update(this.value ? moment(this.value).format(this.options.format) : this.options.unknown);
        this.current.classList.add('title');
        this.widget.appendChild(this.current);

        this.toggle = new Element('div').update('⌵');
        this.toggle.classList.add('toggle');
        this.widget.appendChild(this.toggle);
        
        this.panel = new Element('div');
        this.panel.classList.add('panel');
        this.panel.observe('click', function(e) { e.stop(); });
        this.widget.appendChild(this.panel);

        this.calendar = new Widgets.Calendar(this.panel, { showWeekNumber: true, showControls: true, showYearNavigation: true, date: this.value ? this.value : new Date(), active: this.value ? this.value : new Date(), minDate: this.options.minDate, maxDate: this.options.maxDate });
        this.calendar.addEventListener("dayclick", this.onSelect.bind(this));
    },

    onSelect: function(date) {
        this.calendar.setActive(date);

        this.value = new Date(date);
        this.current.update(this.value ? moment(this.value).format(this.options.format) : this.options.unknown);
        
        if (this.options.onChange) {
            this.options.onChange(new Date(date));
        }
        
        this.close();
    },

    toggle: function(e) {
        e.stop();
        
        this.widget.classList.toggle('open');
    },

    close: function() {
        this.widget.classList.remove('open');
    }
};
