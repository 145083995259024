Window.ComposeEmail = (function() {
	ComposeEmailWindow = Class.create();
	ComposeEmailWindow.prototype = {
		initialize: function(options) {
            this.options = options;

			this.window = new Window({
				width:		540,
				height: 	544,
				className: 	'composeEmail',
				onClose:	this.destroy.bind(this),
			});
			
            this.title = new Widgets.Field(this.window, {
                value:          this.options.title,
                style:			'title',
            });

            new Widgets.Separator(this.window, {
                type:		'line',
				width:		'page'
            });

            let panel = new Widgets.Scroll(this.window, {
                height:		'420px',
                style:		'full'
            });

            new Widgets.TextEditor(panel, {
                value:		    this.options.contents,
                hideToolbar:    true,
                onChange:       (value) => {
                    this.options.contents = value;
                }
            });

            var flex = new Layout.Flex(this.window.footer, {
                orientation:	'horizontal',
                pack:			'justify',
                items:			[ { name: 'left' }, { name: 'right' } ]
            });
    
            this.cancelButton = new Widgets.Button(flex.items.right, {
				title:		'Annuleer',
				onClick:	this.onCancel.bind(this)
			});
			
			this.notifyButton = new Widgets.Button(flex.items.right, {
				title:		'Verstuur',
				color:		'green',
				onClick:	this.onSave.bind(this)
			});
	
			this.window.show();		
		},
		
		destroy: function() {
		},
		
        onCancel: function (e) {
            this.window.close();
        },

		onSave: function (e) {
			this.window.close();

            this.options.onSave({
                title: this.title.value,
                contents: this.options.contents
            });
		}
	}
	
	return ComposeEmailWindow; 
})();	
