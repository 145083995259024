(function() {
	var timer = null;

	function fireEvent() {
		document.fire("dom:idle");
	}

	function reachedTimeout() {
		fireEvent();
		startTimeout();
	}

	function interruptTimeout() {
		window.clearTimeout(timer);
		startTimeout();
	}

	function startTimeout() {
		timer = window.setTimeout(reachedTimeout, 60000);
	}

	Event.observe(document, 'mousemove', interruptTimeout);
	Event.observe(document, 'keypress', interruptTimeout);
	Event.observe(document, 'touchstart', interruptTimeout);
	startTimeout();
})();
