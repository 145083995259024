Widgets.Field.Number = Class.create();
Widgets.Field.Number.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            value:          null,
            allowNegative:  false,
            onChange:	    null
        }, options || {});

        this.toString = (value) => {
            if (value === null || typeof value === 'undefined') {
                return '';
            }

            if (value === '-') {
                return '-';
            }

            let int = parseInt(value, 10);

            if (!this.options.allowNegative) {
                int = Math.abs(int);
            }

            if (!isNaN(int)) {
                return String(int);
            }

            return '';
        }

        this.valueOf = (value) => {
            let int = parseInt(value, 10);

            if (!this.options.allowNegative) {
                int = Math.abs(int);
            }

            return !isNaN(int) ? int : null;
        }

        let fieldOptions = {
            hint:       'numeric',
            allow:      this.options.allowNegative ? '1234567890-' : '1234567890',
            value:      this.toString(this.options.value),
            onFormat:   this.toString,
        }

        if (this.options.onChange) {
            fieldOptions.onChange = (value) => this.options.onChange(this.valueOf(value));
        }

        if (this.options.onValidate) {
            fieldOptions.onValidate = (value) => this.options.onValidate(this.valueOf(value));
        }

        this.field = new Widgets.Field (parent, Object.assign({}, this.options, fieldOptions));
    },

    destroy: function() {
        this.field.destroy();
    },

    focus: function() {
        this.field.focus();

        /* 
            If we deliberately focus a formatted field, such as this, we need to
            select the whole text to make sure the user can start typing immediately
            to replace the current text. 
        */

        this.field.select();
    },

    blur: function() {
        this.field.blur();
    },

    get enabled() { return this.field.enabled; },
    set enabled(value) {
        this.field.enabled = value;
    },

    get valid() { return this.field.valid; },

    get value() { return this.valueOf(this.field.value); },
    set value(value) {
        this.field.value = this.toString(value);
    }
};
