
CSS.insert(`
    .widget.inlinehelp { position: relative; font-size: 0.9em; font-weight: var(--font-weight-bold); color: #bbb; cursor: pointer; }
`);

Widgets.InlineHelp = Class.create();
Widgets.InlineHelp.prototype = {
    initialize: function (parent, options) {
        this.options = Object.assign({
            contents:	'',
        }, options || {});

        if (typeof parent.container != 'undefined') parent = parent.container;
        this.button = new Element('a', { 'class': 'widget inlinehelp' }).update('?');
        parent.appendChild(this.button);

        new Widgets.Tooltip(this.button, { contents: this.options.contents, tipJoint: "right", target: true, delay: 0.4 });
    }
};
