
CSS.insert(`
    .widget.hourField label { font-size: 0.8em; display: inline-block; } 
    .widget.hourField > div { position: relative; } 
    .widget.hourField div.control { display: inline-block; box-sizing: border-box; margin: 2px 0; padding: 0; border: 1px solid var(--field-border); background: var(--field-background); font-size: 13px; } 
    .widget.hourField div.control input { border: none; background: transparent; font-size: 13px; width: 16px; padding: 5px 2px; text-align: right; } 
    .widget.hourField div.control input.hourPart { padding-left: 5px; width: 22px; } 
    .widget.hourField div.control input.minutePart { padding-right: 5px; } 
    .widget.hourField span.unit { font-size: 0.8em; margin-left: 8px; } 
    .widget.hourField.invalid div.control { border-color: var(--field-error-border); background: var(--field-error-background); } 
    .widget.hourField.columnsStyle > label { display: block; width: 100px; vertical-align: top; margin: 8px 0 0; } 
    .widget.hourField.columnsStyle > div {  display: block; flex: 1; vertical-align: top; margin: 0 12px; } 
    .widget.hourField.columnsStyle :last-child { margin-right: 0; } 
    .widget.hourField.columnsStyle { display: flex; flex-direction: row; justify-content: space-between; } 
    .widget.hourField.columnsStyle > div span.description { display: block; font-size: 0.7em; color: #888; margin-top: 6px; } 
`);

Widgets.Field.Hour = Class.create();
Widgets.Field.Hour.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            visible:		true,
            className:		null,
            style:			null,
            label: 			null,
            description:	null,
            allow:			null,
            onChange:		null,
            value:			null,
            frequency:		0.4,
            minChars:		1,
            minimum:		0,
            maximum:		null
        }, options || {});

        this._value = this.options.value;
        this._complete = this.options.value ? true : false;
        this._valid = this.options.value ? true : false;
                    
        if (typeof parent.container != 'undefined') parent = parent.container;
        this.container = new Element('div', { 'class': 'widget hourField' });
        parent.appendChild(this.container);
        
        if (this.options.style) {
            this.container.classList.add(this.options.style + 'Style');
        }

        if (this.options.label) {
            this.label = new Element('label').update(this.options.label);
            this.container.appendChild(this.label);
        }
        
        if (this.options.className) {
            this.container.classList.add(this.options.className);
        }


        var wrapper = new Element('div');
        this.container.appendChild(wrapper);
        
        this.control = new Element('div', { 'class': 'control' });
        wrapper.appendChild(this.control);
        
        this.hour = new Element('input', { 'class': 'hourPart', type: 'text', value: this._value != null ? Math.floor(this._value / 60) : '' });
        this.hour.setAttribute('inputmode', 'numeric');
        this.hour.observe('keypress', this.onKeyPress.bindAsEventListener(this));
        this.hour.observe('keydown', this.onKeyDown.bindAsEventListener(this));
        this.control.appendChild(this.hour);

        this.control.appendChild(new Element('span').update(':'));

        var minutes = this._value != null ? this._value - (Math.floor(this._value / 60) * 60) : 0;
        this.minute = new Element('input', { 'class': 'minutePart', type: 'text', value: this._value != null ? (minutes < 10 ? '0' + minutes : minutes) : '' });
        this.minute.setAttribute('inputmode', 'numeric');
        this.minute.observe('keypress', this.onKeyPress.bindAsEventListener(this));
        this.minute.observe('keydown', this.onKeyDown.bindAsEventListener(this));
        this.control.appendChild(this.minute);

        wrapper.appendChild(new Element('span', { 'class': 'unit' }).update('uur'));

        if (this.options.description) {
            this.description = new Element('span', { 'class': 'description' }).update(this.options.description);
            wrapper.appendChild(this.description);
        }

        if (!this.options.visible) {
            this.hide();
        }
    },

    destroy: function() {
        this.hour.stopObserving();
        this.minute.stopObserving();
        this.container.remove();
    },

    focus: function() {
        this.hour.focus();
    },

    blur: function() {
        this.hour.blur();
        this.minute.blur();
    },

    getPreviousField: function(field) {
        switch(field) {
            case this.hour:		return null;
            case this.minute:	return this.hour;
        }

        return null;
    },

    getNextField: function(field) {
        switch(field) {
            case this.hour:		return this.minute;
            case this.minute:	return null;			
        }
        
        return null;
    },

    onKeyDown: function(event) {
        if (event.keyCode == Event.KEY_BACKSPACE) {
            if (event.target == this.minute) {
                var hour = parseInt(this.hour.value);

                /* Delete last character */

                if (this.minute.selectionStart >= 1 && this.minute.selectionEnd == 2) {
                    var minute = parseInt(this.minute.value.substring(0, 1) + '0');

                    if ((hour * 60) + minute >= this.options.minimum) {
                        this.minute.value = String(minute).padStart(2, '0');
                        this.minute.selectionStart = 1;
                        this.minute.selectionEnd = 1;
                    }

                    event.stop();
                }

                /* Delete first character */

                else if (this.minute.selectionStart >= 0 && this.minute.selectionEnd == 1) {
                    var minute = parseInt(this.minute.value.substring(1, 2));

                    if ((hour * 60) + minute >= this.options.minimum) {
                        this.minute.value = String(minute).padStart(2, '0');
                        this.minute.selectionStart = 0;
                        this.minute.selectionEnd = 0;
                    }

                    event.stop();
                }

                /* Delete both characters */

                else if (this.minute.selectionStart == 0 && this.minute.selectionEnd == 2) {
                    var minute = 0;

                    if ((hour * 60) + minute >= this.options.minimum) {
                        this.minute.value = String(minute).padStart(2, '0');
                        this.minute.selectionStart = 0;
                        this.minute.selectionEnd = 0;
                    }

                    event.stop();
                }

                /* Delete while cursor is before first character */

                else if (this.minute.selectionStart == 0 && this.minute.selectionEnd == 0) {
                    var previous = this.getPreviousField(event.target);
                    previous.focus();
                    previous.selectionStart = previous.selectionEnd = previous.value.length;
                    event.stop();
                    return;
                }
            }
        }
        
        if (event.keyCode == Event.KEY_UP) {
            var hour = parseInt(this.hour.value);
            var minute = parseInt(this.minute.value);
            var value = (hour * 60) + minute;

            if (event.target == this.minute) {
                value = value + 5;
            }

            if (event.target == this.hour) {
                value = value + 60;
            }

            if (this.options.maximum) {
                if (value > this.options.maximum) {
                    event.stop();
                    return;
                }
            }

            var hour = Math.floor(value / 60);
            var minute = value - (hour * 60);

            this.hour.value = hour;
            this.minute.value = String(minute).padStart(2, '0');

            event.target.selectionStart = event.target.selectionEnd = 2;
            event.stop();
        }

        if (event.keyCode == Event.KEY_DOWN) {
            var hour = parseInt(this.hour.value);
            var minute = parseInt(this.minute.value);
            var value = (hour * 60) + minute;

            if (event.target == this.minute) {
                value = value - 5;
            }

            if (event.target == this.hour) {
                value = value - 60;
            }

            if (value >= this.options.minimum) {
                var hour = Math.floor(value / 60);
                var minute = value - (hour * 60);

                this.hour.value = hour;
                this.minute.value = String(minute).padStart(2, '0');

                event.target.selectionStart = event.target.selectionEnd = 2;
            }

            event.stop();
        }

        if (event.keyCode == Event.KEY_LEFT) {
            if (event.target == this.minute) {
                if (event.target.selectionStart == 0 && !event.targetSelectionEnd) {
                    event.stop();

                    var previous = this.getPreviousField(event.target);
                    previous.focus();
                    previous.selectionStart = previous.selectionEnd = previous.value.length;
                }				
            }
        }
        
        if (event.keyCode == Event.KEY_RIGHT) {
            if (event.target == this.hour) {
                if (event.target.selectionStart == event.target.value.length && !event.targetSelectionEnd) {
                    event.stop();

                    var next = this.getNextField(event.target);
                    next.focus();
                    next.selectionStart = next.selectionEnd = 0;
                }				
            }
        }

        if (this.observer) 
            clearTimeout(this.observer);
    
        this.observer = setTimeout(this.onObserverEvent.bind(this), this.options.frequency * 1000);
    },

    onKeyPress: function(event) {
        var character = String.fromCharCode(event.charCode);

        if (String('-/:').indexOf(character) >= 0) {
            event.stop();
            
            if (event.target == this.hour) {
                var next = this.getNextField(event.target);
                
                next.focus();
                next.selectionStart = 0;
                next.selectionEnd = next.value.length;
            }
            
            return;
        }

        if (String('0123456789').indexOf(character) < 0) {
            event.stop();
            return;
        }
        
        if (event.target == this.hour) {
            /* Check maximum and maximum */

            var	hour = parseInt(this.hour.value.substring(0, this.hour.selectionStart) + character + this.hour.value.substring(this.hour.selectionEnd), 10);
            var minute = parseInt(this.minute.value);
            
            if (this.options.maximum) {
                if ((hour * 60) + minute > this.options.maximum) {
                    event.stop();
                    return;
                }
            }

            if ((hour * 60) + minute < this.options.minimum) {
                event.stop();
                return;
            }

        
            /* Automatically switch over to minute field if we already have 3 digits in the hour field */

            if (this.hour.value.length == 3 && this.hour.selectionStart == this.hour.selectionEnd) {
                event.stop();
                
                this.minute.focus();
                this.minute.value = String.fromCharCode(event.charCode);
                return;
            }
        }
        
        if (event.target == this.minute) {
            var hour = parseInt(this.hour.value);

            var isZeroToSix = String('0123456').indexOf(character) != -1;
            var isZeroOrFive = String('05').indexOf(character) != -1;

            /* Cursor before first character */

            if (this.minute.selectionStart == 0) { 
                var second = this.minute.selectionEnd == 2 ? '0' : this.minute.value.substring(1, 2);
                var minute = parseInt(character + second, 10);
                
                if (!isZeroToSix || minute > 60) {
                    event.stop();
                    return;
                }

                if (this.options.maximum) {
                    if ((hour * 60) + minute > this.options.maximum) {
                        event.stop();
                        return;
                    }
                }

                if ((hour * 60) + minute < this.options.minimum) {
                    event.stop();
                    return;
                }

                this.minute.value = character + second;
                this.minute.selectionStart = 1;
                this.minute.selectionEnd = 1;
                event.stop();
            }

            /* Cursor between first and second character */

            else if (this.minute.selectionStart == 1) { 
                var minute = parseInt(this.minute.value.substring(0, 1) + character, 10);

                if (!isZeroOrFive || minute > 60) {
                    event.stop();
                    return;
                }

                if (this.options.maximum) {
                    if ((hour * 60) + minute > this.options.maximum) {
                        event.stop();
                        return;
                    }
                }

                if ((hour * 60) + minute < this.options.minimum) {
                    event.stop();
                    return;
                }

                this.minute.value = this.minute.value.substring(0, 1) + character;
                this.minute.selectionStart = 2;
                this.minute.selectionEnd = 2;
                event.stop();
            }

            /* Cursor after second character */

            else if (this.minute.selectionStart == 2) {
                event.stop();
            }
        }
        
        if (this.observer) 
            clearTimeout(this.observer);
    
        this.observer = setTimeout(this.onObserverEvent.bind(this), this.options.frequency * 1000);
    },

    onObserverEvent: function() {
        this._complete = true;

        var hour = parseInt(this.hour.value, 10);
        var minute = parseInt(this.minute.value, 10);
        var value = (hour * 60) + minute;

        this.validate();

        if (value != this._value) {
            this._value = value;

            if (this.options.onChange) {
                this.options.onChange(value);
            }
        }
    },

    validate: function() {
        var valid = true;

        var hour = parseInt(this.hour.value, 10);
        var minute = parseInt(this.minute.value, 10);
        var value = (hour * 60) + minute;

        if (value < this.options.minimum) valid = false;
        if (this.options.maximum) {
            if (value > this.options.maximum) value = false;
        }

        this.valid = valid;
    },

    get valid() { return this._valid; },
    set valid(value) {
        this._valid = value;

        if (this._valid || !this._complete) {
            this.container.classList.remove('invalid');
        } else {
            this.container.classList.add('invalid');
        }
    },

    get value() { return this._valid ? this._value : null; },
    set value(value) { 
        this._value = value;

        this.hour.value = Math.floor(value / 60);
        this.minute.value = String(value - (Math.floor(value / 60) * 60)).padStart(2, '0');
        
        this._complete = true;
        this.valid = true;
    },

    get maximum() { return this.options.maximum; },
    set maximum(value) {
        if (value != this.options.maximum) {
            this.options.maximum = value;
            this.validate();
        }
    },

    get minimum() { return this.options.minimum; },
    set minimum(value) {
        if (value != this.options.minimum) {
            this.options.minimum = value;
            this.validate();
        }
    },

    show: function() {
        this.container.show();
        this._visible = true;
    },

    hide: function() {
        this.container.hide();
        this._visible = false;
    },

    toggle: function() {
        this.container.toggle();
        this._visible = !this._visible;
    },

    get visible() { return this._visible; },
    set visible(value) { value ? this.show() : this.hide(); },
};	
