
Formatter.transactionColor = function(d) {
	if (d.status == 'created')   			return 'gray';
	if (d.status == 'pending')   			return 'gray';
	if (d.status == 'manualintervention') 	return 'red';
	if (d.status == 'error')     			return 'red';
	if (d.status == 'success')	 			return 'green';

	if (d.status == 'failed') {
		if ([ '', 'stopped', 'transaction_refused' ].includes(d.reason)) {
			return 'gray';
		}

		if ([ 'offline', 'error', 'merchant_invalid', 'pin_required', 'card_removed', 'card_failed' ].includes(d.reason)) {
			return 'red';
		}

		return 'orange';
	}

	return 'transparent';
}


Formatter.transactionStatus = function(d) {
	if (d.status == 'created')   			return 'Gestart';
	if (d.status == 'pending')   			return 'Nog bezig...';
	if (d.status == 'manualintervention') 	return 'Aandacht vereist!';
	if (d.status == 'error')     			return 'Fout';
	if (d.status == 'success')	 			return 'Geslaagd';

	if (d.status == 'failed') {
		if ([ '', 'stopped', 'transaction_refused' ].includes(d.reason)) {
			return 'Gestopt';
		}

		if ([ 'offline', 'error', 'merchant_invalid', 'pin_required', 'card_removed', 'card_failed' ].includes(d.reason)) {
			return 'Fout';
		}

		return 'Afgekeurd';
	}

	return '';
}




Formatter.transactionStatusWithReason = function(d) {

    function icon(id) {
		return `<img src="../../../assets/icons/Color/icons/${id}.svg" width=32 height=32>`;
	}

    let censored = false;

    if (d.environment && d.environment == 'live') {
        censored = true;
    }

    if (d.status == 'created')   			return 'De transactie is gestart';
	if (d.status == 'pending')   			return 'De transactie is nog bezig...';
	if (d.status == 'manualintervention') 	return `${icon('warning')} <span>De status van de transactie is onbekend. Controleer het netwerk en herstart uw terminal.</span>`;
	if (d.status == 'success')	 			return `${icon('ok')} <span>De transactie is <b>geslaagd</b></span>`;

	if (d.status == 'error') {
        if (d.reason == 'bad_credentials')          return `${icon('warning')} <span>SalonhubPay heeft geen toegang tot uw terminal op het CCV netwerk.</span>`;
        if (d.reason == 'bad_terminal')             return `${icon('warning')} <span>De terminal kan niet gevonden worden binnen SalonhubPay</span>`;
        if (d.reason == 'invalid_config')          	return `${icon('warning')} <span>De koppeling is niet goed geconfigureerd.</span>`;
        if (d.reason == 'connection_error')         return `${icon('warning')} <span>SalonhubPay kan geen contact maken met het CCV netwerk.</span>`;
        if (d.reason == 'rejected')                 return `${icon('warning')} <span>De betaalterminal was nog bezig met een andere transactie.</span>`;
        if (d.reason == 'unknown')                  return `${icon('warning')} <span>Er is een fout opgetreden tijdens het communiceren met het CCV netwerk.</span>`;

        return `${icon('warning')} <span>Er is een fout opgetreden bij het verwerken van de transactie</span>`;
    }

	if (d.status == 'failed') {
		if ([ '', 'stopped', 'transaction_refused' ].includes(d.reason)) {
			return `${icon('cancel')} <span>De transactie is <b>gestopt</b></span>`;
		}

		if ([ 'error', 'merchant_invalid' ].includes(d.reason)) {
			return `${icon('warning')} <span>Er is een fout opgetreden bij het verwerken van de transactie</span>`;
		}

		if (d.reason == 'offline') {
			return `${icon('wifi-disconnected')} <span>De terminal <b>stond uit</b>, of had <b>geen netwerkverbinding</b></span>`;
		}

		if (d.reason == 'card_declined') 			return `${icon('blocked')} <span>De transactie is <b>afgekeurd</b></span>`;
		if (d.reason == 'card_expired') 			return `${icon('cards-disabled')} <span>De kaart is <b>verlopen</b></span>`;
		if (d.reason == 'card_fraud') 				return censored ? `${icon('cards-disabled')} <span>De kaart is <b>geblokkeerd</b></span>` : `${icon('cards-disabled')} <span>De kaart is <b>geblokkeerd</b> i.v.m. fraude</span>`;
		if (d.reason == 'card_restricted') 			return `${icon('cards-disabled')} <span>De kaart heeft <b>beperkte mogelijkheden</b></span>`;
		if (d.reason == 'card_lost') 				return censored ? `${icon('cards-disabled')} <span>De kaart is <b>geblokkeerd</b></span>` : `${icon('cards-disabled')} <span>De kaart is gemeld als <b>verloren</b></span>`;
		if (d.reason == 'card_stolen') 				return censored ? `${icon('cards-disabled')} <span>De kaart is <b>geblokkeerd</b></span>` : `${icon('cards-disabled')} <span>De kaart is gemeld als <b>gestolen</b></span>`;
		if (d.reason == 'card_invalid') 			return `${icon('cards-disabled')} <span><b>Ongeldig</b> kaartnummer</span>`;
		if (d.reason == 'card_removed') 			return `${icon('cards-disabled')} <span>De kaart is tijdens de transactie verwijderd</span>`;
		if (d.reason == 'card_failed') 			    return `${icon('cards-disabled')} <span>De kaart is defect en kan niet gelezen worden</span>`;
		if (d.reason == 'pin_tries_exceeded') 		return `${icon('pinpad')} <span>Maximum aantal pogingen pincode bereikt</span>`;
		if (d.reason == 'pin_required') 			return `${icon('pinpad')} <span>Te lang gewacht met het invoeren van de pincode</span>`;
		if (d.reason == 'pin_incorrect') 			return `${icon('pinpad')} <span>De pincode is onjuist</span>`;
		if (d.reason == 'transaction_not_allowed') 	return `${icon('blocked')} <span>De transactie is afgekeurd</span>`;
		if (d.reason == 'not_sufficient_funds') 	return `${icon('blocked')} <span>Onvoldoende saldo</span>`;
		if (d.reason == 'exceeded_amount_limit') 	return `${icon('blocked')} <span>Maximaal opnamebedrag bereikt</span>`;
		if (d.reason == 'exceeded_frequency_limit') return `${icon('blocked')} <span>Maximaal aantal betalingen bereikt</span>`;

		return `${icon('blocked')} <span>De transactie is <b>afgekeurd</b></span>`;
	}

	return '';
}

Formatter.transactionStatusWithError = function(e) {

	function icon(id) {
		return `<img src="../../../assets/icons/Color/icons/${id}.svg" width=32 height=32>`;
	}

    let explaination = `${icon('warning')} <span>Er is een fout opgetreden.</span>`;

    switch(e) {
        case _PAYMENT_ERROR_NOTOK:							explaination = `${icon('blocked')} <span>Betaling afgebroken of niet goedgekeurd door de bank.</span>`; break;
        case _PAYMENT_ERROR_VALUTAWRONG:					explaination = `${icon('warning')} <span>Er is een fout opgetreden: valuta wordt niet ondersteund.</span>`; break;
        case _PAYMENT_ERROR_PRODUCTINFOWRONG:				explaination = `${icon('warning')} <span>Er is een fout opgetreden: product informatie is niet correct.</span>`; break;
        case _PAYMENT_ERROR_PRICELITRERATECHECKFAILED:		explaination = `${icon('warning')} <span>Er is een fout opgetreden: prijs / liter verhouding klopt niet.</span>`; break;
        case _PAYMENT_ERROR_TOTALNOTEQUAL:					explaination = `${icon('warning')} <span>r is een fout opgetreden: totaal is niet gelijk aan subtotalen.</span>`; break;
        case _PAYMENT_ERROR_SYNTAXERROR:					explaination = `${icon('warning')} <span>Er is een fout opgetreden: onbekend antwoord van betaalterminal.</span>`; break;
        case _PAYMENT_ERROR_AMOUNTNOTALLOWED:				explaination = `${icon('warning')} <span>Er is een fout opgetreden: bedrag moet hoger zijn dan 0.</span>`; break;
        case _PAYMENT_ERROR_AMOUNTTOOHIGH:					explaination = `${icon('warning')} <span>Er is een fout opgetreden: bedrag is te hoog.</span>`; break;
        case _PAYMENT_ERROR_INVALIDMESSAGEVERSIONNUMBER:	explaination = `${icon('warning')} <span>Er is een fout opgetreden: bericht versie niet geldig.</span>`; break;
        case _PAYMENT_ERROR_TRXSTILLBUSY:					explaination = `${icon('payment-terminal')} <span>De betaalterminal is momenteel bezig met een andere transactie.</span>`; break;
    }

    return explaination;
}



Formatter.transactionTreatmentResult = function(m) {
	m = parseInt(m, 10);

	switch(m) {		
		case 1802:	return 'Unexpected message';
		case 1803:	return 'Time-out expiration';
		case 1804:	return 'Rejection notification';
		case 1811:	return 'Technical problem';
		case 1822:	return 'Connection failure';
		case 1823:	return 'Invalid response';
		case 2620:	return 'Card-required advice';
		case 2621:	return 'Cancellation on PIN entry';
		case 2622:	return 'Time-out on PIN entry';
		case 2623:	return 'Card refusal';
		case 2624:	return 'Card rejection';
		case 2625:	return 'Corrupted response';
		case 2626:	return 'Data authentication failure';
		case 2627:	return 'Host refusal';
		case 2628:	return 'Magnetic stripe fall back not allowed';
		case 2629:	return 'Cancellation';
		case 2630:	return 'Denial after referral';
		case 2707:	return 'PIN try exhaustion';
		case 3313:	return 'MAC verification failure';
		case 9998:	return 'Session limit with acquirer';
		case 9999:	return 'Parameters not up-to-date';
	}	

	return 'Onbekende melding';
}

Formatter.transactionHostInformation = function(m) {
	m = parseInt(m, 10);

	switch(m) {		
		case 100:	return 'Do Not Honor';
		case 101:	return 'Expired Card';
		case 102:	return 'Suspect Fraud';
		case 103:	return 'Contact Acquirer';
		case 104:	return 'Restricted Card';
		case 105:	return 'Call Acquirer Security';
		case 106:	return 'PIN Tries Exceeded';
		case 107:	return 'Refer to Issuer';
		case 108:	return 'Refer to Issuer\'s Specifications';
		case 109:	return 'Invalid Merchant';
		case 110:	return 'Invalid Amount';
		case 111:	return 'Invalid Card Number';
		case 112:	return 'PIN Data Required';
		case 113:	return 'Unacceptable Fee';
		case 114:	return 'No Account Type Requested';
		case 115:	return 'Function Not Supported';
		case 116:	return 'Insufficient Funds';
		case 117:	return 'Incorrect PIN';
		case 118:	return 'No Card Record';
		case 119:	return 'Transaction Not Permitted to Card';
		case 120:	return 'Transaction Not Permitted to Terminal';
		case 121:	return 'Exceeded Withdrawal Limit';
		case 122:	return 'Security Violation';
		case 123:	return 'Exceeded Frequency Limit';
		case 124:	return 'Violation of Law';
		case 125:	return 'Card Not Effective';
		case 126:	return 'Invalid PIN Block';
		case 127:	return 'PIN Length Error';
		case 128:	return 'PIN Key Sync Error';
		case 129:	return 'Suspect Counterfeit Card';
		case 200:	return 'Do Not Honor';
		case 201:	return 'Expired Card';
		case 202:	return 'Suspect Fraud';
		case 203:	return 'Contact Acquirer';
		case 204:	return 'Restricted Card';
		case 205:	return 'Call Acquirer Security';
		case 206:	return 'PIN Tries Exceeded';
		case 207:	return 'Special';
		case 208:	return 'Lost Card';
		case 209:	return 'Stolen Card';
		case 210:	return 'Suspect Card';
		case 900:	return 'Acknowledged';
		case 901:	return 'Acknowledged Responsibility';
		case 902:	return 'Invalid Transaction';
		case 903:	return 'Reenter Transaction';
		case 904:	return 'Format Error';
		case 905:	return 'Acquirer Not Supported';
		case 906:	return 'Cutover in Progress';
		case 907:	return 'Switch Inoperative';
		case 908:	return 'Destination Not Found';
		case 909:	return 'System Malfunction';
		case 910:	return 'Issuer Signed Off';
		case 911:	return 'Issuer Timed Out';
		case 912:	return 'Issuer Unavailable';
		case 9550:	return 'PIN Data required';
		case 9552:	return 'Closed account';
		case 9553:	return 'Verification data failed';
		case 9554:	return 'Transaction not permitted to cardholder';
		case 9601:	return 'Restricted card, issued in sanctioned country';
		case 9602:	return 'Restricted card, restricted card product';
	}

	return 'Onbekende reden';
}
