
CSS.insert(`
    .widget.spinner.numeric { -webkit-user-select: none; }
    .widget.spinner.numeric label { font-size: 0.8em; display: inline-block; }
    .widget.spinner.numeric span { font-size: 0.8em; display: inline-block; }
    .widget.spinner.numeric ul { display: inline-flex; }
    .widget.spinner.numeric ul li { display: inline-block; vertical-align: top; height: 24px; line-height: 24px; cursor: pointer; font-family: SalonIcon; font-size: 11px; text-align: left; padding: 0 8px; border-top: 1px solid var(--button-border); border-left: 1px solid var(--button-border); border-bottom: 1px solid var(--button-border); background: var(--button-background); color: var(--spinner-text); -webkit-font-smoothing: antialiased; }
    .widget.spinner.numeric ul li:focus { z-index: 1; position: relative; }
    [data-input-mode=mouse] .widget.spinner.numeric ul li:focus { outline: none}
    .widget.spinner.numeric ul li.enabled:hover { background: var(--button-hover-background); }
    .widget.spinner.numeric ul li.enabled:active { background: var(--button-active-background); }
    .widget.spinner.numeric ul li.disabled { color: var(--spinner-disabled-text); }
    .widget.spinner.numeric ul li:first-child { border-bottom-left-radius: 4px; border-top-left-radius: 4px; }
    .widget.spinner.numeric ul li:last-child { border-right: 1px solid var(--button-border); border-bottom-right-radius: 4px; border-top-right-radius: 4px; }
    .widget.spinner.numeric.columnsStyle label { width: 100px; }
    .widget.spinner.numeric.columnsStyle ul { margin: 2px 12px; }
`);

Widgets.NumericSpinner = Class.create();
Widgets.NumericSpinner.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            value:			0,
            maximum:		100,
            minimum:		0,
            step:			1,
            prefix:			'',
            postfix:		'',
            preview:        true,
            onChange:		null
        }, options || {});

        this._value = parseInt(this.options.value, 10);

        if (typeof parent.container != 'undefined') parent = parent.container;
        this.container = new Element('div', { 'class': 'widget numeric spinner' });
        parent.appendChild(this.container);

        if (this.options.style) {
            this.container.classList.add(this.options.style + 'Style');
        }

        if (this.options.label) {
            this.label = new Element('label').update(this.options.label);
            this.container.appendChild(this.label);
        }


        this.buttons = new Element('ul');
        this.container.appendChild(this.buttons);

        this.downSpinner = new Element('li', { 'class': 'down' }).update('-');
        this.downSpinner.observe('click', this.onDown.bindAsEventListener(this));
        this.downSpinner.observe('keypress', this.onDownKeyPress.bindAsEventListener(this));
        this.buttons.appendChild(this.downSpinner);

        this.upSpinner = new Element('li', { 'class': 'up' }).update('+');
        this.upSpinner.observe('click', this.onUp.bindAsEventListener(this));
        this.upSpinner.observe('keypress', this.onUpKeyPress.bindAsEventListener(this));
        this.buttons.appendChild(this.upSpinner);

        if (this.options.preview) {
            this.display = new Element('span').update(this.options.prefix + this._value + this.options.postfix);
            this.container.appendChild(this.display);
        }

        this.check();
    },

    check: function() {
        if (this._value <= this.options.minimum) {
            this.downSpinner.classList.add('disabled');
            this.downSpinner.tabIndex = -1;
        } else {
            this.downSpinner.classList.remove('disabled');
            this.downSpinner.tabIndex = 0;
        }

        if (this._value >= this.options.maximum) {
            this.upSpinner.classList.add('disabled');
            this.upSpinner.tabIndex = -1;
        } else {
            this.upSpinner.classList.remove('disabled');
            this.upSpinner.tabIndex = 0;
        }
    },

    onDown: function() {
        this._value = this._value - this.options.step;
        this._value = Math.max(this._value, this.options.minimum);

        if (this.options.preview) {
            this.display.update(this.options.prefix + this._value + this.options.postfix);
        }

        this.check();

        if (this.options.onChange) {
            this.options.onChange(this._value)
        }
    },

    onDownKeyPress: function(event) {
        if (event.key == 'Enter') {
            this.onDown();
        }
    },

    onUp: function() {
        this._value = this._value + this.options.step;
        this._value = Math.min(this._value, this.options.maximum);

        if (this.options.preview) {
            this.display.update(this.options.prefix + this._value + this.options.postfix);
        }

        this.check();

        if (this.options.onChange) {
            this.options.onChange(this._value)
        }
    },

    onUpKeyPress: function(event) {
        if (event.key == 'Enter') {
            this.onUp();
        }
    },

    get value() { return this._value; },
    set value(value) {
        this._value = parseInt(value, 10);
        this._value = Math.max(this._value, this.options.minimum);
        this._value = Math.min(this._value, this.options.maximum);

        if (this.options.preview) {
            this.display.update(this.options.prefix + this._value + this.options.postfix);
        }
        
        this.check();
    }
};
