CSS.insert(`
	.page { outline: none }
	.page.hidden { transform: translate(-4000px,0); opacity: 0; pointer-events: none; } 

	body[data-viewport=expand] .page.hidden {
		transform: none;
		height: 0px !important;
		min-height: 0px !important;
		overflow: hidden;
	}
`);


Page = (function() {
	var Page = {
		_initialized: {},
	};

	Page.Base = Class.create({
		isPage:		true,

		initialize: function(parent, options) {
			this.internal = {
				defaults:	{
					visible:	true
				}
			};

			this.options = {};
			this.options = Object.assign(this.options, this.internal.defaults);
			this.options = Object.assign(this.options, this.defaults || {});
			this.options = Object.assign(this.options, options || {});

			if (typeof Page._initialized[this.name] == 'undefined') this.bootstrap();

			this.internal.container = new Element('div', { 'class': 'page ' + this.name });
			this.internal.container.tabIndex = -1;
			parent.appendChild(this.internal.container);

			if (this.options.className) this.internal.container.classList.add(...this.options.className.split(' '));

			this.defineProperty("classList", {
				get:		function() { return this.internal.container.classList; },
			});

			this.defineProperty("dataset", {
				get:		function() { return this.internal.container.dataset; },
			});

			this.defineProperty("visible", {
				get:		function() { return this.internal.visible; },
				set:		function(value) { value ? this.show() : this.hide(); }
            });

			this.initPage.apply(this, [...arguments].slice(2));

			if (!this.options.visible) this.hide();
		},

		bootstrap: function() {
			if (this.css) {
				if (typeof this.css == 'string')
					CSS.insert(this.css);
				else
					CSS.load(this.css);
			}

			Page._initialized[this.name] = true;
		},

		initPage: function(options) {
		},

		defineProperty: function(prop, descriptor) {
			Object.defineProperty(this, prop, descriptor);
		},

		setStyle: function(s) {
			this.internal.container.setStyle(s);
		},

		appendChild: function(element) {
			this.internal.container.insert(element);
		},

		insert: function(element) {
			this.internal.container.insert(element);
		},

		show: function() {
			this.internal.visible = true;
			this.classList.remove('hidden');

			window.setTimeout(() => {
				this.internal.container.focus();
			}, 100);
		},

		hide: function() {
			this.internal.visible = false;
			this.classList.add('hidden');
		}
	});



	Page.Settings = Class.create(Page.Base, {
		name:		'settings',

		css: `
			.page.settings { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #fff; color: #000; }
			.page.settings > .panels { display: flex; flex-direction: row; width: 100%; min-height: 100%; background: linear-gradient(to bottom, #fff 0px, rgba(255,255,255,0) 30px), linear-gradient(to right, #fff 220px, #eee 221px, #fff 235px);  }
			.page.settings > .panels > .panelNavigation { width: 220px; min-width: 220px; padding: 30px 0 0 0; }
			.page.settings > .panels > .panelNavigation > * { margin-left: 30px; }
			.page.settings > .panels > .panelNavigation .widget.tabbar.verticalType { margin-bottom: 24px; }
			.page.settings > .panels > .panelContent { flex: 1; padding: 30px 30px 30px 34px; position: relative; }
			

			/* Expanded viewport */

			body[data-viewport=expand] .page.settings {
				position: static;
				width: 1000px;
				min-height: 100vh;
			}

			body[data-viewport=expand] .page.settings > .panels { min-height: 100vh; }

			body[data-viewport=expand] .page.settings > .panels > .panelContent {
				width: 780px;
				overflow-x: hidden;
			}


			/* Small viewport */

			.page.settings > .panels > .panelToggle {
				display: none; 
			}

			@media (max-width: 799px) {

				.page.settings > .panels {
					background: #fff;
				}

				.page.settings > .panels > .panelContent {
					padding-top: 70px;
				}

				.page.settings > .panels > .panelNavigation {
					background: #fff;
					padding-top: 30px;
					z-index: 2;
					margin-left: -220px;

					box-shadow: 0 0 20px #fff;

					transition: transform 0.4s, box-shadow 0.4s;
				}

				.page.settings.sidebarVisible > .panels > .panelNavigation {
					box-shadow: 0 0 20px #ccc;
					transform: translateX(220px);
				}

				.page.settings > .panels > .panelToggle {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 40px;
				}

				.page.settings > .panels > .panelToggle .widget.button { 
					position: absolute;
					top: 6px;
					left: 34px;
					display: block;
					z-index: 1;
				}
				.page.settings > .panels > .panelToggle .widget.button button {
					width: auto;
					min-width: auto;
					padding-right: 20px;
				} 
				.page.settings > .panels > .panelToggle .widget.button button[data-icon]::before {
					font-size: 22px;
				}
			}
		`,

		initPage: function() {
			this.classList.add('scrollWrapper');
			this.internal.container.tabIndex = -1;

			var panels = new Element('div', { 'class' : 'panels scrollContainer' });
			this.appendChild(panels);

			this.navigation = new Element('div', { 'class' : 'panelNavigation' });
			panels.appendChild(this.navigation);

			this.content = new Element('div', { 'class': 'panelContent' });
			panels.appendChild(this.content);

			this.toggle = new Element('div', { 'class': 'panelToggle' });
			panels.appendChild(this.toggle);

			new Widgets.Button(this.toggle, {
				style:      'none',
				caret:		true,
				icon:		{ character: '\uE958' },
				onClick:	(e) => {
					e.stopPropagation();
					this.classList.add('sidebarVisible');
					Widgets.registerSheet(() => this.classList.remove('sidebarVisible'), [ this.navigation ]);
				}
			})
		}
	});


	Page.Preview = Class.create(Page.Base, {
		name:		'preview',

		defaults: {
			size: 'medium'
		},

		css: `
			.page.preview { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #fff; color: #000; }
			.page.preview .scrollWrapper { position: absolute; height: 100%; right: 0; overflow-x: hidden; }
			.page.preview .panelContent { position: relative; padding: 30px; box-sizing: border-box; }
			.page.preview .panelPreview { position: absolute; left: 0px; top: 30px; bottom: 0; padding-left: 30px; overflow: hidden; box-sizing: border-box; }

			.page.preview[data-size=medium] > .scrollWrapper { left: 35%; left: max(350px, min(900px, 35%)); }
			.page.preview[data-size=medium] > .panelPreview { width: 35%; width: max(350px, min(900px, 35%)); }

			@media (max-width: 700px) { 
				.page.preview[data-size=medium] > .scrollWrapper { left: 0; }
				.page.preview[data-size=medium] > .panelPreview { display: none; }
			}

			.page.preview[data-size=small] > .scrollWrapper { left: 50%; left: max(300px, min(500px, 50%)); }
			.page.preview[data-size=small] > .panelPreview { width: 50%; width: max(300px, min(500px, 50%)); }

			@media (max-width: 600px) { 
				.page.preview[data-size=small] > .scrollWrapper { left: 0; }
				.page.preview[data-size=small] > .panelPreview { display: none; }
			}
		`,

		initPage: function() {
			this.internal.container.tabIndex = -1;

			if (this.options.size) {
				this.dataset.size = this.options.size;
			}

			var wrapper = new Element('div', { 'class': 'scrollWrapper' });
			this.internal.container.appendChild(wrapper);

			var panels = new Element('div', { 'class' : 'panels scrollContainer' });
			wrapper.appendChild(panels);

			this.content = new Element('div', { 'class': 'panelContent' });
			panels.appendChild(this.content);

			this.preview = new Element('div', { 'class': 'panelPreview' });
			this.internal.container.appendChild(this.preview);
		}
	});



	Page.DoubleSidebar = Class.create(Page.Base, {
		name:		'doubleSidebar',

		css: `
			.page.doubleSidebar { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #fff; color: #000; }
			.page.doubleSidebar > .panels { display: flex; flex-direction: row;; width: 100%; min-height: 100%; background: linear-gradient(to bottom, #fff 0px, rgba(255,255,255,0) 30px), linear-gradient(to right, #fff 220px, #eee 221px, #fff 235px, #fff 440px, #eee 441px, #fff 455px);  }
			.page.doubleSidebar > .panels > .panelPrimaryNavigation { width: 220px;  padding: 30px 0 0 0; }
			.page.doubleSidebar > .panels > .panelPrimaryNavigation > * { margin-left: 30px; }
			.page.doubleSidebar > .panels > .panelSecondaryNavigation { width: 220px;  padding: 30px 0 0 0; }
			.page.doubleSidebar > .panels > .panelSecondaryNavigation > * { margin-left: 30px; }
			.page.doubleSidebar > .panels > .panelContent { flex: 1; padding: 30px 30px 30px 34px; }


			/* Expanded viewport */

			body[data-viewport=expand] .page.doubleSidebar {
				position: static;
				width: 1000px;
				min-height: 100vh;
				overflow: visible;
			}

			body[data-viewport=expand] .page.doubleSidebar > .panels {
				min-height: 100vh;
			}
		`,

		initPage: function() {
			this.classList.add('scrollWrapper');
			this.internal.container.tabIndex = -1;

			var panels = new Element('div', { 'class' : 'panels scrollContainer' });
			this.appendChild(panels);

			this.primary = new Element('div', { 'class' : 'panelPrimaryNavigation' });
			panels.appendChild(this.primary);

			this.secondary = new Element('div', { 'class' : 'panelSecondaryNavigation' });
			panels.appendChild(this.secondary);

			this.content = new Element('div', { 'class': 'panelContent' });
			panels.appendChild(this.content);
		}
	});



	Page.Tabs = Class.create(Page.Base, {
		name:		'tabs',

		css: `
			.page.tabs { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #fff; }
			.page.tabs > .panels > .panelNavigation { position: absolute; top: 0; left: 0; right: 0; height: 40px; padding: 10px 30px 0 30px; overflow:hidden;  }
			.page.tabs > .panels > .panelContent { position: absolute; top: 52px; left: 0; right: 0; bottom: 0; /* padding: 0 30px; */ overflow: hidden; }
			

			/* Expanded viewport */

			body[data-viewport=expand] .page.tabs {
				position: static;
				width: 1000px;
				min-height: 100vh;
			}

			body[data-viewport=expand] .page.tabs > .panels { min-height: 100vh; }

			body[data-viewport=expand] .page.tabs > .panels > .panelNavigation {
				position: relative;
				top: auto; left: auto; right: auto; bottom: auto;
			}
			body[data-viewport=expand] .page.tabs > .panels > .panelContent {
				position: relative;
				top: auto; left: auto; right: auto; bottom: auto;
				overflow-y: visible;
				overflow-x: hidden;

			}
		`,

		initPage: function() {
			var panels = new Element('div', { 'class' : 'panels' });
			this.appendChild(panels);

			this.navigation = new Element('div', { 'class' : 'panelNavigation' });
			panels.appendChild(this.navigation);

			this.content = new Element('div', { 'class' : 'panelContent' });
			panels.appendChild(this.content);
		}
	});



	Page.List = Class.create(Page.Base, {
		name:		'list',

		css: `
			.page.list { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #fff; }
			.page.list > .panels > .panelToolbar { position: absolute; top: 0; left: 0; right: 0; height: 40px; padding: 12px 0 0 0; overflow:hidden;  }
			.page.list > .panels > .panelContent { position: absolute; top: 52px; left: 0; right: 0; bottom: 0; padding: 30px 30px 0; background: linear-gradient(to bottom, #eee 0px, #fff 10px); color: #000; }


			/* Expanded viewport */

			body[data-viewport=expand] .page.list {
				position: static;
				width: 1000px;
				min-height: 100vh;
			}

			body[data-viewport=expand] .page.list > .panels { min-height: 100vh; }
			body[data-viewport=expand] .page.list > .panels > .panelToolbar {
				position: static;
			}
			body[data-viewport=expand] .page.list > .panels > .panelContent {
				position: static;
			}
		`,

		initPage: function() {
			var panels = new Element('div', { 'class' : 'panels' });
			this.appendChild(panels);

			this.toolbar = new Element('div', { 'class' : 'panelToolbar' });
			panels.appendChild(this.toolbar);

			var wrapper = new Element('div', { 'class' : 'panelContent scrollWrapper' });
			wrapper.tabIndex = -1;
			panels.appendChild(wrapper);

			this.content = new Element('div', { 'class' : 'scrollContent' });
			wrapper.appendChild(this.content);
		}
	});



	Page.Calendar = Class.create(Page.Base, {
		name:		'calendar',

		css: `
			.page.calendar { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #fff; }
			.page.calendar > .panels > .panelToolbar { position: absolute; top: 0; left: 0; right: 0; height: 40px; padding: 12px 0 0 0; }
			.page.calendar > .panels > .panelHeader  { position: absolute; top: 52px; left: 0; right: 0; height: 52px; padding: 0; overflow: hidden; background: linear-gradient(to bottom, #eee 0px, #fff 4px); color: #000; }
			.page.calendar > .panels > .panelContent { position: absolute; top: 104px; left: 0; right: 0; bottom: 0; padding: 0; background: linear-gradient(to bottom, #eee 0px, #f6f6f6 10px); color: #000; }


			/* Expanded viewport */

			body[data-viewport=expand] .page.calendar {
				position: static;
				width: 100vw;
				min-height: 100vh;
			}

			body[data-viewport=expand] .page.calendar > .panels { min-height: 100vh; }

			body[data-viewport=expand] .page.calendar > .panels > .panelToolbar {
				position: relative;
				top: auto;
				left: auto;
				right: auto;
			}
			body[data-viewport=expand] .page.calendar > .panels > .panelHeader {
				position: relative;
				top: auto;
				left: auto;
				right: auto;
			}
			body[data-viewport=expand] .page.calendar > .panels > .panelContent {
				position: relative;
				top: auto;
				left: auto;
				right: auto;

				overflow: hidden !important;
			}
		`,

		initPage: function() {
			var panels = new Element('div', { 'class' : 'panels' });
			this.appendChild(panels);

			this.toolbar = new Element('div', { 'class' : 'panelToolbar' });
			panels.appendChild(this.toolbar);

			this.header = new Element('div', { 'class' : 'panelHeader' });
			panels.appendChild(this.header);

			this.wrapper = new Element('div', { 'class' : 'panelContent scrollWrapper' });
			this.wrapper.tabIndex = -1;
			panels.appendChild(this.wrapper);

			this.content = new Element('div', { 'class' : 'scrollContent' });
			this.wrapper.appendChild(this.content);

			document.observe('calendar:scroll', this.onScroll.bind(this));
		},

		onScroll: function(e) {
			var scroll = e.memo;
			if (scroll.height) {
				var height = scroll.height + this.content.positionedOffset()[1];

				if (scroll.effect) {
					if (height != this.wrapper.scrollTop) new Fx.scrollToHeight(height, this.wrapper);
				} else {
					this.wrapper.scrollTop = height;
				}
			}
		}
	});



	Page.Receipt = Class.create(Page.Base, {
		name:		'receipt',

		css: `
			.page.receipt { position: absolute; top: 49px; bottom: 0; left: 0; right: 0; overflow: hidden; overflow-y: scroll; }
			body[data-scroll=custom] .page.receipt { border-right: 8px solid rgba(0,0,0,0); }
			.page.receipt { background: #fff; background: linear-gradient(to right, #fff 81px, #ddd 81px, #f6f6f6 82px, #fcfcfc 86px, #fcfcfc 538px, #f6f6f6 542px, #ddd 543px, #fff 543px, #fff 544px); color: #000; }
			.page.receipt > .header { position: fixed; top: 50px; z-index: 99; width: 100%; height: 51px; padding: 10px 30px 0px 30px; border-bottom: 1px solid var(--tab-separator); box-sizing: border-box; }
			.page.receipt > .header [data-name="toolbar"] { display: flex; flex-wrap: nowrap; }
			.page.receipt > .panels { display: flex; flex-direction: row; width: 100%; }
			.page.receipt > .panels > * { outline: 1px solid rgba(0,255,255,0); }
			.page.receipt > .panels > .panelNavigation { width: 82px; min-width: 82px; padding: 30px 0 0 0; }
			.page.receipt > .panels > .panelNavigation > * { margin-left: 30px; }
			.page.receipt > .panels > .panelReceipt { width: 460px; min-width: 460px; padding: 30px 0 0 0; }
			.page.receipt > .panels > .panelContent { flex: 1; padding: 30px 30px 30px 30px; }
		`,

		initPage: function() {

			/* Header */
			
			let header = new Element('div', { 'class' : 'header' });
			this.appendChild(header);

			let layout = new Layout.Flex(header, {
				orientation:	'horizontal',
				pack:			'justify',
				items: 			[ { name: 'switcher' },  { flexible: true } , { name: 'toolbar' } ]
			});
	
			this.switcher = layout.items.switcher;
			this.toolbar = layout.items.toolbar;

			/* Panels */

			let panels = new Element('div', { 'class' : 'panels' });
			this.appendChild(panels);

			this.navigation = new Element('div', { 'class': 'panelNavigation' });
			panels.appendChild(this.navigation);

			this.receipt = new Element('div', { 'class': 'panelReceipt' });
			panels.appendChild(this.receipt);

			this.content = new Element('div', { 'class': 'panelContent' });
			panels.appendChild(this.content);
		}
	});


	Page.Login = Class.create(Page.Base, {
		name:		'login',

		css: `
			.page.login { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #fff; }
			body.hasHeader .page.login { top: 36px; }
			body.hasFooter .page.login { bottom: 49px; }
			.page.login > .panels > .panelContent { position: absolute; top: 0; left: 0; right: 0; bottom: 0; padding: 0; background: radial-gradient(circle, #f6f6f6, #ccc); }
		`,

		initPage: function() {
			var panels = new Element('div', { 'class' : 'panels' });
			this.appendChild(panels);

			this.content = new Element('div', { 'class' : 'panelContent' });
			panels.appendChild(this.content);
		}
	});


	Page.Generic = Class.create(Page.Base, {
		name:		'generic',

		css: `
			.page.generic { position: absolute; top: 0; bottom: 0; left: 0; right: 0; overflow: hidden; overflow-y: scroll; outline: none; }
			body[data-scroll=custom] .page.generic { border-right: 8px solid rgba(0,0,0,0); }
			.page.generic > .panels { display: flex; flex-direction: row;; width: 100%; }
			.page.generic > .panels > * { outline: 1px solid rgba(0,255,255,0); }
			.page.generic > .panels > .panelContent { flex: 1; padding: 30px 30px 30px 30px; position: relative; }


			/* Expanded viewport */

			body[data-viewport=expand] .page.generic {
				position: static;
				width: 1000px;
				min-height: 100vh;
				overflow: visible;
			}
		`,

		initPage: function() {
			var panels = new Element('div', { 'class' : 'panels' });
			this.appendChild(panels);

			this.content = new Element('div', { 'class': 'panelContent' });
			panels.appendChild(this.content);
		}
	});

	return Page;
})();
