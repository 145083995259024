
CSS.insert(`
    .widget.checkbox { line-height: 24px; cursor: pointer; -webkit-user-select: none; display: flex; }
    .widget.checkbox.floating { display: inline-flex; width: 140px; }
    .widget.checkbox[data-style=floating] { display: inline-flex; width: 140px; }
    .widget.checkbox[data-style=inline] { display: inline-flex; }
    .widget.checkbox[data-style=columns] { padding-left: 112px; }
    .widget.checkbox[data-align] { width: fit-content; }
    .widget.checkbox[data-align=left] { margin: 0 auto 0 0; }
    .widget.checkbox[data-align=center] { margin: 0 auto; }
    .widget.checkbox[data-align=right] { margin: 0 0 0 auto; }
    .widget.checkbox > button { display: inline-block; width: 24px; height: 24px; border: 1px solid #d0d0d0; border-radius: 4px; background: linear-gradient(to bottom, rgb(230,230,230) 0%, rgb(255,255,255) 10%); color: #000; vertical-align: middle; }
    .widget.checkbox > button:disabled { opacity: 0.5; }
    [data-input-mode=mouse] .widget.checkbox > button:focus { outline: none }
    .widget.checkbox > button::after { visibility: hidden; content: '✔'; display: inline-block; line-height: 22px; width: 22px; text-align: center; font-family: SalonWidgets; font-size: 20px; font-weight: normal; color: #333; }
    .widget.checkbox > button[aria-checked=true]:after { visibility: visible; }
    .widget.checkbox > button + span { display: inline-block; font-weight: var(--font-weight-bold); font-size: 0.75em; margin-left: 8px; }
    .widget.checkbox > button:disabled + span { opacity: 0.5; }
    .widget.checkbox[data-type=radio] > button:after { content: '⬤'; line-height: 23px; }
`);

Widgets.Checkbox = Class.create();
Widgets.Checkbox.prototype = {
    initialize: function(parent, value, options) {
        this.options = Object.assign({
            onChange: 		null,
            onChanged: 		null,
            description: 	'',
            floating:		false,
            style:			null,
            align:          null,
            enabled:		true,
            className:      null,
            type:			'checkbox',
        }, options || {});

        this._value = !!value;

        if (typeof parent.container != 'undefined') parent = parent.container;
        this.container = new Element('div', { 'class': 'widget checkbox' });
        parent.appendChild(this.container);

        if (this.options.style) {
            this.container.dataset.style = this.options.style;
        }

        if (this.options.align) {
            this.container.dataset.align = this.options.align;
        }
        
        if (this.options.type) {
            this.container.dataset.type = this.options.type;
        }

        if (this.options.floating) {
            this.container.classList.add('floating');
        }

        if (this.options.className) {
            this.container.classList.add(this.options.className);
        }

        this.checkbox = new Element('button');
        this.checkbox.role = 'checkbox';
        this.checkbox.observe('click', this.onClick.bind(this));
        this.container.appendChild(this.checkbox);

        this.label = new Element('span').update(this.options.description);
        this.label.observe('click', this.onClick.bind(this));
        this.container.appendChild(this.label);

        this.checkbox.setAttribute('aria-checked', this._value ? 'true' : 'false');
        this.checkbox.disabled = ! this.options.enabled;
    },

    destroy: function() {
        Event.stopObserving(this.checkbox, 'click');
        Event.stopObserving(this.label, 'click');
		this.container.remove();
    },

    onClick: function(e) {
        Event.stop(e);

        if (this.options.enabled) {
            if (this.options.onChange) {
                var result = this.options.onChange(!this.value, this);

                if (typeof result == 'undefined' || result) {
                    this.toggle();
                }

                return;
            }

            this.toggle();
        }
    },

    getValue: function() {
        return this._value;
    },

    update: function() {
        this.checkbox.setAttribute('aria-checked', this._value ? 'true' : 'false');
    },

    clear: function() {
        if (this._value) {
            this._value = false;

            this.update();
            if (this.options.onChanged) this.options.onChanged(this._value);
        }
    },

    toggle: function(e) {
        this._value = !this._value;

        this.update();
        if (this.options.onChanged) this.options.onChanged(this._value);
    },

    get value() { return this._value; },
    set value(value) {
        this._value = value;
        this.update();
    },

    enable: function() {
        this.options.enabled = true;
        this.checkbox.disabled = false;
    },

    disable: function() {
        this.options.enabled = false;
        this.checkbox.disabled = true;
    },

    get enabled() { return this.options.enabled; },
    set enabled(value) { value ? this.enable() : this.disable(); },
    get disabled() { return !this.options.enabled; },
    set disabled(value) { !value ? this.enable() : this.disable(); }
};

