
CSS.insert(`
    .widget.context { z-index: 9000; position: absolute; line-height: 24px; margin; 0; padding: 0; font-size: 0.8em; font-weight: var(--font-weight-bold); text-transform: var(--font-transform); text-align: left; color: var(--menu-text); transform: translate3d(0px,0px,0px); box-shadow: 0 0 6px rgba(0,0,0,0.15); filter: drop-shadow(0px 0px 8px rgba(0,0,0,0.25)); border-radius: 6px; } 
    .widget.context > div div.tip { position: absolute; top: -10px; width: 0px; height: 0px; border: 10px solid rgba(0,0,0,0); border-bottom: 10px solid #fff; border-top: none; } 
    .widget.context.alignLeft > div div.tip { left: 10px; } 
    .widget.context.alignRight > div div.tip { right: 10px; } 
    .widget.context.alignCenter > div div.tip { left: 50%; margin-left: -10px; } 
    .widget.context.alignBottom > div div.tip { top: auto; bottom: -10px; border: 10px solid rgba(0,0,0,0); border-top: 10px solid #fff; border-bottom: none;  } 
    .widget.context > div ul { z-index: 9003; cursor: pointer; border-radius: 6px; list-style-type: none; padding: 4px 0; background: var(--menu-background); background-clip: padding; outline: none; } 
    .widget.context > div ul li { cursor: pointer; white-space: nowrap; margin: 0; padding: 0 10px; display: flex; } 
    .widget.context > div ul li.nested { padding: 0 10px 0 20px; } 
    .widget.context > div ul li.label { font-size: 0.9em; font-weight: normal; padding-top: 10px; } 
    .widget.context > div ul li.label:hover { background: none; color: #000; text-shadow: none; } 
    .widget.context > div ul li.separator { border-top: 1px solid #ddd; margin: 5px 0; } 
    .widget.context > div ul li.separator + li.separator { display: none; } 
    .widget.context > div ul li.separator:last-child { display: none; } 
    .widget.context > div ul li.separator:first-child { display: none; } 
    .widget.context > div ul li.disabled { color: #aaa; } 
    [data-input-mode=keyboard] .widget.context > div ul li:focus { color: var(--menu-selected-text); border-color: var(--menu-selected-border); background: var(--menu-selected-background); outline: none; }
    [data-input-mode=keyboard] .widget.context > div ul li[data-color=green]:focus { border-color: var(--colors-green); background: var(--colors-green); }
    [data-input-mode=keyboard] .widget.context > div ul li[data-color=red]:focus { border-color: var(--colors-red); background: var(--colors-red); }
    [data-input-mode=keyboard] .widget.context > div ul li[data-color=orange]:focus { border-color: var(--colors-orange); background: var(--colors-orange); }
    [data-input-mode=mouse] .widget.context > div ul li:focus { outline: none; }
    [data-input-mode=mouse] .widget.context > div ul li.enabled:hover { color: var(--menu-selected-text); border-color: var(--menu-selected-border); background: var(--menu-selected-background); }
    [data-input-mode=mouse] .widget.context > div ul li.enabled[data-color=green]:hover { border-color: var(--colors-green); background: var(--colors-green); }
    [data-input-mode=mouse] .widget.context > div ul li.enabled[data-color=red]:hover { border-color: var(--colors-red); background: var(--colors-red); }
    [data-input-mode=mouse] .widget.context > div ul li.enabled[data-color=orange]:hover { border-color: var(--colors-orange); background: var(--colors-orange); }
    .widget.context.fixedHeight > div { overflow: hidden; } 
    .widget.context.fixedHeight > div ul { max-height: 400px; overflow-y: scroll; } 
    body[data-scroll=custom] .widget.context.fixedHeight > div ul { border-right: 6px solid rgba(0,0,0,0); } 
    body[data-scroll=custom] .widget.context.fixedHeight > div ul li { margin-right: 6px; } 
    .widget.context > div ul li.hasCheckmark span.checkmark::before { content:''; display: inline-block; width: 16px; margin-right: 4px; padding-left: 4px; } 
    .widget.context > div ul li.checked span.checkmark::before { content:'✔'; font-family: SalonWidgets; font-weight: normal; font-size: 18px; line-height: 14px; position: relative; left: -3px; top: 4px; } 
    .widget.context > div ul li[data-icon]::before { font-size: 16px; margin-right: 4px; margin-top: -1px; width: 20px; order: 1; }
    .widget.context > div ul li span.checkmark { order: 0; } 
    .widget.context > div ul li span.title { order: 10; } 
`);

Widgets.ContextMenu = Class.create();
Widgets.ContextMenu.prototype = {
    initialize: function(options) {
        this.options = Object.assign({
            align:			'auto',
            verticalAlign:	'auto',
            overlay:		false,
            offset:         24,
            x:				100,
            y:				100,
            items:			[],
            onClose:        null
        }, options || {});

        this.open = false;
        this.elements = [];
        this.lastType = null;

		this.previousFocus = document.activeElement;

        this.container = new Element('div', { 'class': 'widget context' });
        this.container.style.visibility = 'hidden';


        /* Create items */

        this.wrapper = new Element('div');
        this.container.appendChild(this.wrapper);

        this.tip = new Element('div', { 'class': 'tip' });
        this.wrapper.appendChild(this.tip);

        this.list = new Element('ul');
        this.list.tabIndex = -1;
        this.list.observe('keydown', this.onKeyDown.bindAsEventListener(this));
        this.list.observe('keyup', this.onKeyUp.bindAsEventListener(this));
        this.wrapper.appendChild(this.list);

        for (var i = 0; i < this.options.items.length; i++) {
            this.options.items[i] = this.add(this.options.items[i]);
        }

        document.body.appendChild(this.container);


        /* Do we have any items, otherwise close immediately */

        if (this.elements.length === 0) {
            return this.close();
        }


        /* Determine auto alignment */

        var verticalAlign = this.options.verticalAlign;
        var horizontalAlign = this.options.align;

        if (horizontalAlign == 'auto') {
            if (this.options.x > document.body.clientWidth / 2) {
                horizontalAlign = "right";
            } else {
                horizontalAlign = "left";
            }
        }
        
        if (verticalAlign == 'auto') {
            let spaceAbove = this.options.y + this.options.offset;
            let spaceBelow = document.body.clientHeight - (this.options.y + this.options.offset);
            let spaceRequired = this.container.offsetHeight;

            if (spaceRequired < spaceBelow) {
                verticalAlign = "top";
            }
            else if (spaceRequired < spaceAbove) {
                verticalAlign = "bottom";
            }
            else if (spaceBelow > spaceAbove) {
                verticalAlign = "top";
            }
            else {
                verticalAlign = "bottom";
            }
        }


        /* Align based on preference or determined optimal aligment */

        var containerRect = this.container.getBoundingClientRect();
        
        if (verticalAlign == 'top') {
            this.container.classList.add('alignTop');

            if (horizontalAlign == 'left') {
                this.container.setStyle({ top: (this.options.y + this.options.offset) + 'px', left: (this.options.x - 20) + 'px'});
                this.container.classList.add('alignLeft');
            } 
            if (horizontalAlign == 'center') {
                this.container.setStyle({ top: (this.options.y + this.options.offset) + 'px', left: (this.options.x - parseInt(containerRect.width / 2, 10)) + 'px'});
                this.container.classList.add('alignCenter');
            } 
            if (horizontalAlign == 'right') {
                this.container.setStyle({ top: (this.options.y + this.options.offset) + 'px', right: (document.body.clientWidth - this.options.x - 20) + 'px'});
                this.container.classList.add('alignRight');
            }
        }

        if (verticalAlign == 'bottom') {
            this.container.classList.add('alignBottom');

            if (horizontalAlign == 'left') {
                this.container.setStyle({ bottom: (document.body.clientHeight - this.options.y + this.options.offset - 16) + 'px', left: (this.options.x - 20) + 'px'});
                this.container.classList.add('alignLeft');
            } 
            if (horizontalAlign == 'center') {
                this.container.setStyle({ bottom: (document.body.clientHeight - this.options.y + this.options.offset - 16) + 'px', left: (this.options.x - parseInt(containerRect.width / 2, 10)) + 'px'});
                this.container.classList.add('alignCenter');
            }
            if (horizontalAlign == 'right') {
                this.container.setStyle({ bottom: (document.body.clientHeight - this.options.y + this.options.offset - 16) + 'px', right: (document.body.clientWidth - this.options.x - 20) + 'px'});
                this.container.classList.add('alignRight');
            }
        }
        

        /* Show context menu */

        this.open = true;

        this.container.style.visibility = 'visible';

        Widgets.registerSheet(this, [ this.container ]);

        if (this.options.overlay) {
            this.overlay = new Overlay ({
                zIndex: ((Window.count + 1) * 1000) - 1,
                container: Window.container,
                animate: this.options.animate,
                owner: this.container
            });
        }

        this.list.focus();
    },

    add: function(item) {
        item = Object.assign({
            type:		'',
            title: 		'',
            color:      null,
            escape:		true,
            selected: 	false,
            visible:	true,
            enabled:	true,
            onSelect:	null,
        }, item || {});

        if (item.visible) {
            if (item.type == 'separator') {
                if (this.lastType != item.type) {
                    var element = new Element('li', { 'class': 'separator' });
                    this.list.appendChild(element);
                    this.lastType = item.type;
                }

                return item;
            }

            var element = new Element('li');
            element.innerHTML = "<span class='checkmark'></span><span class='title'>" + (item.escape ? escapeHTML(item.title) : item.title) + "</span>";

            if (item.color) {
                element.dataset.color = item.color;
            }

            if (item.enabled) {
                element.observe('click', this.select.bindAsEventListener(this, element, item));
                element.observe('keypress', this.onKeyPress.bindAsEventListener(this, element, item));
                element.tabIndex = 0;
                element.classList.add('enabled');
            } else {
                element.classList.add('disabled');
            }

            this.list.appendChild(element);

            if (typeof item.checked != 'undefined') element.classList.add('hasCheckmark');
            if (typeof item.checked != 'undefined' && item.checked) element.classList.add('checked')

            if (typeof item.icon != 'undefined') {
                if (typeof item.icon == 'object') 
                {
                    if (item.icon.id) {
                        element.dataset.icon = item.id;
                    }
    
                    if (item.icon.character) {
                        element.dataset.icon = 'custom-character';
                        element.dataset.iconCharacter = item.icon.character;
                    }
    
                    if (item.icon.color) {
                        element.dataset.iconColor = item.icon.color;
                    }
                } else {
                    element.dataset.icon = item.icon;
                }
            }

            this.lastType = item.type;

            this.elements.push(element);

            if (this.elements.length >= 20) {
                this.container.classList.add('fixedHeight');
            }
        }

        return item;
    },

    onKeyPress: function(event, element, item) {
        if (event.key == 'Enter') {
            this.select(event, element, item);
        }
    },

    onKeyDown: function(event) {
        Widgets.inputMode = 'keyboard';

        if (event.key == 'ArrowUp') {
            event.stopPropagation();

            let element;

            if (event.target == this.list) {
                element = this.list.lastElementChild;
            }
            else {
                element = event.target.closest('li').previousElementSibling;
            }

            while (element) {
                if (element.tabIndex == 0) {
                    element.focus();
                    return;
                }

                element = element.previousElementSibling;
            }
        }

        if (event.key == 'ArrowDown') {
            event.stopPropagation();

            let element;

            if (event.target == this.list) {
                element = this.list.firstElementChild;
            }
            else {
                element = event.target.closest('li').nextElementSibling;
            }

            while (element) {
                if (element.tabIndex == 0) {
                    element.focus();
                    return;
                }

                element = element.nextElementSibling;
            }
        }
    },

    onKeyUp: function(event) {
        if (event.key == 'ArrowUp' || event.key == 'ArrowDown') {
            event.stopPropagation();
        }

        if (event.key == 'Escape') {
            event.stopPropagation();
            this.close();
        }
    },

    select: function(event, element, item) {
        this.close();

        for (var i = 0; i < this.elements.length; i++) {
            if (this.elements[i] == element) {
                if (item.onSelect) {
                    item.onSelect();
                }
            }
        }
    },

    toggle: function(event) {
        event.stop();

        this.close();
    },

    close: function() {
        if (this.open == false) {
            return;
        }

        this.open = false;

        if (this.container) {
            this.container.remove();
            this.container = null;
        }

        if (this.overlay) {
            this.overlay.destroy();
        }

        if (this.previousFocus) {
            this.previousFocus.focus();
        }

        if (this.options.onClose) {
            this.options.onClose();
        }
    }
};
