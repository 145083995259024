Widgets.Color = Class.create(Widgets.Base, {
    name:		'color',

    defaults:	{
        onChange:		() => {},
        value:			'',
    },

    css: `
        .widget.color .well {
            position: relative;
            top: 2px;

            width: 24px;
            height: 24px;
            border-radius: 50%;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px inset;
            border: 1px solid rgba(0, 0, 0, 0.2);

            background: #fff;
            cursor: pointer;
        }

        .widget.color .well:hover {
            box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 1px inset;
        }

        .widget.color .well::after {
            content: '⌵';
            font-family: SalonWidgets; 
            font-size: 12px; 
            text-align: center;
            line-height: 26px;
            opacity: 0.4;
            
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            
            pointer-events: none;
        }

        .widget.color .well input {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    `,

    initWidget: function() {
        this._value = this.options.value;

        this.well = new Element('div', { className: 'well' });
        this.well.style.backgroundColor = this._value;
        this.insert(this.well);

        this.field = new Element('input', { type: 'color', value: this._value });
        this.field.observe('input', this.handleChange.bind(this));
        this.well.appendChild(this.field);

        this.defineProperty('value', {
            get: function() { return this._value; },
            set: function(value) { this._value = value; this.field.value = value; this.well.style.backgroundColor = value; }
        });
    },

    stateChange: function() {
    },

    handleChange: function(e) {
        this._value = this.field.value;
        this.well.style.backgroundColor = this._value;
        this.options.onChange(this._value);
    },
});