Widgets.Scroll = Class.create(Widgets.Base, {
    name:		'scroll',

    css: `
        .widget.scroll { position: relative; overflow: hidden; overflow-y: scroll; margin: 0; outline: none; }
        body[data-scroll=custom] .widget.scroll { border-right: 8px solid rgba(0,0,0,0); }
        body[data-scroll=custom] .widget.scroll::-webkit-scrollbar-thumb:vertical { background-color: var(--scroll-thumb); }
        body[data-scroll=custom] .widget.scroll::-webkit-scrollbar-track-piece { background-color: var(--scroll-track); }
        .widget.scroll.maximizeStyle { border-right: none; }
        body[data-scroll=custom] .widget.scroll.maximizeStyle::-webkit-scrollbar-track-piece { margin: 0; }
        body[data-scroll=custom] .widget.scroll.fullStyle { border-right: none; }
        .widget.scroll.fullStyle > * { margin-right: 12px; }
        body[data-scroll=custom] .widget.scroll.fullStyle::-webkit-scrollbar-track-piece { margin: 0; }
        body[data-scroll=custom] .widget.scroll.smallStyle { border-right: 6px solid rgba(0,0,0,0); }
        .widget.scroll.smallStyle > * { margin: 6px; }
        body[data-scroll=custom] .widget.scroll.smallStyle::-webkit-scrollbar-track-piece { margin: 6px 0; }
        .widget.scroll.smallerStyle { border-right: none; }
        .widget.scroll.smallerStyle > * { margin: 6px 0; }
        body[data-scroll=custom] .widget.scroll.smallerStyle::-webkit-scrollbar-track-piece { margin: 6px 0; }
    `,

    initWidget: function() {
        if (this.options.height) this.setStyle({ 'height': this.options.height });
        if (this.options.minHeight) this.setStyle({ 'minHeight': this.options.minHeight });
        if (this.options.style) this.classList.add(this.options.style + 'Style');

        this.internal.container.tabIndex = -1;
    },

    clear: function() {
        this.internal.container.update('');
    }
});
