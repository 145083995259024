Widgets.ButtonBar = Class.create(Widgets.Base, {
    name:		'buttonBar',

    defaults:	{
        align:		'vertical'
    },

    css: `
        body .widget.buttonBar { display: inline-flex; box-sizing: border-box; border: 1px solid var(--button-border); border-radius: 4px; background: var(--button-background); white-space: nowrap; -webkit-user-select: none; vertical-align: middle; }
        body .widget.buttonBar .widget.button { margin: 0 !important; }
        body .widget.buttonBar .widget.button button { border: none; border-radius: 0; background: none; }
        body .widget.buttonBar .widget.button button:hover { background: var(--button-hover-background); }
        body .widget.buttonBar .widget.button button:active { background: var(--button-active-background); }
        body .widget.buttonBar .widget.button button:focus { position: relative; z-index: 1; }
        body .widget.buttonBar .widget.button.active button { background: var(--button-active-background); }
        body .widget.buttonBar .widget.button.disabled button { color: var(--button-disabled-text); }
        body .widget.buttonBar.verticalAlign { flex-direction: column; }
        body .widget.buttonBar.verticalAlign .widget.button button { width: 100%; border-bottom: 1px solid var(--button-border); }
        body .widget.buttonBar.verticalAlign .widget.button:first-child button { border-top-left-radius: 4px; border-top-right-radius: 4px; border-top: none; }
        body .widget.buttonBar.verticalAlign .widget.button:last-child button { border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; border-bottom: none; }
        body .widget.buttonBar.horizontalAlign { flex-direction: row; }
        body .widget.buttonBar.horizontalAlign .widget.button button { border-right: 1px solid var(--button-border); }
        body .widget.buttonBar.horizontalAlign .widget.button:first-child button { border-top-left-radius: 4px; border-bottom-left-radius: 4px; border-left: none; }
        body .widget.buttonBar.horizontalAlign .widget.button:last-child button { border-top-right-radius: 4px; border-bottom-right-radius: 4px; border-right: none; }
        body .widget.buttonBar.horizontalAlign .widget.button.active button { border-left: 1px solid transparent; }
    `,

    initWidget: function() {
        if (this.options.align) this.classList.add(this.options.align + 'Align');
        if (this.options.width) this.setStyle({ 'width': this.options.width, 'minWidth': '0px' });
        if (this.options.height) this.setStyle({ 'height': this.options.height });
    }
});