Widgets.Footer = Class.create(Widgets.Base, {
    name:		'footer',

    defaults:	{
        title:		'',
    },

    css: `
        .widget.footer { margin-top: -40px; margin-bottom: 40px; }
        .widget.footer h3 { font-size: 0.7em; font-style: italic; font-weight: normal; line-height: 150%; color: #888; }
    `,

    initWidget: function() {
        this.footer = new Element('h3');
        this.footer.innerHTML = this.options.title;
        this.insert(this.footer);

        this.defineProperty('value', {
            get: function() { return this.options.title; },
            set: function(value) { this.options.title = value; this.footer.innerHTML = value; }
        });

        this.defineProperty('title', {
            get: function() { return this.options.title; },
            set: function(value) { this.options.title = value; this.footer.innerHTML = value; }
        });
    }
});
