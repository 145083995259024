
CSS.insert(`
    body .widget.tabbar { -webkit-user-select: none; }
    body .widget.tabbar label { display: block; font-size: 0.8em; color: #666; margin: 0 0 8px; }
    body .widget.tabbar ul { margin: 0; padding: 0; list-style-type: none; }
    body .widget.tabbar.disabled ul { opacity: 0.5; }
    body .widget.tabbar li { display: inline-block; height: 24px; line-height: 24px; cursor: pointer; font-size: 0.8em; font-weight: var(--font-weight-bold); text-transform: var(--font-transform); text-align: left; padding: 0 10px; border-top: 1px solid var(--button-border); border-left: 1px solid var(--button-border); border-bottom: 1px solid var(--button-border); background: #fff; color: var(--tab-text); }
    body .widget.tabbar li:first-child { border-bottom-left-radius: 4px; border-top-left-radius: 4px; }
    body .widget.tabbar li:last-child { border-right: 1px solid var(--button-border); border-bottom-right-radius: 4px; border-top-right-radius: 4px; }
    body .widget.tabbar li:hover { background: var(--button-hover-background); }
    body .widget.tabbar li:active { background: var(--button-active-background); }
    body .widget.tabbar.disabled li { cursor: auto; background: #fff; }
    body .widget.tabbar li.selected { background: var(--tab-vertical-selected-background); color: var(--tab-vertical-selected-text); border-color: var(--tab-vertical-selected-background); }
    body .widget.tabbar.disabled li.selected { cursor: auto; background: var(--tab-vertical-selected-background); }
    body .widget.tabbar.invalid li { border-top-color: var(--field-error-border); border-bottom-color: var(--field-error-border); background: var(--field-error-background); }
    body .widget.tabbar.invalid li:first-child { border-left-color: var(--field-error-border); }
    body .widget.tabbar.invalid li:last-child { border-right-color: var(--field-error-border); }
    [data-input-mode=mouse] .widget.tabbar li:focus { outline: none; }
    
    body .widget.tabbar.floatingType { white-space: nowrap; }
    
    body .widget.tabbar.solidType { padding-top: 7px; }
    body .widget.tabbar.solidType ul { border-radius: 5px; border: 1px solid var(--button-border); background: var(--button-background); margin-bottom: 20px; }
    body .widget.tabbar.solidType li { height: 28px; line-height: 28px; border: 1px solid rgba(0,0,0,0); background: none; margin: -1px 0 -1px -1px; }
    body .widget.tabbar.solidType li.selected { border: 1px solid var(--button-border); border-bottom: 1px solid #fff; background: #fff; border-bottom-right-radius: 0; border-bottom-left-radius: 0; border-top-right-radius: 5px; border-top-left-radius: 5px; }
    
    body .widget.tabbar.pageType { background: linear-gradient(to top, rgb(245,245,245) 0%, rgba(255,255,255,0) 70%); padding-top: 7px; margin: 0 -30px; }
    body .widget.tabbar.pageType ul { display: flex; border-bottom: 1px solid var(--tab-separator); padding: 0 30px; white-space: nowrap; }
    body .widget.tabbar.pageType ul li { height: 28px; line-height: 28px; border: 1px solid rgba(0,0,0,0); background: none; margin: 0 0 -1px 0; }
    body .widget.tabbar.pageType li.newItem { font-size: 10px; font-family: SalonIcon; }
    body.Mobile .widget.tabbar.pageType li.newItem { line-height: 30px; }
    body .widget.tabbar.pageType li.selected { color: var(--tab-selected-text); border: 1px solid var(--tab-separator); border-bottom: 1px solid #fff; background: #fff; border-bottom-right-radius: 0; border-bottom-left-radius: 0; border-top-right-radius: 5px; border-top-left-radius: 5px; }
    body .window .widget.tabbar.pageType { margin: 0 -20px 20px; }
    body .window .widget.tabbar.pageType ul { padding: 0 20px; }
    
    body .widget.tabbar.pillsType { }
    body .widget.tabbar.pillsType ul { width: 160px; }
    body .widget.tabbar.pillsType li { display: block; float: left; clear: left; border-radius: 0; background: none; line-height: 100%; height: auto; font-size: 0.9em; color: var(--tab-vertical-text); border: none; padding: 5px 6px 4px; margin-bottom: 2px; }
    body .widget.tabbar.pillsType li.selected { border-radius: 4px; color: var(--tab-vertical-selected-text); background: var(--tab-vertical-selected-background); }
    
    body .widget.tabbar.verticalType { }
    body .widget.tabbar.verticalType ul { width: 160px; border-radius: 4px; background: var(--tab-vertical-background); }
    body .widget.tabbar.verticalType li { display: block; border-radius: 0; background: none; line-height: 100%; height: auto; font-size: 0.8em; color: var(--tab-vertical-text); padding: 6px 0 6px 10px; border-left: 1px solid var(--tab-vertical-border); border-right: 1px solid var(--tab-vertical-border); border-top: 0px solid transparent; border-bottom: 0px solid transparent; }
    body .widget.tabbar.verticalType li:first-child { padding-top: 5px; border-top: 1px solid var(--tab-vertical-border); border-top-left-radius: 4px; border-top-right-radius: 4px; }
    body .widget.tabbar.verticalType li:last-child { padding-bottom: 5px; border-bottom: 1px solid var(--tab-vertical-border); border-bottom-right-radius: 4px; border-bottom-left-radius: 4px; }
    body .widget.tabbar.verticalType li.selected { padding-top: 5px; padding-bottom: 5px; border-width: 1px 1px 1px 1px; border-color: var(--tab-vertical-selected-border) var(--tab-vertical-selected-border) var(--tab-vertical-selected-border) var(--tab-vertical-selected-border) !important; border-radius: 4px; color: var(--tab-vertical-selected-text); background: var(--tab-vertical-selected-background); }
    body .widget.tabbar.verticalType.smallSize ul { width: 100px; }
    body .widget.tabbar.verticalType.smallSize li { font-size: 0.75em; padding: 5px 0 5px 8px; }
    body .widget.tabbar.verticalType.smallSize li:first-child { padding-top: 4px; }
    body .widget.tabbar.verticalType.smallSize li:last-child { padding-bottom: 4px; }
    body .widget.tabbar.verticalType.smallSize li.selected { padding-top: 4px; padding-bottom: 4px; }

    body .widget.tabbar.verticalType li[data-type=separator] {
        height: 10px;
        padding: 0;
        position: relative;
    }

    body .widget.tabbar.verticalType li[data-type=separator]::before {
        position: absolute;
        top: 4px;
        width: 100%;

        content: '';
        display: block;
        border-top: 1px solid var(--tab-vertical-border);
    }
    
    body .widget.tabbar.verticalType [data-icon]:before {
        font-size: 17px;
        width: 24px;
        display: inline-block;
        opacity: 0.6;
        vertical-align: text-bottom;
    }

    
    body .widget.tabbar.selectType ul { width: 100%; border-radius: 4px; background: var(--tab-vertical-background); }
    body .widget.tabbar.selectType li { display: block; background: none; line-height: 100%; height: auto; font-size: 0.9em; color: var(--tab-vertical-text); padding: 5px 0 5px 10px; border-left: 1px solid var(--tab-vertical-border); border-right: 1px solid var(--tab-vertical-border); border-top: 1px solid transparent; border-bottom: 1px solid transparent; }
    body .widget.tabbar.selectType li:first-child { border-top: 1px solid var(--tab-vertical-border); border-top-left-radius: 4px; border-top-right-radius: 4px; }
    body .widget.tabbar.selectType li:last-child {	border-bottom: 1px solid var(--tab-vertical-border); border-top-right-radius: 0; border-top-left-radius: 0; border-bottom-right-radius: 4px; border-bottom-left-radius: 4px; }
    body .widget.tabbar.selectType li.selected { border-color: var(--tab-vertical-selected-border) var(--tab-vertical-selected-border) var(--tab-vertical-selected-border) var(--tab-vertical-selected-border) !important; border-radius: 4px; color: var(--tab-vertical-selected-text); background: var(--tab-vertical-selected-background); }
    
    body .widget.tabbar.columnsStyle > label { display: inline-block; width: 100px; color: #000; }
    body .widget.tabbar.columnsStyle > ul { margin-left: 12px; display: inline-block; }
`);

Widgets.TabBar = Class.create();
Widgets.TabBar.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            className: 	null,
            type:		'floating',
            size:		null,
            value:		null,
            style:		null,
            items:		[],
            required:   false,
            enabled:	true,
            visible:    true,
            onNewItem:	null,
            onSelect:	null
        }, options || {});

        this.items = [];
        this._value = null;

        if (typeof parent.container != 'undefined') parent = parent.container;
        this.container = new Element('div', { 'class': 'widget tabbar ' + this.options.type + 'Type' });
        if (this.options.className) {
            this.container.classList.add(this.options.className);
        }
        parent.appendChild(this.container);

        if (this.options.style) {
            this.container.classList.add(this.options.style + 'Style');
        }

        if (this.options.size) {
            this.container.classList.add(this.options.size + 'Size');
        }

        if (this.options.required) {
            this.container.classList.add('required');
        }

        if (this.options.label) {
            var label = new Element('label').update(this.options.label);
            this.container.appendChild(label);
        }

        this.tabs = new Element('ul');
        this.container.appendChild(this.tabs);

        for (var i = 0; i < this.options.items.length; i++) {
            this.add(this.options.items[i]);
        }

        if (this.options.onNewItem) {
            this.newButton = new Element('li', { 'class': 'newItem' }).update('+');
            this.tabs.appendChild(this.newButton);

            this.newButton.observe('click', function() {
                this.options.onNewItem();
            }.bind(this));
        }

        this.options.enabled ? this.enable() : this.disable();
        this.options.visible ? this.show() : this.hide();

        this.validate();
    },

    validate: function() {
        if (this.options.required) {
            if (this._value === null) {
                this.container.classList.add('invalid');
                return;
            }
        }

        this.container.classList.remove('invalid');
    },

    add: function(item) {
        if (typeof item.visible == 'undefined' || item.visible) {
            var i = new Widgets.TabBarItem(this, item);
            this.items.push(i);

            return i;
        }
    },

    clear: function() {
        for (var i = 0; i < this.items.length; i++) {
            this.items[i].destroy();
        }

        this._value = null;
        this.onSelect(null);
    },

    select: function(item) {
        for (var i = 0; i < this.items.length; i++) {
            if (this.items[i] == item) {
                this.items[i].select();

            } else {
                this.items[i].unselect();
            }
        }
    },

    unselect: function() {
        for (var i = 0; i < this.items.length; i++) {
            this.items[i].unselect();
        }

        this._value = null;
    },

    onSelect: function(value) {
        this.validate();

        if (this.options.onSelect) {
            this.options.onSelect(value);
        }
    },

    get visible() { return this._visible; },
    set visible(value) { value ? this.show() : this.hide(); },

    show: function() {
        this.container.show();
        this._visible = true;
    },

    hide: function() {
        this.container.hide();
    },

    get value() { return this._value; },
    set value(value) {
        for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].options.value == value) {
                this.items[i].select();
            } else {
                this.items[i].unselect();
            }
        }
    },

    enable: function() {
        this.options.enabled = true;
        this.container.classList.remove('disabled');
    },

    disable: function() {
        this.options.enabled = false;
        this.container.classList.add('disabled');
    },

    get enabled() { return this.options.enabled; },
    set enabled(value) { value ? this.enable() : this.disable(); },
    get disabled() { return !this.options.enabled; },
    set disabled(value) { !value ? this.enable() : this.disable(); }
};

Widgets.TabBarItem = Class.create();
Widgets.TabBarItem.prototype = {
    initialize: function(parent, options) {
        this.parent = parent;
        this.options = Object.assign({
            title: 		'',
            type:       'item',
            escape:		true,
            className:	null,
            value:		null,
            selected: 	false,
            onSelect:	null,
        }, options || {});

        this.element = new Element('li');
        this.element.dataset.type = this.options.type;

        if (this.options.type == 'item') {
            this.element.update(this.options.escape ? escapeHTML(this.options.title) : this.options.title)

            this.element.tabIndex = 0;
            this.element.observe('click', this.click.bind(this));
            this.element.observe('keypress', this.keypress.bind(this));

            if (this.options.value !== null) {
                this.element.dataset.value = this.options.value;

                if (this.options.value == this.parent.options.value) {
                    this.options.selected = true;
                }
            }

            if (this.options.selected) {
                this.element.classList.add('selected');
                this.parent._value = this.options.value;
            }

            if (typeof this.options.icon != 'undefined') {
                if (typeof this.options.icon == 'object') 
                {
                    if (this.options.icon.id) {
                        this.element.dataset.icon = this.options.id;
                    }
    
                    if (this.options.icon.character) {
                        this.element.dataset.icon = 'custom-character';
                        this.element.dataset.iconCharacter = this.options.icon.character;
                    }
    
                    if (this.options.icon.color) {
                        this.element.dataset.iconColor = this.options.icon.color;
                    }
                } else {
                    this.element.dataset.icon = this.options.icon;
                }
            }
        }

        if (this.options.className) {
            this.element.classList.add(this.options.className);
        }

        if (this.parent.newButton) {
            this.parent.tabs.insertBefore(this.element, this.parent.newButton);
        } else {
            this.parent.tabs.appendChild(this.element);
        }
    },

    destroy: function() {
        if (this.options.type == 'item') {
            this.element.stopObserving();
        }

        this.element.remove();
    },

    click: function() {
        if (this.parent.options.enabled) {
            this.parent.select(this);
        }
    },

    keypress: function(event) {
        if (event.key == 'Enter') {
            if (this.parent.options.enabled) {
                this.parent.select(this);
            }
        }
    },

    select: function() {
        this.element.classList.add('selected');
        this.parent._value = this.options.value;

        if (this.options.onSelect) {
            this.options.onSelect(this.options.value);
        }

        if (this.parent.onSelect) {
            this.parent.onSelect(this.options.value);
        }

        document.fire("dom:update");
    },

    unselect: function() {
        this.element.classList.remove('selected');
    },

    setTitle: function(title) {
        this.options.title = title;
        this.element.update(this.options.escape ? escapeHTML(this.options.title) : this.options.title);
    }
};