Taxes = {
	add: function(value, type) {										// From exclusive to inclusive
		return value + Taxes.valueFromExclusive(value, type);
	},

	remove: function(value, type) {										// From inclusive to exclusive
		return value - Taxes.valueFromInclusive(value, type);
	},

	valueFromExclusive: function(value, type) {
		switch(type) {
			case _TAX_0_:		return Math.round(100 * ((value * 0) / 100)) / 100;
			case _TAX_6_:		return Math.round(100 * ((value * 6) / 100)) / 100;
			case _TAX_9_:		return Math.round(100 * ((value * 9) / 100)) / 100;
			case _TAX_19_:		return Math.round(100 * ((value * 19) / 100)) / 100;
			case _TAX_21_:		return Math.round(100 * ((value * 21) / 100)) / 100;
		}

		return 0;
	},

	valueFromInclusive: function(value, type) {
		switch(type) {
			case _TAX_0_:		return Math.round(100 * ((value / (100 + 0)) * 0)) / 100;
			case _TAX_6_:		return Math.round(100 * ((value / (100 + 6)) * 6)) / 100;
			case _TAX_9_:		return Math.round(100 * ((value / (100 + 9)) * 9)) / 100;
			case _TAX_19_:		return Math.round(100 * ((value / (100 + 19)) * 19)) / 100;
			case _TAX_21_:		return Math.round(100 * ((value / (100 + 21)) * 21)) / 100;
		}

		return 0;
	},

	defaultForNone: function() {
		return _TAX_0_;
	},

	defaultForProduct: function() {
		return _TAX_21_;
	},

	defaultForTreatment: function() {
		return moment().year() >= 2019 ? _TAX_9_ : _TAX_6_;
	},

	values: function(options) {
		var items = [];
		var options = Object.assign({
			includeZeroTariff:	false,
			onlyActive:			false,
			value:				null
		}, options || {});

		if (options.includeZeroTariff) {
			items.push({ title: '0%', value: _TAX_0_ });
		}

		if (!options.onlyActive || Taxes.defaultForTreatment() == _TAX_6_) {
			items.push({ title: '6%', value: _TAX_6_ });
		} else {
			if (options.value == _TAX_6_) {
				items.push({ title: '6%', value: _TAX_6_ });
			}
		}

		items.push({ title: '9%', value: _TAX_9_ });

		if (!options.onlyActive || Taxes.defaultForProduct() == _TAX_19_) {
			items.push({ title: '19%', value: _TAX_19_ });
		} else {
			if (options.value == _TAX_19_) {
				items.push({ title: '19%', value: _TAX_19_ });
			}
		}

		items.push({ title: '21%', value: _TAX_21_ });

		return items;
	}
};
