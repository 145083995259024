(function() {
	var throttled = throttle(function() {
		document.fire("dom:resize");
	}, 100);

	addEventListener('resize', function() {
		throttled();
	});

	addEventListener('orientationchange', function() {
		throttled();
	});
})();