
CSS.insert(`
    .widget.tree ul { list-style-type: none; color: #000; cursor: pointer; }
    .widget.tree ul li { position: relative; margin: 0 0 2px; }
    .widget.tree.defaultStyle ul ul { margin-bottom: 3px; }
    .widget.tree.defaultStyle ul ul:empty { position: absolute; width: 100%; min-height: 4px; margin: 0; }
    .widget.tree.defaultStyle ul ul li { margin: 0 0 2px 16px; }
    .widget.tree.defaultStyle ul ul li > div.container { font-size: 75%; font-weight: normal; }
    .widget.tree.defaultStyle ul ul li > div.container:before { display: inline-block; content: ' '; border-bottom: 1px dotted #ddd; border-left: 1px dotted #ddd; border-bottom-left-radius: 2px; width: 8px; height: 20px; position: absolute; left: -11px; top: -10px; }
    .widget.tree.defaultStyle ul ul li:first-child > div.container:before { height: 10px; top: 0px; }
    .widget.tree.defaultStyle ul li > div.container { display: inline-block; border: 1px solid transparent; font-size: 0.8em; padding: 2px 4px; text-transform: var(--font-transform); font-weight: var(--font-weight-bold); }
    .widget.tree.defaultStyle ul li.focus > div.container { border: 1px solid #d0d0d0; border-radius: 4px; background: #eee; }
    .widget.tree.defaultStyle ul li.selected > div.container { border: 1px solid; border-color: var(--colors-named-accent) var(--colors-named-accent) var(--colors-named-accent) var(--colors-named-accent); border-radius: 4px; color: #fff; background: var(--colors-named-accent); }
    .widget.tree.linesStyle ul li > div.container { display: block; margin: 0 6px; border-top: 1px solid transparent; border-left: 1px solid transparent; border-right: 1px solid transparent; border-bottom: 1px solid #e0e0e0; font-size: 0.75em; padding: 4px 8px; font-weight: normal; white-space: nowrap; overflow: hidden; }
    .widget.tree.linesStyle ul li.selected > div.container { border-color: var(--colors-named-accent); border-radius: 2px; color: #fff; background: var(--colors-named-accent); }
    .widget.tree ul li > div.container.accept { border: 1px solid #d0d0d0; border-radius: 4px; background: linear-gradient(to top, rgb(230,230,230) 16%, rgb(245,245,245) 100%); color: #000; }
    .widget.tree ul li > div.toggle { width: 9px; height: 9px; border: 1px solid #ddd; border-radius: 2px; position: absolute; left: -14px; top: 5px; line-height: 8px; text-align: center; font-weight: normal !important; font-size: 9px; color: #555; }
    .Electron.Windows .widget.tree ul li > div.toggle { top: 7px; }
    [data-input-mode=mouse] .widget.tree ul li > div.container:focus { outline: none }
`);

Widgets.List = Class.create();
Widgets.List.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            id:				'element',
            title:			null,
            value:			null,
            onInitialize:	null,
            onSelect:		null,
            onSorted:		null,
            style:			'default',
            items:			[],
            only:			false
        }, options || {});

        if (typeof parent.container != 'undefined') parent = parent.container;

        this.selected = null;
        this.items = [];

        this.element = new Element('div', { 'class' : 'widget tree ' + this.options.style + 'Style' });
        parent.appendChild(this.element);

        this.tree = new TreeList(this, null, this.element, this.options.items, {
            value:		  this.options.value,
            onInitialize: this.options.onInitialize
        })

        if (this.options.onSorted) {
            this.tree.list.id = this.options.id;
            this.createSortable();
        }

        if (this.selected) {
            Fx.scrollIntoView(this.selected.item);
        }
    },

    destroy: function() {
        this.clear();
        this.element.remove();
    },

    clear: function() {
        this.tree.destroy();
    },

    update: function(items) {
        this.tree = new TreeList(this, null, this.element, items, {
            onInitialize: this.options.onInitialize
        })
    },

    createSortable: function() {
        Sortable.create(this.tree.list, {
            tree:			false,
            dropOnEmpty:	true,
            hoverclass:		'hover',
            onUpdate:		this.onSort.bind(this),
            only:			this.options.only ? 'editable' : false
        })
    },

    onSort: function() {
        if (this.options.onSorted) {
            this.options.onSorted(Sortable.parents(this.tree.list));
        }
    },

    getItem: function(id) {
        var item = this.tree.findItem(id);

        if (item) {
            return item;
        }
    },

    newItem: function(data) {
        var parent = this.tree.findParent(data.parent);

        if (parent) {
            parent.newItem(data);
        } else {
            this.tree.newItem(data);
        }

        if (this.options.onSorted) {
            Sortable.destroy(this.tree.list);
            this.createSortable();
        }
    },

    editItem: function(data) {
        var item = this.tree.findItem(data.id);

        if (item) {
            item.edit(data);
        }
    },

    deleteItem: function(id) {
        var item = this.tree.findItem(id);

        if (item) {
            item.remove();
        }
    }
};
