Widgets.ImageEditor = Class.create(Widgets.Base, {
    name:		'imageeditor',

    defaults:	{
        multiple:       false,
        value:          { url: null, id: null },
        api:            null,
        domain:         'email',
        style:          null,
        aspectRatio:    null,
        onStatusChange: () => {},
    },

    css: `

        .widget.imageeditor {
            margin-top: -10px;
        }

        .filepond--root {
            margin-top: 12px;
        }

        .filepond--image-preview {
            background-color: #fff;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' fill='%23eee'%3E%3Cpath d='M0 0 H50 V50 H0'/%3E%3Cpath d='M50 50 H100 V100 H50'/%3E%3C/svg%3E");
            background-size: 1.25em 1.25em;
        }

        .filepond--drop-label.filepond--drop-label label {
            padding: 1.2em;
            font-size: 0.85rem;
        }

        .filepond--file {
            min-height: 44px;
        }

        .filepond--file-info {
            display: none;
        }

        .filepond--image-preview-overlay-success {
            mix-blend-mode: normal;
            color: var(--colors-named-accent);
        }

        .filepond--item[data-filepond-item-state=processing-complete] .filepond--image-preview-overlay-idle svg {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }

        .filepond--item[data-filepond-item-state=processing-complete] .filepond--image-preview-overlay-success svg {
            opacity: 0;
            transition: opacity 0.8s ease-in-out;
            transition-delay: 1.5s;
        }
        
        .filepond--item[data-filepond-item-state=processing-complete] .filepond--image-preview-wrapper:hover .filepond--image-preview-overlay-success svg {
            opacity: 0.8;
            transition: opacity 0.2s ease-in-out;
            transition-delay: 0s;
        }


        [data-filepond-item-state='processing-complete'] .filepond--item-panel {
            background-color: var(--colors-named-accent);
        }
          
        .filepond--image-clip {
            transform: translate3d(0px, 0px, 0px) scale3d(1.003, 1.003, 1) !important;
            transform-origin: top left;
        }
    
        .doka--root {
            --doka-color--alpha: var(--colors-named-accent);
            --doka-color--alpha-dim: #3b6d9c;

            --doka-button-primary--color: #ffffff;

            --doka-editor--background-color: #333;
            --doka-editor--background-ellipse-inner-color: #333;
            --doka-editor--background-ellipse-outer-color: #333;
        }
        
    `,

    initWidget: function() {
        if (this.options.style) {
            this.dataset.style = this.options.style;
        }

        this.input = document.createElement('input');
        this.input.type = 'file';

        if (this.options.multiple) {
            this.input.multiple = 'multiple';
        }

        this.insert(this.input);

        this._valueDidChange = false;
        this._working = false;
        this._metadata = new Map();

        this.defineProperty('value', {
            get: () => { 
                let files = pond.getFiles();

                if (this.options.multiple) {
                    return files.map((file, index) => {
                        if (file.status == FilePond.FileStatus.PROCESSING_COMPLETE) {
                            let url;
                            let width;
                            let height;
                            let size;
                            let type;

                            let original = file.getMetadata('original');
                            if (original) {
                                url = original.url;
                                width = original.width;
                                height = original.height;
                                size = original.size;
                                type = original.type;
                            }

                            let metadata = this._metadata.get(file.serverId);
                            if (metadata) {
                                url = metadata.url;
                                width = metadata.width;
                                height = metadata.height;
                                size = metadata.size;
                                type = metadata.type;
                            }

                            return new Object({
                                id:         file.serverId,  
                                url,
                                size,
                                type,
                                width,
                                height,
                                original,
                                position:   index
                            })
                        }
                    }).filter(file => file);
                }

                if (files.length) {
                    let file = files[0];
                    if (file.status == FilePond.FileStatus.PROCESSING_COMPLETE) {
                        let url;
                        let width;
                        let height;
                        let size;
                        let type;

                        let original = file.getMetadata('original');
                        if (original) {
                            url = original.url;
                            width = original.width;
                            height = original.height;
                            size = original.size;
                            type = original.type;
                        }

                        let metadata = this._metadata.get(file.serverId);
                        if (metadata) {
                            url = metadata.url;
                            width = metadata.width;
                            height = metadata.height;
                            size = metadata.size;
                            type = metadata.type;
                        }
                            
                        return new Object({
                            id:         file.serverId,  
                            url,
                            size,
                            type,
                            width,
                            height,
                            original,
                            position:   0
                        })
                    }
                }

                return {
                    id:         null,
                    url:        null
                }; 
            },
        });

        this.defineProperty('working', {
            get: () => this._working,
            set: (value) => { 
                let changed = value != this._working;
                this._working = value;

                if (changed) {
                    this.options.onStatusChange({
                        working: this._working
                    })
                }
            }
        });



        /* Check if we have any already existing images */

        var files = [];

        if (this.options.multiple) {
            files = this.options.value.map(i => {
                if (i.id) {
                    return { source: i.id, options: { type: 'limbo', metadata: { original: i.original || null } } };
                }
                else if (i.url) {
                    return { source: i.url, options: { metadata: { original: i.original || null } } }
                }
            }).filter(i => i);
        }
        else {
            if (this.options.value.id) {
                files.push({ source: this.options.value.id, options: { type: 'limbo', metadata: { original: this.options.value.original || null } } });
            }
            else if (this.options.value.url) {
                files.push({ source: this.options.value.url, options: { metadata: { original: this.options.value.original || null } } });
            }
        }
        


        /* Set up our editor */

        FilePond.registerPlugin(
            FilePondPluginImageResize,
            FilePondPluginImageEdit,
            FilePondPluginImageCrop,
            FilePondPluginImagePreview,
            FilePondPluginImageTransform,
            FilePondPluginImageExifOrientation,
            FilePondPluginFileValidateSize,
            FilePondPluginFileValidateType
        );

        var options = {
            imagePreviewMinHeight:  100,
            imagePreviewMaxHeight:  500,

            styleImageEditButtonEditItemPosition: 'top left',
            acceptedFileTypes: [ 'image/png', 'image/gif', 'image/jpeg', 'image/heic' ],

            imageEditEditor: Doka.create({
                outputFit:  'cover',

                labelButtonReset:               'Terug',
                labelButtonCancel:              'Annuleer',
                labelButtonConfirm:             'Opslaan',

                labelStatusAwaitingImage:       'Wachten op afbeelding...',
                labelStatusLoadingImage:        'Afbeelding laden...',
                labelStatusLoadImageError:      'Fout met laden van afbeelding',
                labelStatusProcessingImage:     'Afbeelding verwerken...',

                labelColorBrightness:           'Helderheid',
                labelColorContrast:             'Contrast',
                labelColorExposure:             'Belichting',
                labelColorSaturation:           'Verzadiging',
                labelResizeWidth:               'Breedte',
                labelResizeHeight:              'Hoogte',
                labelResizeApplyChanges:        'Toepassen',

                labelCropInstructionZoom:       'In- en uitzoomen met het scrollwiel of de trackpad.',
                labelButtonCropZoom:            'Zoom',
                labelButtonCropRotateLeft:      'Roteer links',
                labelButtonCropRotateRight:     'Roteer rechts',
                labelButtonCropRotateCenter:    'Rotatie centreren',
                labelButtonCropFlipHorizontal:  'Horizontaal spiegelen',
                labelButtonCropFlipVertical:    'Vertikaal spiegelen',
                labelButtonCropAspectRatio:     'Verhouding',
                labelButtonCropToggleLimit:     'Selectie uitknippen',
                labelButtonCropToggleLimitEnable:   'Beperk tot afbeelding',
                labelButtonCropToggleLimitDisable:  'Selecteer buiten afbeelding',
                labelButtonUtilCrop:            'Knippen',
                labelButtonUtilFilter:          'Filter',
                labelButtonUtilColor:           'Kleuren',
                labelButtonUtilResize:          'Formaat',
                labelButtonUtilMarkup:          'Tekenen',
                labelMarkupTypeRectangle:       'Rechthoek',
                labelMarkupTypeEllipse:         'Cirkel',
                labelMarkupTypeText:            'Tekst',
                labelMarkupTypeLine:            'Pijl',
                labelMarkupSelectFontSize:      'Grootte',
                labelMarkupSelectFontFamily:    'Lettertype',
                labelMarkupSelectLineDecoration:    'Decoratie',
                labelMarkupSelectLineStyle:     'Stijl',
                labelMarkupSelectShapeStyle:    'Stijl',
                labelMarkupRemoveShape:         'Verwijder',
                labelMarkupToolSelect:          'Selecteer',
                labelMarkupToolDraw:            'Tekenen',
                labelMarkupToolLine:            'Pijl',
                labelMarkupToolText:            'Tekst',
                labelMarkupToolRect:            'Vierkant',
                labelMarkupToolEllipse:         'Cirkel',

                onclose: () => { 
                    if (typeof Application != "undefined" && Application.disableOverlay) {
                        Application.disableOverlay();
                    }
                },
                onloadstart: () => { 
                    if (typeof Application != "undefined" && Application.enableOverlay) {
                        Application.enableOverlay();
                    }
                }
            }),

            server: {
                process: {
                    url: this.options.api + 'Image.Upload/process?domain=' + this.options.domain,
                    headers: { 'Authorization': 'Bearer ' + Application.current.platform.tokens['Image.Upload'] }
                },

                fetch: {
                    url: this.options.api + 'Image.Upload/process?domain=' + this.options.domain + '&fetch=',
                    headers: { 'Authorization': 'Bearer ' + Application.current.platform.tokens['Image.Upload'] }
                },

                restore: {
                    url: this.options.api + 'Image.Upload/process?domain=' + this.options.domain + '&restore=',
                    headers: { 'Authorization': 'Bearer ' + Application.current.platform.tokens['Image.Upload'] }
                },

                load: {
                    url: this.options.api + 'Image.Upload/process?domain=' + this.options.domain + '&load=',
                    headers: { 'Authorization': 'Bearer ' + Application.current.platform.tokens['Image.Upload'] }
                },

                revert: null,
            },
            
            files: files,

            labelFileLoading: 'Laden...',
            labelFileLoadError: 'Fout opgetreden!',
            labelFileProcessing: 'Verwerken...',
            labelFileProcessingAborted: '',
            labelFileProcessingComplete: '',
            labelFileProcessingError: 'Fout opgetreden!',
            labelFileProcessingRevertError: '',
            labelFileRemoveError: '',
            labelTapToUndo: '',
            labelTapToCancel: '',
            labelTapToRetry: 'Opnieuw proberen',
            labelIdle:  'Sleep hier een afbeelding of <span class="filepond--label-action"> selecteer </span>',

            labelFileTypeNotAllowed: 'Bestandtype niet toegestaan',
            fileValidateTypeLabelExpectedTypes: 'Alleen {allButLastType} of {lastType} toegestaan',
            fileValidateTypeLabelExpectedTypesMap: { 'image/jpeg': '.jpg', 'image/png': '.png', 'image/gif': '.gif' }
        };

        if (this.options.aspectRatio) {
            options.imageCropAspectRatio = this.options.aspectRatio;
        }

        if (this.options.multiple) {
            options.allowReorder = true;

            if (typeof this.options.multiple === 'number') {
                options.maxFiles = this.options.multiple;
            }
        }

        var pond = FilePond.create(this.input, options);


        /* Handle events */

        pond.on('processfilestart', () => {
            this.working = true;
        })


        pond.on('addfile', (e, file) => {
            if (this.options.onLoad) {
                this.options.onLoad();
            }
        });

        pond.on('processfile', (e, file) => {
            let options = {
                headers: {
                    'Authorization': 'Bearer ' + Application.current.platform.tokens['Image.Upload']
                }
            }

            /* Image set during initialisation */

            if (file.origin == FilePond.FileOrigin.LIMBO) {
                fetch(this.options.api + 'Image.Upload/get?id=' + file.serverId, options)
                    .then(response => response.json())
                    .then(data => { 
                        this._metadata.set(file.serverId, {
                            name:       data.name,
                            width:      data.width,
                            height:     data.height,
                            url:        data.url,
                            size:       data.size,
                            type:       data.type
                        });
                    });
            }


            /* Image uploaded or changed */

            if (file.origin != FilePond.FileOrigin.LIMBO || this._valueDidChange) {
                if (file.fileType == 'image/heic') {
                    let id = file.id;
                    let serverId = file.serverId;
                    let files = pond.getFiles();
                    let index = 0;

                    files.forEach((f,i) => {
                        if (f.id == file.id) {
                            index = i;
                        }
                    });

                    setTimeout(() => {
                        pond.removeFile(id);
                        pond.addFiles([{ source: serverId, options: { type: 'limbo' } }], { index: index });
                    }, 0);

                    return;
                }

                fetch(this.options.api + 'Image.Upload/get?id=' + file.serverId, options)
                    .then(response => response.json())
                    .then(data => { 
                        this._metadata.set(file.serverId, {
                            name:       data.name,
                            width:      data.width,
                            height:     data.height,
                            url:        data.url,
                            size:       data.size,
                            type:       data.type
                        });
                        
                        if (this.options.onChange) {
                            this.options.onChange({
                                id:     file.serverId,
                                name:   data.name,
                                size:   data.size,
                                type:   data.type,
                                url:    data.url,
                                ratio:  data.ratio
                            });
                        }
                    });
            }

            this._valueDidChange = false;
        });

        pond.on('processfiles', () => {
            this.working = false;
        })

        pond.on('removefile', (e, file) => {
            if (this.options.onRemove) {
                this.options.onRemove();
            }
        });

        pond.on('preparefile', (e, file) => {
            this._valueDidChange = true;
        });
    }
});