CSS.insert(`
    .widget.calendar { border-collapse:collapse; width: 220px; -webkit-user-select: none; }
    .widget.calendar td.prev { font-family: SalonIcon; -webkit-font-smoothing: antialiased; font-size: 0.6rem; text-align: left; }
    .widget.calendar td.next { font-family: SalonIcon; -webkit-font-smoothing: antialiased; font-size: 0.6rem; text-align: right; }
    .widget.calendar td { border:1px solid #ddd; padding:3px; text-align:center; font-weight: var(--font-weight-bold); cursor: pointer; font-size: 80%; background-color: #fff; color: #333; }
    .widget.calendar td, x:-moz-any-link, x:default { padding: 2px 3px; }
    .widget.calendar td.week { background-color: transparent; border: none; font-weight: normal;  font-size: 70%; color: #666; }
    .widget.calendar td.disabled { cursor: default; color:#ddd; }
    .widget.calendar td.empty { cursor: default; background: #f6f6f6; }
    .widget.calendar td.active { color: #fff; background: var(--colors-named-accent); border: 1px solid var(--colors-named-accent); }
    .widget.calendar td.week.even { padding-right: 5px; border-right: 5px solid var(--colors-green); }
    .widget.calendar td.week.odd { padding-right: 5px; border-right: 5px solid #ddd; }
    .widget.calendar .dow td { border: none; color: #aaa; font-size: 70%; cursor: default; }
    .widget.calendar .header { background: transparent; border: none; border-bottom: 8px solid transparent; }
    .widget.calendar .header td { border: none; background: transparent; color: #666; text-transform: var(--font-transform); cursor: default; }
    [data-input-mode=mouse] .widget.calendar td:focus { outline: none; }
    .miniSize .widget.calendar { border-collapse:collapse; width: 166px; }
    .miniSize .widget.calendar td { padding: 2px 2px; font-size: 70%; }
    .miniSize .widget.calendar .dow td { font-size: 60%; }
    .miniSize .widget.calendar .header td { font-size: 70%; }
`);

Widgets.Calendar = DateSelect;
