Widgets.Header = Class.create(Widgets.Base, {
    name:		'header',

    defaults:	{
        title:		'',
        subtitle:	''
    },

    css: `
        .widget.header { margin-bottom: 1rem; }
        .widget.header p { font-size: 0.75em; color: #888; margin-top: 4px; }
        .widget.header h3 { text-transform: var(--font-transform); font-size: 1.2rem; color: #3E4A59; font-weight: var(--font-weight-bold); }
        .widget.header h3 em { font-style: normal; color: #aaa; }
        .widget.header h3 small { font-size: 60%; color:#aaa; font-style: italic; font-weight: normal; margin-left: 10px; }
        .widget.header h3[data-icon]::before { font-size: 125%; position: relative; top: 1px; margin-right: 3px; }
        .widget.header.smallSize h3 { font-size: 1.0rem; }
        .widget.header.smallerSize h3 { font-size: 0.9rem; color: #aaa; }
        .widget.header.smallestSize h3 { font-size: 0.8rem; margin-top: -2.5em; }
        .widget.header.informationSize h3 { font-size: 0.8rem; text-align: center; color: #aaa; padding: 50px 100px 0 100px; }
        .widget.header.placeholderSize { padding-bottom: 100px; }
        .widget.header.placeholderSize h3 { font-size: 0.9rem; text-align: center; color: #aaa; padding-top: 100px; }
        .widget.header.placeholderSize h3[data-icon]::before { font-size: 10rem; display: block; color: #eee; margin-bottom: 20px; text-shadow: 0px -1px 0px #ddd; }
        .widget.header.placeholderSize p { padding-top: 20px; font-size: 0.7rem; text-align: center; }
    `,

    initWidget: function() {
        this.header = new Element('h3');
        this.header.innerHTML = this.options.title;
        this.insert(this.header);

        if (this.options.subtitle != '') {
            this.paragraph = new Element('p');
            this.paragraph.innerHTML = this.options.subtitle;
            this.insert(this.paragraph);
        }

        if (this.options.icon) {
            if (typeof this.options.icon == 'object') 
            {
                if (this.options.icon.id) {
                    this.header.dataset.icon = this.options.icon.id;
                }

                if (this.options.icon.character) {
                    this.header.dataset.icon = 'custom-character';
                    this.header.dataset.iconCharacter = this.options.icon.character;
                }

                if (this.options.icon.color) {
                    this.header.dataset.iconColor = this.options.icon.color;
                }
            } else {
                this.header.dataset.icon = this.options.icon;
            }
        }


        if (this.options.size) this.classList.add(this.options.size + 'Size');

        this.defineProperty('value', {
            get: function() { return this.options.title; },
            set: function(value) { this.options.title = value; this.header.innerHTML = value; }
        });

        this.defineProperty('title', {
            get: function() { return this.options.title; },
            set: function(value) { this.options.title = value; this.header.innerHTML = value; }
        });

        this.defineProperty('subtitle', {
            get: function() { return this.options.subtitle; },
            set: function(value) { this.options.subtitle = value; this.paragraph.innerHTML = value; }
        });
    }
});
