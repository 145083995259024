
CSS.insert(`
    .widget.toolbar { display: flex; width: 100%; flex-direction: row; justify-content: space-between; }
    .widget.toolbar > .item { display: block; padding: 0 6px; }
    .widget.toolbar > .item:first-child { padding-left: 30px; }
    .widget.toolbar > .item:last-child { padding-right: 30px; }
    .widget.toolbar > .item.flexible { flex: 1; }
`);

Widgets.Toolbar = Class.create();
Widgets.Toolbar.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            items:		[]
        }, options || {});

        if (typeof parent.container != 'undefined') parent = parent.container;

        this.container = new Element('div', { 'class': 'widget toolbar' });
        if (this.options.className) this.container.classList.add(this.options.className);
        parent.appendChild(this.container);


        for (var i = 0; i < this.options.items.length; i++) {
            var item = Object.assign({
                flexible:		typeof this.options.items[i].onInitialize != 'undefined' ? false : true,
                onInitialize:	null
            }, this.options.items[i]);

            var wrapper = new Element('div', { 'class': 'item' + (item.flexible ? ' flexible' : '') });
            this.container.appendChild(wrapper);

            if (typeof item.width != 'undefined') {
                wrapper.setStyle({ width: item.width });
            }

            if (item.onInitialize) {
                item.onInitialize(wrapper);
            }
        }
    },

    show: function() {
        this.container.show();
    },

    hide: function() {
        this.container.hide();
    }
}
