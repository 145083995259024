Layout = (function() {
	var Layout = {};



	CSS.insert(`
		.layout.generic { position: absolute; top: 0; left: 0; right: 0; bottom: 0; color: #000; }
		.layout.generic > div.content { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
		.layout.generic > div.content.padding { padding: 30px; }
		.layout.generic > div.content.overflow { overflow-y: scroll; }
		body[data-scroll=custom] .layout.generic > div.content.overflow { border-right: 8px solid rgba(0,0,0,0); }
	`);

	Layout.Generic = Class.create();
	Layout.Generic.prototype = {
		initialize: function(parent, options) {
			this.options = Object.assign({
				overflow: true,
				padding: true,
				visible: true,
				className: null,
			}, options || {});
			
			if (typeof parent.container != 'undefined') parent = parent.container;

			this.element = new Element('div', { 'class': 'layout generic' });
			parent.appendChild(this.element);

			if (this.options.className) {
				this.element.classList.add(...this.options.className.split(' '));
			}

			this.content = new Element('div', { 'class': 'content' });
			this.element.appendChild(this.content);

			if (this.options.padding) this.content.classList.add('padding');
			if (this.options.overflow) this.content.classList.add('overflow');

			if (!this.options.visible) {
				this.hide();
			}
		},
		
		hide: function() {
			this.element.hide();	
		},
		
		show: function() {
			this.element.show();	
		}
	};
	
	

	CSS.insert(`
		.layout.toolbar { position: absolute; top: 0; left: 0; right: 0; bottom: 0; color: #000; }
		.layout.toolbar > div.wrapper { position: absolute; top: 0; left: 0; right: 0; height: 60px; z-index: 1; background: linear-gradient(to bottom, rgba(255,255,255,0) 50px, rgba(0,0,0,0.03) 51px, rgba(255,255,255,0) 60px); }
		.layout.toolbar > div.wrapper > div.top { width: 100%; height: 41px; border-bottom: 1px solid #e0e0e0; padding: 9px 0 0; }
		.layout.toolbar > div.wrapper > div.top .widget.header { position: relative; top: 6px; }
		.layout.toolbar > div.bottom { position: absolute; top: 50px; left: 0; right: 0; bottom: 0; }
		.layout.toolbar > div.wrapper > div.top.padding { padding-left: 30px; padding-right: 30px; width: calc(100% - 60px); }
		.layout.toolbar > div.bottom.padding { padding: 30px; }
		.layout.toolbar.separator > div.bottom { border-top: 1px solid #eee; }
		.layout.toolbar > div.bottom.overflow { overflow-y: scroll; }
		body[data-scroll=custom] .layout.toolbar > div.bottom.overflow { border-right: 8px solid rgba(0,0,0,0); }


		/* Expanded viewport */

		body[data-viewport=expand] .layout.toolbar {
			position: static;
		}

		body[data-viewport=expand] .layout.toolbar > div.wrapper {
			position: relative;
			top: auto; left: auto; right: auto; bottom: auto;
		}

		body[data-viewport=expand] .layout.toolbar > div.bottom {
			position: relative;
			top: auto; left: auto; right: auto; bottom: auto;
			margin-top: -10px;
			min-height: calc(100vh - 50px);
		}
	`);

	Layout.Toolbar = Class.create();
	Layout.Toolbar.prototype = {
		initialize: function(parent, options) {
			this.options = Object.assign({
				separator:	true,
				overflow: true,
				padding: true,
				top: {},
				bottom: {}
			}, options || {});
			
			this.options.top = Object.assign({
				padding: true
			}, this.options.top);
			
			this.options.bottom = Object.assign({
				padding: true
			}, this.options.bottom);
			
			if (typeof parent.container != 'undefined') parent = parent.container;

			this.element = new Element('div', { 'class': 'layout toolbar' });
			parent.appendChild(this.element);

			var wrapper = new Element('div', { 'class': 'wrapper' });
			this.element.appendChild(wrapper);

			this.top = new Element('div', { 'class': 'top' });
			wrapper.appendChild(this.top);

			this.bottom = new Element('div', { 'class': 'bottom' });
			this.element.appendChild(this.bottom);

			if (this.options.separator) this.element.classList.add('separator');
			if (this.options.padding && this.options.top.padding) this.top.classList.add('padding');
			if (this.options.padding && this.options.bottom.padding) this.bottom.classList.add('padding');
			if (this.options.overflow) this.bottom.classList.add('overflow');

			if (this.options.className) {
				this.element.classList.add(...this.options.className.split(' '));
			}
		},
		
		hide: function() {
			this.element.hide();	
		},
		
		show: function() {
			this.element.show();	
		}
	};


	CSS.insert(`
		.layout.sidebar { display: table; width: 100%; height: 100%; }
		.layout.sidebar > div { display: table-row; }
		.layout.sidebar > div > .left { display: table-cell; vertical-align: top; width: 170px; min-width: 170px; padding: 30px 20px 30px 30px; position: relative; }
		.layout.sidebar > div > .right { display: table-cell; vertical-align: top; padding: 30px; position: relative; }
		.layout.toolbar > .wrapper > .top .layout.sidebar > div > div { padding-top: 0 !important; }
		.layout.sidebar.separator { background: linear-gradient(to bottom, #fff 10px, rgba(255,255,255,0) 40px), linear-gradient(to right, #fff 220px, #eee 221px, #fff 235px); }
	`);

	Layout.Sidebar = Class.create();
	Layout.Sidebar.prototype = {
		initialize: function(parent, options) {
			this.options = Object.assign({
				separator:	true,
				visible:	true
			}, options || {});
			
			if (typeof parent.container != 'undefined') parent = parent.container;

			this.element = new Element('div', { 'class': 'layout sidebar' });
			parent.appendChild(this.element);

			if (this.options.separator) {
				this.element.classList.add('separator');
			}

			if (this.options.className) {
				this.element.classList.add(...this.options.className.split(' '));
			}
			
			var wrapper = new Element('div');
			this.element.appendChild(wrapper);
			
			this.left = new Element('div', { 'class': 'left' });
			wrapper.appendChild(this.left);

			this.right = new Element('div', { 'class': 'right' });
			wrapper.appendChild(this.right);
			
			if (!this.options.visible) {
				this.hide();
			}
		},
		
		hide: function() {
			this.element.hide();	
		},
		
		show: function() {
			this.element.show();	
		},
	};


	CSS.insert(`
		.layout.panels { position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden; color: #000; }
		.layout.panels > div > div.panel { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
		.layout.panels > div > div.panel.padding { padding: 30px; }
		.layout.panels > div > div.panel.overflow { overflow-y: scroll; }
		body[data-scroll=custom] .layout.panels > div > div.panel.overflow { border-right: 8px solid rgba(0,0,0,0); }


		/* Expanded viewport */

		body[data-viewport=expand] .layout.panels {
			position: static;
			min-height: 100vh;
		}

		body[data-viewport=expand] .layout.panels > div.panel {
			position: relative;
			top: auto; left: auto; right: auto; bottom: auto;
			min-height: 100vh;
		}

	`);

	Layout.Panels = Class.create();
	Layout.Panels.prototype = {
		initialize: function(parent, data) {
			this.data = data;
			this.items = {};
		
			this.container = new Element('div', { 'class': 'layout panels' });
			parent.appendChild(this.container);
		
			var wrapper = new Element('div');
			this.container.appendChild(wrapper);
				
			for (var i = 0; i < this.data.length; i++) {
				var panel = new Element('div', { 'class': 'panel' });
				wrapper.appendChild(panel);
				
				if (typeof this.data[i].padding != 'undefined' && this.data[i].padding) {
					panel.classList.add('padding');
				}
				
				if (typeof this.data[i].overflow != 'undefined' && this.data[i].overflow) {
					panel.classList.add('overflow');
				}
				
				this.items[this.data[i].name] = panel;
				this.data[i].element = panel;
			}
			
			this.width = this.container.getWidth();
			this.current = null;
		
			this.go(0, false);
		},
		
		back: function(cb) {
			if (this.current > 0) {
				this.go(this.current - 1, true, cb);
			}	
		},
		
		forward: function(cb) {
			if (this.current < this.data.length - 1) {
				this.go(this.current + 1, true, cb);
			}	
		},
		
		goToId: function(id, cb) {
			var n = null;
			
			for (var i = 0; i < this.data.length; i++) {
				if (this.data[i].name == id) {
					n = i; 
				}
			}
			
			if (n != null) {
				this.go(n, true, cb);
			}
		},
		
		go: function(n, animate, cb) {
			if (animate) {
				this.width = Math.round(this.container.getWidth() * 0.66);
			
				if (n > this.current) {
					this.slide(this.data[n].element, 'right', 'center');
					this.slide(this.data[this.current].element, 'center', 'left', cb);
				}
				
				if (n < this.current) {
					this.slide(this.data[n].element, 'left', 'center');
					this.slide(this.data[this.current].element, 'center', 'right', cb);
				}
				
				this.current = n;
			}
			
			else {
				for (var i = 0; i < this.data.length; i++) {
					if (i == n) {
						this.data[i].element.show();
					} else {
						this.data[i].element.hide();
					}
				}
				
				if (cb) {
					cb();
				}
				
				this.current = n;
			}
		},
		
		slide: function(el, from, to, cb) {
			var duration = this.width / 4500;
			var begin = (from == 'left' ? 0 - this.width : (from == 'right' ? this.width : 0));
			var end = (to == 'left' ? 0 - this.width : (to == 'right' ? this.width : 0));
			
			if (to == 'center') {
				el.style.opacity = 0;
				el.style.transform = 'translateX(' + begin + 'px)';
				el.show();
			
				el.offsetHeight;
			
				window.setTimeout(function() {
					el.style.transition = 'transform ' + duration + 's ease-in, opacity ' + duration + 's ease-in';
					
					window.setTimeout(function() {
						el.style.transform = 'translateX(0px)';
						el.style.opacity = 1;
					}.bind(this), 0);

					window.setTimeout(function() {
						window.setTimeout(function() {
							el.style.transition = '';
							el.style.transform = '';
						}.bind(this), 0);
	
						if (cb) {
							cb();
						}
					}.bind(this), duration * 1000);
				}.bind(this), 0);
			}
			
			else {
				el.style.transform = 'translateX(0px)';
				el.style.opacity = 1;

				el.offsetHeight;

				window.setTimeout(function() {
					el.style.transition = 'transform ' + duration + 's ease-in, opacity ' + duration + 's ease-in';

					window.setTimeout(function() {
						el.style.transform = 'translateX(' + end + 'px)';
						el.style.opacity = 0;
					}.bind(this), 0);
					
					window.setTimeout(function() {
						el.hide();
						
						window.setTimeout(function() {
							el.style.transition = '';
							el.style.transform = '';
							el.style.opacity = 1;
						}.bind(this), 0);
	
						if (cb) {
							cb();
						}
					}.bind(this), duration * 1000);
				}.bind(this), 0);
			}
		},
		
		hide: function() {
			this.container.hide();	
		},
		
		show: function() {
			this.container.show();	
		}
	};
	
	
	
	CSS.insert(`
		.layout.columns { display: table; width: 100%; }
		.layout.columns > div { display: table-row; }
		.layout.columns > div > .column { display: table-cell; vertical-align: top; padding: 0 30px 0 0; }
		.layout.columns > div > .column:last-child { padding: 0; }
	`);

	Layout.Columns = Class.create();
	Layout.Columns.prototype = {
		initialize: function(parent, columns) {
			this.columns = {};
		
			this.container = new Element('div', { 'class': 'layout columns' });
			parent.appendChild(this.container);
		
			var wrapper = new Element('div');
			this.container.appendChild(wrapper);
				
			for (var i = 0; i < columns.length; i++) {
				var column = new Element('div', { 'class': 'column' });
				wrapper.appendChild(column);
				
				if (columns[i].width) {
					column.setStyle({ 'width': columns[i].width });
				}

				if (columns[i].minWidth) {
					column.setStyle({ 'minWidth': columns[i].minWidth });
				}
				
				if (columns[i].maxWidth) {
					column.setStyle({ 'maxWidth': columns[i].maxWidth });
				}
				
				if (columns[i].align) {
					column.setStyle({ 'textAlign': columns[i].align });
				}
				
				this.columns[columns[i].name] = column;
			}
		}
	};
	
	
	
	CSS.insert(`
		.layout.flex { display: flex; }
		.layout.flex[data-orientation="horizontal"] { width: 100%; flex-direction: row; }
		.layout.flex[data-orientation="vertical"] { height: 100%; flex-direction: column; }
		.layout.flex[data-pack="start"] { justify-content: flex-start; }
		.layout.flex[data-pack="end"] { justify-content: flex-end; }
		.layout.flex[data-pack="center"] { justify-content: center; }
		.layout.flex[data-pack="justify"] { justify-content: space-between; }
		.layout.flex[data-align="start"] { align-items: flex-start; }
		.layout.flex[data-align="end"] { align-items: flex-end; }
		.layout.flex[data-align="center"] { align-items: center; }
		.layout.flex[data-align="stretch"] { align-items: stretch; }
		.layout.flex > .box { display: block; box-sizing: border-box; }
		.layout.flex > .box.flexible { flex: auto; }
	`);

	Layout.Flex = Class.create();
	Layout.Flex.prototype = {
		initialize: function(parent, options) {
			this.options = Object.assign({
				orientation: 	'horizontal',
				align:			'stretch',
				pack:			'start',
				visible:		true,
				className:		null,
				items:			[]
			}, options || {});
		
			this.items = {};
		
			if (typeof parent.container != 'undefined') parent = parent.container;
			this.container = new Element('div', { 'class': 'layout flex' });
			this.container.dataset.orientation = this.options.orientation;
			this.container.dataset.pack = this.options.pack;
			this.container.dataset.align = this.options.align;
			parent.appendChild(this.container);

			if (this.options.className) {
				this.container.classList.add(this.options.className);
			}

			for (var i = 0; i < this.options.items.length; i++) {
				var item = new Element('div', { 'class': 'box ' + (this.options.items[i].name ? this.options.items[i].name + 'Box': '') });
				item.dataset.name = this.options.items[i].name || '';
				this.container.appendChild(item);
				
				var attributes = [ 'width', 'minWidth', 'maxWidth', 'height', 'minHeight', 'maxHeight' ];
				var style = {};

				for (var a = 0; a < attributes.length; a++) {
					if (this.options.items[i][attributes[a]]) {
						style[attributes[a]] = this.options.items[i][attributes[a]];
					}
				}
				
				item.setStyle(style);

				if (this.options.items[i].flexible) {
					item.classList.add('flexible');
				}
				
				if (this.options.items[i].name) {
					this.items[this.options.items[i].name] = item;
				} else {
					this.items['unnamed-' + i] = item;
				}
			}

			if (!this.options.visible) this.hide();
		},
		
		show: function() {
			this.container.show();
			this.options.visible = true;
		},
		
		hide: function() {
			this.container.hide();
			this.options.visible = false;
		},
		
		toggle: function() {
			this.container.toggle();
			this.options.visible = !this.options.visible;
		},

		get visible() { return this.options.visible; },
		set visible(value) { value ? this.show() : this.hide(); },	
	};
	

	return Layout;
})();
