
CSS.insert(`
    body .widget.range { position: relative; }
    body .widget.range > label { font-size: 0.8em; display: inline-block; }
    body .widget.range > div { display: flex; align-items: center; }
    body .widget.range[data-style=columns] { width: 100%; display: flex; flex-direction: row; justify-content: space-between; }
    body .widget.range[data-style=columns] > label { display: block; width: 100px; vertical-align: top; margin: 8px 0 0; }
    body .widget.range[data-style=columns] > div {  flex: 1; vertical-align: top; margin: 0 12px; }
    body .widget.range[data-style=columns] > :last-child { margin-right: 0; }
    body .widget.range .widget.field { margin: 0; }
    body .widget.range .widget.numeric.spinner { margin: 0 0 0 12px; }
`);


Widgets.Field.Range = Class.create();
Widgets.Field.Range.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            name:           null,
            className:		null,
            style:			null,
            label: 			null,
            value:			0,
            maximum:		100,
            minimum:		0,
            step:			1,
            onChange:		null
        }, options || {});
        
        if (typeof parent.container != 'undefined') parent = parent.container;
        this.container = new Element('div', { 'class': 'widget range' });
        parent.appendChild(this.container);

        if (this.options.name) {
            this.container.dataset.name = this.options.name;
        }

        if (this.options.style) {
            this.container.dataset.style = this.options.style;
        }

        if (this.options.label) {
            this.label = new Element('label').update(this.options.label);
            this.container.appendChild(this.label);
        }

        if (this.options.className) {
            this.container.classList.add(this.options.className);
        }

        var wrapper = new Element('div');
        this.container.appendChild(wrapper);

        this.field = new Widgets.Field (wrapper, {
            width:      '60px',
            value:      this.options.value,
            allow:      '0123456789',
            onValidate: this.onValidateField.bind(this),
            onChange:	this.onChangeField.bind(this)
        });

        this.spinner = new Widgets.NumericSpinner(wrapper, {
            value:      this.options.value,
            maximum:    this.options.maximum,
            minimum:    this.options.minimum,
            step:       this.options.step,
            preview:    false,
            onChange:	this.onChangeSpinner.bind(this)
        });
    },

    destroy: function() {
        this.field.destroy();
        this.spinner.destroy();
    },

    focus: function() {
        this.field.focus();
    },

    blur: function() {
        this.field.blur();
        this.spinner.blur();
    },

    onValidateField: function(value) {
        let valid = true;

        if (value == '') {
            valid = false;
        }

        if (parseInt(value, 10) < this.options.minimum) {
            valid = false;
        }

        if (parseInt(value, 10) > this.options.maximum) {
            valid = false;
        }

        return valid;
    },

    onChangeField: function() {
        if (this.field.valid) {
            this.spinner.value = this.field.value;
        }

        this.onChange();
    },

    onChangeSpinner: function() {
        this.field.value = this.spinner.value;
        this.onChange();
    },

    onChange: function() {
        if (this.options.onChange) {
            this.options.onChange();
        }
    },

    get value() { 
        if (this.field.value) {
            return this.field.value; 
        }

        return this.options.value;
    },
    set value(value) {
        this.field.value = value;
    },

    get valid() {
        return this.field.valid;
    }
};

