CSS.insert(`
    .widget.badge { position: absolute; top: 0; right: 0; margin: -6px -6px 0 0; min-width: 18px; height: 18px; line-height: 18px; }
    .widget.badge { border-radius: 10px; color: #fff; background: var(--colors-red); }
    .widget.badge { font-weight: normal; font-size: 11px; text-align: center; }
    .widget.badge.checked { background: var(--colors-named-accent); }
    .widget.badge.checked::before { display: block; content: '✔'; font-family: SalonWidgets; font-size: 14px; color: #fff; }
`);

Widgets.Badge = Class.create();
Widgets.Badge.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            value:			null,
            checked:		false,
            visible:		true,
            onFormat:		null,
        }, options || {});

        this._value = this.options.value;

        if (typeof parent.container != 'undefined') parent = parent.container;
        this.element = new Element('div', { 'class': 'widget badge' });
        parent.appendChild(this.element);


        if (this.options.checked) {
            this.element.classList.add('checked');
        }

        if (this.options.onFormat) {
            this.element.update(this.options.onFormat(this._value));
        } else {
            this.element.update(this._value);
        }

        if (!this.options.visible) this.hide();
    },

    show: function() {
        this.element.show();
        this.options.visible = true;
    },

    hide: function() {
        this.element.hide();
        this.options.visible = false;
    },

    get visible() { return this.options.visible; },
    set visible(value) { value ? this.show() : this.hide(); },

    get value() { return this._value; },
    set value(value) {
        this._value = value;

        if (this.options.onFormat) {
            this.element.update(this.options.onFormat(this._value));
        } else {
            this.element.update(this._value);
        }
    }
}