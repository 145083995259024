
CSS.insert(`
    .widget.output { font-size: 0.75em; }
`);

Widgets.Output = Class.create();
Widgets.Output.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            value:			null,
            onFormat:		null,
        }, options || {});

        this._value = this.options.value;

        if (typeof parent.container != 'undefined') parent = parent.container;
        this.element = new Element('div', { 'class': 'widget output' });
        parent.appendChild(this.element);

        if (this.options.onFormat) {
            this.element.update(this.options.onFormat(this._value));
        } else {
            this.element.update(this._value);
        }
    },

    get value() { return this._value; },
    set value(value) {
        this._value = value;

        if (this.options.onFormat) {
            this.element.update(this.options.onFormat(this._value));
        } else {
            this.element.update(this._value);
        }
    }
}
