Widgets.TextEditor = Class.create(Widgets.Base, {
    name:		'texteditor',

    defaults:	{
        value:          '',
        placeholder:    null,
        paragraphStyle: false,
        hideToolbar:    false,
        type:           'text/html',
    },

    css: `

        .widget.texteditor {
            margin: 0;
        }

        .fr-wrapper {
            line-height: 1.4;    
            font-size: 14px;
        }

        .fr-wrapper a {
            color: var(--colors-named-accent);
        }

        .fr-wrapper span[contenteditable=false] {
            background: #fff;
            border-radius: 4px;
            color: var(--colors-named-accent);
            box-shadow: 0 0 0 1px var(--colors-named-accent);
            margin-left: 1px;
            margin-right: 1px;
            display: inline-block;
            padding-left: 2px;
            padding-right: 2px;
        }

        .fr-wrapper p {
            margin: 0 0 0.6em;
        }

        .fr-wrapper ul {
            margin: 1em 0 1em;
            padding: 0 0 0 1.2em;
        }
        .fr-wrapper li {
            margin: 0 0 0.5em;
        }

        .fr-view hr {
            border: 1px solid #aaa;
            border-bottom: 0;
            margin: 1em 0;
        }

        .fr-box.fr-basic .fr-wrapper {
            border-radius: 0;
            -webkit-border-radius: 0;
            border: none;
        }

        .fr-box.fr-basic .fr-element {
            padding: 1px 0 6px;
            min-height: 0px;
        }
       
        .fr-toolbar.fr-bottom {
            border-radius: 4px;
            border: 1px solid #d4d4d4;
            display: inline-block;
        }

        [data-hide-toolbar=true] .fr-toolbar.fr-bottom {
            opacity: 0;
            transform: translateY(8px);
            transition: opacity 0.3s ease-in-out, transform 0.2s ease-in-out;
        }
        [data-hide-toolbar=true]:hover .fr-toolbar.fr-bottom {
            transform: translateY(0px);
            opacity: 1;
        }

        .fr-toolbar .fr-newline {
            display: none;
        }

        .fr-separator.fr-vs {
            margin: 0;
            height: 28px;
        }
        .fr-separator {
            background: #d4d4d4;
        }

        .fr-toolbar .fr-btn-grp {
            margin: 0 3px 0 3px;
        }

        .fr-toolbar .fr-command.fr-btn,
        .fr-popup .fr-command.fr-btn {
            height: 24px;
            margin: 2px 1px;
        }
        .fr-toolbar .fr-command.fr-btn svg.fr-svg,
        .fr-popup .fr-command.fr-btn svg.fr-svg {
            width: 18px;
            height: 18px;
        }
        .fr-toolbar .fr-command.fr-btn i, 
        .fr-toolbar .fr-command.fr-btn svg,
        .fr-popup .fr-command.fr-btn i, 
        .fr-popup .fr-command.fr-btn svg {
            margin: 0px 3px;
            height: 18px;
        }

        .fr-toolbar .fr-command.fr-btn span,
        .fr-popup .fr-command.fr-btn span {
            min-width: 14px;
            font-size: 13px;
        }

        .fr-toolbar .fr-more-toolbar {
            position: static;
            float: none;
        }
        .fr-toolbar .fr-more-toolbar.fr-expanded {
            height: 28px;
        }
        .fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li {
            font-size: 13px;
        }

        .fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper {
            max-height: 160px;
            overflow-y: auto;
        }

    `,

    initWidget: function() {
        this.input = document.createElement('textarea');
        this.input.value = this.protectVariables(this.unprotectVariables(this.options.value)); 
        this.insert(this.input);

        var variables = {}; 

        if (this.options.hideToolbar) {
            this.dataset.hideToolbar = 'true';
        }
        
        if (this.options.variables) {
            this.options.variables.map(i => i == 'salon.signature' ? 'salon.name' : i ).forEach(i => { variables[i] = '{{ ' + i + ' }}' });
        
            FroalaEditor.DefineIcon('variables', { NAME: '{{', template: 'text' });
            FroalaEditor.RegisterCommand('variables', {
                title: 'Variabelen',
                type: 'dropdown',
                focus: true,
                undo: false,
                options: variables,
                callback: function (cmd, val) {
                    this.html.insert('<span contenteditable=false>{{ ' + val + ' }}</span>');
                }
            });
        }

        var editorOptions = {};

        if (this.options.type == 'text/plain') {
            this.addEventListener('keydown', this.preventStylesFromKeyboard);

            editorOptions = {
                toolbarButtons: [ (this.options.variables ? ['variables'] : null ) ],
                shortcutsEnabled: ['undo', 'redo'],
                htmlAllowedTags: ['span'],
                htmlAllowedAttrs: ['contenteditable']
            }
        }
        else {
            if (this.options.paragraphStyle) {
                editorOptions = {
                    toolbarButtons: {
                        'text': { buttons: [ 'bold', 'italic', 'underline', 'strikeThrough', '|', 'fontSize', 'textColor', 'backgroundColor', '|', 'clearFormatting', '|' ], buttonsVisible: 99 }, 
                        'moreParagraph': { buttons: [ 'paragraphFormat', 'formatUL', 'alignLeft', 'alignCenter', 'alignRight' ], buttonsVisible: 2 },
                        'link': { buttons: [ '|', 'insertLink', 'insertHR' ], buttonsVisible: 99 }, 
                        'variables': { buttons: (this.options.variables ? [ '|', 'variables' ] : null ), buttonsVisible: 99 }
                    }
                }
            }
            else {
                editorOptions = {
                    toolbarButtons: [[ 'bold', 'italic', 'underline', 'strikeThrough' ], '|', [ 'fontSize', 'textColor' ], '|', [ 'clearFormatting' ], '|', [ 'paragraphFormat', 'formatUL' ], '|', [ 'insertLink' ], (this.options.variables ? [ '|', 'variables' ] : null ) ]
                }
            }
        }

        this.editor = new FroalaEditor(this.input, Object.assign({ 
            key: Application.current.platform.keys.froala,

            toolbarBottom: true,
            attribution: false,
            charCounterCount: false,

            htmlAllowedStyleProps: ['font-size', 'color', 'text-align', 'background-color'],

            toolbarVisibleWithoutSelection: false,
            toolbarSticky: false,

            placeholderText: this.options.placeholder,
            
            pastePlain: true,
            pasteImage: false,
            pasteAllowLocalImages: false,
            pasteAllowedStyleProps: [],
            pasteDeniedAttrs: ['class', 'id', 'style'],
            pasteDeniedTags: [ 'h1', 'h2', 'div' ],
            
            quickInsertEnabled: false,
            fileUpload: false,
            linkList: [],
            linkEditButtons: [ 'linkEdit', 'linkRemove' ],
            listAdvancedTypes: false,

            events: {
                'initialized': () => {
                    this.editor.events.on('drop', function (dropEvent) {
                        if (dropEvent.originalEvent.dataTransfer.files.length) {
                            dropEvent.preventDefault();
                            return false;
                        }
                    }, true);
                },
                        
                'contentChanged': () => {
                    if (this.options.onChange) {
                        this.options.onChange(this.value);
                    }
                },

                'commands.after': (cmd, param1, param2) => {
                    if (cmd == 'variables') {
                        if (this.options.onChange) {
                            this.options.onChange(this.value);
                        }
                    }
                },

                'keydown': (keyEvent) => {
                    if (keyEvent.keyCode == 219) {
                        var selection = this.editor.selection.get();
                        if (selection.anchorOffset && selection.anchorNode && selection.anchorNode.wholeText) {
                            var previous = selection.anchorNode.wholeText.substr(selection.anchorOffset - 1, 1);
                            if (previous == '{') {
                                keyEvent.preventDefault();
                                return false;
                            }
                        }
                    }
                }
            }
        }, editorOptions));

        this.defineProperty('value', {
            get: function() { 
                if (this.options.type == 'text/plain') {
                    var element = document.createElement('div');
                    element.innerHTML = this.unprotectVariables(this.editor.html.get().replace(/<(\/p|br)>/g, "\n"));
                    return element.textContent.trim().replace(/\s+/g, ' ');
                }

                return this.fixCommonMistakes(this.unprotectVariables(this.editor.html.get())); 
            },
            set: function(value) { this.input.value = this.protectVariables(value); }
        });
    },

    preventStylesFromKeyboard: function(e) {
        if ((e.metaKey || e.ctrlKey) && (e.keyCode == 66 || e.keyCode == 91)) {
            e.preventDefault();
        }
    },

    fixCommonMistakes: function(input) {
        return input.
            replace(/&nbsp;/g, ' ').
            replace(/<p>\s*<\/p>/g, '').
            replace(/(<br>)*\s*$/g, '');
    },

    protectVariables: function(input) {
        return input.
            replace(/{{(\s*)salon.signature(\s*)}}/g, '{{$1salon.name$2}}').
            replace(/({{[^}]+}})/g, '<span contenteditable=false>$1</span>').
            replace(/ href=(['"])([^\1]*)<span contenteditable=['"]?false['"]?>({{[^}]+}})([^\1]*)<\/span>(\1)/g, ' href=$1$2$3$4$1');
    },

    unprotectVariables: function(input) {
        return input.
            replace(/<span contenteditable=['"]?false['"]?>({{[^}]+}})<\/span>/g, '$1').
            replace(/{{(\s*)salon.name(\s*)}}/g, '{{$1salon.signature$2}}');
    }
});