
Widgets.Tooltip = Class.create(Widgets.Base, {
    name:		'tooltip',
    skeleton:	true,

    deps: 		[ '../core/lib/opentip/opentip' ],
    css: 		[ '../../../core/lib/opentip/opentip.css' ],

    initWidget: function(options) {
        new Opentip(this.internal.container, this.options.contents, this.options);
    }
});
