
CSS.insert(`
    .widget.editableList { text-align: center; }
    .widget.editableList > div { border: 1px solid var(--editablelist-border); border-radius: 4px; overflow: hidden;  }
    .widget.editableList > div > div > ul { text-align: left; list-style-type: none; border-radius: 4px; min-height: 14px; background: var(--editablelist-background);  }
    .widget.editableList.scrollable > div > div  { overflow-y: scroll; }
    body[data-scroll=custom] .widget.editableList.scrollable > div > div  { border-right: 10px solid rgba(0,0,0,0); }
    .widget.editableList > div > div > ul > li { position: relative; border-bottom: 1px solid #eee; padding: 8px 8px 6px 8px; cursor: pointer; }
    .widget.editableList.sortable > div > div > ul > li { padding-left: 28px; }
    .widget.editableList > div > div > ul > li.hasButton { min-height: 28px; padding-right: 36px; }
    .widget.editableList > div > div > ul > li:last-child { border-bottom: none; }
    .widget.editableList > div > div > ul > li > .handle { position: absolute; top: 3px; left: 3px; bottom: 4px; width: 16px; cursor: move; background: repeating-linear-gradient(180deg, #fff, #fff 2px, transparent 2px, transparent 4px), repeating-linear-gradient(90deg, #fff, #fff 2px, #ddd 2px, #ddd 4px); }
    .widget.editableList > div > div > ul > li > .container p { font-size: 0.75em; }
    .widget.editableList > div > div > ul > li > .container h3 { font-size: 1em; }
    .widget.editableList > div > div > ul > li > .container h4 { font-size: 0.75em; }
    .widget.editableList > div > div > ul > li > .container .widget { margin: 0; }
    .widget.editableList > div > div > ul > li > .widget.button { position: absolute; top: 10px; right: 4px; }
    .widget.editableList > div > div > ul > li > .widget.button button { height: 22px; width: 22px; min-width: 22px; line-height: 20px; }
    .widget.editableList > div > div > ul > li > .widget.button button[data-icon]::before { font-size: 11px; margin-left: -6px }
    .widget.editableList > div > div > ul > li.disabled { color: #666; cursor: default;  }
    .widget.editableList > div > div > ul > li.disabled > .widget.button { display: none; }
    .widget.editableList > div > div > ul > li.active { background: #fff; box-shadow: 0px 0px 6px rgba(0,0,0,0.3);  }
    .widget.editableList > .widget.button { border-radius: 0; border: none; margin-top: -1px; }
    .widget.editableList > .widget.button button { border-top-left-radius: 0; border-top-right-radius: 0; color: #666; }
    .widget.editableList > .widget.button button[data-icon]:before { font-size: 13px; margin-left: -2px; }
    .widget.editableList > .widget.button button:disabled { color: var(--button-disabled-text); border-top: 1px solid var(--button-border); }
`);

Widgets.EditableList = Class.create();
Widgets.EditableList.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            className:		null,
            height:			null,
            title:			'',
            maximum:        null,
            sortable:		false,
            position:		'position',
            onHeader:		null,
            onFooter:		null,
            onInitialize:	null,
            onNewItem:		null,
            onEditItem:		null,
            onDeleteItem:	null,
            onSorted:		null,
            canDelete:		null,
            items:			[]
        }, options || {});

        if (typeof parent.container != 'undefined') parent = parent.container;

        this.data = this.options.items;
        this.last = this.data.length;

        this.items = [];

        if (this.options.title) {
            new Widgets.Header(parent, {
                title:	this.options.title
            });
        }

        this.element = new Element('div', { 'class' : 'widget editableList' });
        parent.appendChild(this.element);

        if (this.options.className) {
            this.element.classList.add(this.options.className);
        }

        this.container = new Element('div');
        this.element.appendChild(this.container);

        this.wrapper = new Element('div');
        this.container.appendChild(this.wrapper);

        this.list = new Element('ul');
        this.list.identify();
        this.wrapper.appendChild(this.list);

        if (this.options.height) {
            this.element.classList.add('scrollable');
            this.wrapper.setStyle({
                height:		this.options.height
            });
        }

        if (this.options.onHeader) {
            this.header = new EditableListHeader(this, {
                onInitialize: 	this.options.onHeader
            });
        }

        if (this.options.onFooter) {
            this.footer = new EditableListFooter(this, {
                onInitialize: 	this.options.onFooter
            });
        }

        for (var i = 0; i < this.data.length; i++) {
            if (this.data[i]) {
                this.items[i] = new EditableListItem(this, Object.assign(this.data[i], { 'i': i }), {
                    onInitialize: 	this.options.onInitialize,
                    onEditItem:		this.options.onEditItem ? this.onEditItem.bind(this) : null,
                    onDeleteItem: 	this.options.onDeleteItem ? this.onDeleteItem.bind(this) : null,
                    canDelete: 		this.options.canDelete ? this.canDelete.bind(this) : null,
                    sortable:		this.options.sortable || this.options.onSorted
                });
            }
        }

        if (this.options.sortable || this.options.onSorted) {
            this.createSortable();
        }

        if (this.options.onNewItem) {
            let items = this.items.filter(Boolean);
            this.newButton = new Widgets.Button(this.element, {
                icon:		'plus',
                enabled:    this.options.maximum === null ? true : items.length < this.options.maximum,
                onClick:	this.onNewItem.bind(this)
            });
        }
    },

    refresh: function() {
        for (var i = 0; i < this.items.length; i++) {
            this.items[i].refresh();
        }
    },

    createSortable: function() {
        this.element.classList.add('sortable');

        Sortable.create(this.list, {
            dropOnEmpty:	true,
            hoverclass:		'hover',
            onUpdate:		this.onSort.bind(this),
            handle:			'handle'
        })
    },

    onSort: function() {
        if (this.options.sortable) {
            this.storeOrder();
        }

        if (this.options.onSorted) {
            this.options.onSorted(Sortable.sequence(this.list));
        }
    },

    storeOrder: function() {
        var sequence = Sortable.sequence(this.list);
        for (var s = 0; s < sequence.length; s++) {
            if (typeof this.items[sequence[s]].data[this.options.position] != 'undefined') {
                this.items[sequence[s]].data[this.options.position] = s;
            }
        }
    },

    onNewItem: function() {
        if (this.options.onNewItem) {
            this.options.onNewItem();
        }
    },

    newItem: function(data) {
        this.data[this.last] = data;
        this.items[this.last] = new EditableListItem(this, Object.assign(this.data[this.last], { 'i': this.last }), {
            onInitialize: 	this.options.onInitialize,
            onEditItem: 	this.options.onEditItem ? this.onEditItem.bind(this) : null,
            onDeleteItem: 	this.options.onDeleteItem ? this.onDeleteItem.bind(this) : null,
            canDelete: 		this.options.canDelete ? this.canDelete.bind(this) : null,
            sortable:		this.options.sortable || this.options.onSorted
        });

        this.last++;

        if (this.options.sortable || this.options.onSorted) {
            Sortable.destroy(this.list);
            this.createSortable();
        }

        if (this.options.maximum !== null) {
            let items = this.items.filter(Boolean);
            this.newButton.enabled = items.length < this.options.maximum;
        }
    },

    onEditItem: function(i) {
        if (this.options.onEditItem) {
            this.options.onEditItem(i, this.data[i]);
        }
    },

    editItem: function(i, data) {
        this.data[i] = Object.assign(data, { 'i': i });
        this.items[i].editItem(this.data[i]);
    },

    onDeleteItem: function(i) {
        if (this.options.onDeleteItem) {
            this.options.onDeleteItem(i, this.data[i]);
        }
    },

    canDelete: function(i) {
        if (this.options.canDelete) {
            return this.options.canDelete(this.data[i]);
        }

        return false;
    },

    deleteItem: function(i) {
        this.items[i].deleteItem();
        this.items[i] = null;
        this.data[i] = null;

        if (this.options.maximum !== null) {
            let items = this.items.filter(Boolean);
            this.newButton.enabled = items.length < this.options.maximum;
        }
    },

    getData: function() {
        if (this.options.sortable) {
            this.storeOrder();
        }

        var data = [];

        for (var i = 0; i < this.items.length; i++) {
            if (this.items[i]) {
                data.push(this.items[i].data);
            }
        }

        return data;
    },

    get length() { return this.items.length; },
};

EditableListHeader = Class.create();
EditableListHeader.prototype = {
    initialize: function(parent, options) {
        this.parent = parent;
        this.options = Object.assign({
            onInitialize:	null,
        }, options);

        this.item = new Element('li', { 'id': this.parent.list.id + '_header', 'class': 'header' });
        this.parent.list.appendChild(this.item);

        this.container = new Element('div', { 'class': 'container' });
        this.item.appendChild(this.container);

        this.options.onInitialize(this);
    }
};

EditableListFooter = Class.create();
EditableListFooter.prototype = {
    initialize: function(parent, options) {
        this.parent = parent;
        this.options = Object.assign({
            onInitialize:	null,
        }, options);

        this.item = new Element('li', { 'id': this.parent.list.id + '_footer', 'class': 'footer' });
        this.parent.list.appendChild(this.item);

        this.container = new Element('div', { 'class': 'container' });
        this.item.appendChild(this.container);

        this.options.onInitialize(this);
    }
};

EditableListItem = Class.create();
EditableListItem.prototype = {
    initialize: function(parent, data, options) {
        this.parent = parent;
        this.data = data;
        this.options = Object.assign({
            onInitialize:	null,
            onEditItem:		null,
            onDeleteItem:	null,
            sortable:		false
        }, options);

        this.item = new Element('li', { 'id': this.parent.list.id + '_' + this.data.i });

        if (this.parent.options.onFooter)
            this.parent.list.insertBefore(this.item, this.parent.list.lastChild); //footer.item);
        else
            this.parent.list.appendChild(this.item);

        this.enabled = true;

        if (this.options.sortable) {
            var handle = new Element('div', { 'class': 'handle' });
            handle.observe('click', function(e) { e.stop(); });
            this.item.appendChild(handle);
        }

        if (this.options.onEditItem) {
            this.item.observe('click', this.onEditItem.bind(this));
        }

        if (this.options.onDeleteItem) {
            var enabled = true;

            if (this.options.canDelete) {
                enabled = this.options.canDelete(this.data.i);
            }

            this.item.classList.add('hasButton');

            new Widgets.Button(this.item, {
                icon:		'delete',
                onClick:	this.onDeleteItem.bind(this),
                enabled:	enabled
            });
        }

        this.container = new Element('div', { 'class': 'container' });
        this.item.appendChild(this.container);

        this.options.onInitialize(this, this.data);
    },

    refresh: function() {
        this.container.update(' ');
        this.options.onInitialize(this, this.data);
    },

    enable: function() {
        this.enabled = true;
        this.item.classList.remove('disabled');
    },

    disable: function() {
        this.enabled = false;
        this.item.classList.add('disabled');
    },

    editItem: function(data) {
        this.data = data;

        this.container.update(' ');
        this.options.onInitialize(this, this.data);
    },

    onEditItem: function() {
        if (this.enabled && this.options.onEditItem) {
            this.options.onEditItem(this.data.i);
        }
    },

    deleteItem: function() {
		this.item.remove();
    },

    onDeleteItem: function(e) {
        e.stop();

        if (this.enabled && this.options.onDeleteItem) {
            this.options.onDeleteItem(this.data.i);
        }
    }
};
