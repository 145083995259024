
CSS.insert(`
    .widget.search { position: relative; line-height: normal; }
    .widget.search input { width: 134px; margin: 0; padding: 5px 5px 4px 22px; border-radius: 4px; border: 1px solid #cecece; background: linear-gradient(to bottom, rgb(245,245,245) 0px, rgb(255,255,255) 5px); font-size: 13px; -webkit-appearance: none; }
    .widget.search:after { position: absolute; top: 2px; content: 'z'; display: block; width: 24px; height: 24px; text-indent: 3px; text-align: center; font-family: SalonWidgets; font-size: 12px; line-height: 24px; font-weight: normal; color: #666; }
`);

Widgets.Search = Class.create();
Widgets.Search.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            frequency:	0.4,
            minChars:	1,
            manual:		false,
        }, options || {});

        this.observer = null;

        if (typeof parent.container != 'undefined') parent = parent.container;
        this.container = new Element('div', { 'class': 'widget search' });
        parent.appendChild(this.container);

        this.field = new Element('input', { type: 'text' });
        this.field.name = 'search';
        this.field.setAttribute('inputmode','search');
        this.field.setAttribute('autocomplete','off');
        this.field.setAttribute('spellcheck','false');
        this.field.observe('keypress', this.onKeyPress.bind(this));
        this.field.observe('keydown', this.onKeyDown.bind(this));
        this.field.observe('focus', this.onFocus.bind(this));
        this.field.observe('blur', this.onBlur.bind(this));
        this.field.observe('paste', this.onPaste.bind(this));
        this.container.appendChild(this.field);
    },

    focus: function() {
        this.field.focus();
    },

    onFocus: function() {
        /*
        this.container.setStyle({
            zIndex:	10000
        });
        */
    },

    blur: function() {
        this.field.blur();
    },

    onPaste: function(event) {
        setTimeout(() => { 
            this.onObserverEvent();
        }, 0);
    },

    onBlur: function() {
        /*
        this.container.setStyle({
            zIndex:	0
        });
        */
    },

    onKeyPress: function(event) {
        if (this.options.manual) {
            if (event.keyCode == Event.KEY_RETURN) {
                if (this.options.onQuery) this.options.onQuery(this.field.value);
            }

            return;
        }

        if (this.observer)
            clearTimeout(this.observer);

          this.observer = setTimeout(this.onObserverEvent.bind(this), this.options.frequency * 1000);
    },

    onKeyDown: function(event) {
        if (event.keyCode == Event.KEY_BACKSPACE ||
            event.keyCode == Event.KEY_DELETE)
        {
            if (this.observer)
                clearTimeout(this.observer);

              this.observer = setTimeout(this.onObserverEvent.bind(this), this.options.frequency * 1000);
        }
    },

    onObserverEvent: function() {
        var value = null;

        if (this.field.value.length >= this.options.minChars) {
            value = this.field.value;
        }

        if (this.field.value == '') {
            value = this.field.value;
        }

        if (value != this.last) {
            this.value = value;
            if (this.options.onQuery) this.options.onQuery(value);
        }
    },

    get value() { return this.field.value; },
    set value(value) {
        this.field.value = value;
    }
};
