UserAgent = {

    Chrome() {
        return UserAgent.isToken.apply(this, [ 'Chrome' ].concat(Array.prototype.slice.call(arguments)));
    },

    Edge() {
        return UserAgent.isToken.apply(this, [ 'Edge' ].concat(Array.prototype.slice.call(arguments)));
    },

    Firefox() {
        return UserAgent.isToken.apply(this, [ 'Firefox' ].concat(Array.prototype.slice.call(arguments)));
    },

    Safari() {
        return UserAgent.isPattern.apply(this, [ 'Version\\/([0-9]+\\.[0-9]+).*Safari\\/', 1, null ].concat(Array.prototype.slice.call(arguments)));
    },

    iOS() {
        return UserAgent.isPattern.apply(this, [ '(iphone|ipad|ipod touch); cpu.*os ([0-9]+_[0-9]+)', 2, '_' ].concat(Array.prototype.slice.call(arguments)));
    },




    isToken(token) {
        return UserAgent.isPattern.apply(this, [ ' ' + token + '\\/([0-9]+\\.[0-9])', 1, null ].concat(Array.prototype.slice.call(arguments, 1)));
    },

    isPattern(pattern, index, separator, comparison, required) {
        result = new RegExp(pattern, 'gi').exec(navigator.userAgent);

        if (result) {
            if (comparison, required) {
                return UserAgent.isVersion(parseFloat(separator ? result[index].replace(new RegExp(separator, 'g'), '.') : result[index]), comparison, parseFloat(required))
            }

            return true;
        }

        return false;
    },

    isVersion(found, comparison, required) {
        switch (comparison) {
            case '=':   return found == required;
            case '>':   return found > required;
            case '<':   return found < required;
            case '>=':  return found >= required;
            case '<=':  return found <= required;
        }
    }
}