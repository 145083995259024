CSS.insert(`
	.window.AlertWindow .message { font-weight: var(--font-weight-bold); font-size: 1.1em; line-height: 140%; margin: 0 0 10px; }
	.window.AlertWindow .explaination { color: #000; font-size: 0.8em; font-style: normal; line-height: 130%; overflow-x: hidden; overflow-wrap: anywhere; }
	.window.AlertWindow .explaination ul { margin: 0.5em 0 0 1.2em; }

	.window.AlertWindow ul.warning {
		list-style: none;
		padding: 0;
		margin: 0.5em 0 0 20px;
	}

	.window.AlertWindow ul.warning > li::before {
		content: '⚠';

		position: absolute;
		top: 0;
		left: -20px;

		font-family: SalonIcon, SalonWidgets; 
		font-size: 14px; 
		font-weight: normal; 
		text-transform: none; 
		text-align: center;
		color: var(--colors-orange);
	}
	.window.AlertWindow ul.warning > li {
		position: relative;
		margin: 0;
		padding: 0;
	}
`);

Window.Alert = Class.create();
Window.Alert.prototype = {
	initialize: function(options) {
		this.options = Object.assign({
			message: 		'',
			explaination:	null,
			width:			240,
			button:			{},
			onClose: 		null,
			sound:			null
		}, options || {});

		this.options.button = Object.assign({
			title:		'OK',
			color:		null,
			icon:		null
		}, this.options.button);

		this.window = new Window({
			width: 		this.options.width,
			height: 	'auto',
			modal:		true,
			className: 	'AlertWindow',
			onClose:	this.destroy.bind(this),
			onShow:	    () => {
				this.button.focus();
			}
		});

		this.message = new Element('div', { 'class': 'message' }).update(this.options.message);
		this.window.contents.appendChild(this.message);

		if (this.options.explaination) {
			var explaination = new Element('div', { 'class': 'explaination' }).update(this.options.explaination);
			this.window.contents.appendChild(explaination);
		}

		this.button = new Widgets.Button(this.window.footer, {
			title:		this.options.button.title,
			color:		this.options.button.color,
			icon:		this.options.button.icon,
			onClick:	this.onClick.bind(this)
		});

		if (this.options.sound) {
			Application.playSound(this.options.sound);
		}

		this.window.show();
	},

	destroy: function() {
		if (this.options.onClose) {
			this.options.onClose();
		}
	},

	onClick: function() {
		if (this.options.onClose) {
			this.options.onClose();
		}

		this.window.close();
	}
}