CSS = {
	_buffer: '',
	_request: null,

	insert: function(rules) {
		CSS._buffer += rules;

		if (!CSS._request) {
			CSS._request = window.requestAnimationFrame(() => {
				CSS._process();
			});
		}
	},

	load: function(urls) {
		if (typeof urls === 'string') urls = [ urls ];

		urls.forEach(url => {
			let element = document.createElement('link');
			element.rel = 'stylesheet';
			element.href = url;
			document.head.appendChild(element);
		})
	},

	_process: function() {
		let rules = CSS._buffer;

		CSS._buffer = '';
		CSS._request = null;
		
		if (rules) {
			if (CSSStyleSheet.prototype.replaceSync) {
				let sheet = new CSSStyleSheet();
				sheet.replaceSync(rules);
				document.adoptedStyleSheets = [...document.adoptedStyleSheets, sheet];
			}
			else {
				let element = document.createElement('style');
				element.innerHTML = rules;
				document.head.appendChild(element);
			}
		}
	}
}