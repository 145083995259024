Widgets.Field.Currency = Class.create();
Widgets.Field.Currency.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            value:          null,
            allowNegative:  false,
            onChange:	    null,
            onValidate:     null,
        }, options || {});

        this.toString = (value) => {
            if (value === null || typeof value === 'undefined') {
                return '';
            }

            if (value === '-') {
                return '-';
            }

            let float = parseFloat(String(value).replace(',','.'));

            if (!this.options.allowNegative) {
                float = Math.abs(float);
            }

            if (!isNaN(float)) {
                return Formatter.number(Math.round(float * 100) / 100, 2, false);
            }

            return '';
        }

        this.valueOf = (value) => {
            let float = parseFloat(String(value).replace(',','.'));

            if (!this.options.allowNegative) {
                float = Math.abs(float);
            }

            return !isNaN(float) ? float : null;
        }

        let fieldOptions = {
            hint:       'decimal',
            allow:      this.options.allowNegative ? '1234567890,.-' : '1234567890,.',
            value:      this.toString(this.options.value),
            onFormat:   this.toString
        }

        if (this.options.onChange) {
            fieldOptions.onChange = (value) => this.options.onChange(this.valueOf(value));
        }

        if (this.options.onValidate) {
            fieldOptions.onValidate = (value) => this.options.onValidate(this.valueOf(value));
        }

        this.field = new Widgets.Field (parent, Object.assign({}, this.options, fieldOptions));
    },

    destroy: function() {
        this.field.destroy();
    },

    focus: function() {
        this.field.focus();

        /* 
            If we deliberately focus a formatted field, such as this, we need to
            select the whole text to make sure the user can start typing immediately
            to replace the current text. 
        */

        this.field.select();
    },

    blur: function() {
        this.field.blur();
    },

    get enabled() { return this.field.enabled; },
    set enabled(value) {
        this.field.enabled = value;
    },

    get valid() { return this.field.valid; },

    get value() { return this.valueOf(this.field.value); },
    set value(value) {
        this.field.value = this.toString(value);
    }
};
