CSS.insert(`
    .window.ExplainationWindow .message::before { content: '?'; display: block; position: absolute; top: 0; left: 0; font-family: SalonIcon; font-size: 150%; color: var(--colors-blue-darker); }
    .window.ExplainationWindow .message { position: relative; padding: 0 0 0 36px; font-weight: var(--font-weight-bold); font-size: 1.1em; line-height: 140%; margin: 0 0 10px; }
    .window.ExplainationWindow .explaination { clear: both; font-size: 0.75em; line-height: 130%; font-style: normal; }
    .window.ExplainationWindow .explaination ul { padding: 0.8em 0 0.8em 1.3em; }
    .window.ExplainationWindow .calculation { margin-top: 20px; font-size: 0.75em; line-height: 130%; font-style: normal; }
`);

Window.Explaination = Class.create();
Window.Explaination.prototype = {
initialize: function(options) {
    this.options = Object.assign({
        message: 		'',
        explaination:	null,
        onClose: 		null,
        sound:			null,
        pointer:		null
    }, options || {});

    this.window = new Window({
        width: 		360,
        height: 	'auto',
        pointer:	this.options.pointer,
        className: 	'ExplainationWindow',
        onClose:	this.destroy.bind(this)
    });

    this.message = new Element('div', { 'class': 'message' }).update(this.options.message);
    this.window.contents.appendChild(this.message);

    if (this.options.explaination) {
        var explaination = new Element('div', { 'class': 'explaination' });
        explaination.innerHTML = this.options.explaination;
        this.window.contents.appendChild(explaination);
    }

    if (this.options.calculation) {
        var calculation = new Element('div', { 'class': 'calculation' });
        calculation.innerHTML = this.options.calculation;
        this.window.contents.appendChild(calculation);
    }

    this.window.show();
},

destroy: function() {
    if (this.options.onClose) {
        this.options.onClose();
    }
}
}