
CSS.insert(`
    .widget.field.postalcode > div { position: relative; }
    .widget.field.postalcode.busy > div::after { position: absolute; display: block; content: ''; top: 8px; right: 5px; width: 16px; height: 16px; background: url(${_ROOT}images/loader-small.gif); background-size: 16px 16px; }
    @media (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) { .widget.field.postalcode.busy > div::after { background-image: url(${_ROOT}images/loader-small@2x.gif); } }
`);


Widgets.Field.Postalcode = Class.create();
Widgets.Field.Postalcode.cache = {};
Widgets.Field.Postalcode.prototype = {
    initialize: function(parent, options) {
        this.pattern = /^([0-9]{4,4})\s?([a-zA-Z]{2,2})$/;

        this.options = Object.assign({
            onResult:	null,
            onChange:	null
        }, options || {});

        this.previousOnChange = this.options.onChange;

        if (result = this.pattern.exec(this.options.value)) {
            this.options.value = result[1] + ' ' + result[2].toUpperCase();
        }

        this.field = new Widgets.Field (parent, Object.assign(this.options, {
            className:	'postalcode',
            onChange:	this.onChange.bind(this)
        }));

        this._attached = {};
    },

    attach: function(type, widget) {
        this._attached[type] = widget;

        var originalEventCallback = this._attached[type].options.onChange;

        this._attached[type].options.onChange = function(value) {
            if (originalEventCallback) originalEventCallback(value);
            this.lookup(type);
        }.bind(this);
    },

    destroy: function() {
        this.field.destroy();
    },

    focus: function() {
        this.field.focus();
    },

    blur: function() {
        this.field.blur();
    },

    lookup: function(origin) {
        var postalcode = this.field.value;
        var number = null;
        var extension = null;

        if (postalcode = this.pattern.exec(postalcode)) {
            postalcode = postalcode[1] + ' ' + postalcode[2].toUpperCase();

            if (this._attached.number) {
                number = this._attached.number.value;
            }

            if (this._attached.extension) {
                extension = this._attached.extension.value;
            }

            var url = "https://api.pro6pp.nl/v1/autocomplete"
                    + "?auth_key=" + Application.current.platform.keys.pro6pp
                    + "&nl_sixpp=" + postalcode
                    + (number ? '&streetnumber=' + number : '')
                    + (extension ? '&extension=' + extension : '');

            this.field.container.classList.add('busy');

            if (typeof Widgets.Field.Postalcode.cache[url] == 'undefined') {
                new Ajax.Request(url, { 
                    method: 	'get',
                    evalJSON: 	'force',

                    onSuccess:	function(transport) {
                        if (typeof Widgets.Field.Postalcode.cache[url] == 'undefined') {
                            Widgets.Field.Postalcode.cache[url] = transport.responseJSON;
                        }
                
                        this.onCallback(transport.responseJSON, origin);
                    }.bind(this),

                    onFailure:	function() {
                        this.field.container.classList.remove('busy');
                    }.bind(this)
                });		                
            } else {
                this.onCallback(Widgets.Field.Postalcode.cache[url], origin);
            }
        }
    },

    onCallback: function(data, origin) {
        this.field.container.classList.remove('busy');

        if (data && data.status == 'ok') {
            if (data.results.length) {
                var result = data.results[0];

                if (this.options.onResult) {
                    this.options.onResult({
                        address:	result.street,
                        city:		result.city
                    }, origin);
                }

                if (result = this.pattern.exec(result.nl_sixpp)) {
                    this.field.value = result[1] + ' ' + result[2].toUpperCase();
                }
            }
        }
    },

    onChange: function() {
        this.lookup('postalcode');

        if (this.previousOnChange) {
            this.previousOnChange();
        }
    },

    get value() { return this.field.value; },
    set value(value) {
        this.field.value = value;
    }
};

