FileType = {
	getTypeFromExtension: function(ext) {
		switch(ext) {
			case 'csv': return 'text/csv'; break;
			case 'xml': return 'text/xml'; break;
			case 'xls': return 'application/vnd.ms-excel'; break;
			case 'xlsx': return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; break;
			case 'pdf': return 'application/pdf'; break;
		}
	},

	getNameFromType: function(type) {
		switch(type) {
			case 'text/csv': return 'Comma Seperated Values'; break;
			case 'text/xml': return 'XML bestand'; break;
			case 'application/vnd.ms-excel': return 'Microsoft Excel'; break;
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': return 'Microsoft Excel'; break;
			case 'application/pdf': return 'Adobe Acrobat'; break;
			default: return 'Bestand'; break;
		}
	},

	getExtensionFromType: function(type) {
		switch(type) {
			case 'text/csv': return 'csv'; break;
			case 'text/xml': return 'xml'; break;
			case 'application/vnd.ms-excel': return 'xls'; break;
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': return 'xlsx'; break;
			case 'application/pdf': return 'pdf'; break;
			default: return ''; break;
		}
	}
};