
class Color {

    constructor(value) {
        let matches = value.match(/^#?([0-9a-f]{6})$/i);
        if (matches) {
            this.value = {
                r: parseInt(matches[1].substr(0, 2), 16),
                g: parseInt(matches[1].substr(2, 2), 16),
                b: parseInt(matches[1].substr(4, 2), 16)
            };
        }
    }

    isDark() {
		const yiq = (this.value.r * 299 + this.value.g * 587 + this.value.b * 114) / 1000;
		return yiq < 128;
    }

    darken(factor) {
        this.value.r = Math.round(this.value.r * (1 - factor));
        this.value.g = Math.round(this.value.g * (1 - factor));
        this.value.b = Math.round(this.value.b * (1 - factor));
        
        return this;
    }

    toHex() {
        return '#' + [this.value.r, this.value.g, this.value.b].map(x => {
            const hex = x.toString(16)
            return hex.length === 1 ? '0' + hex : hex
        }).join('')
    }
}