
CSS.insert(`
    .widget.dateField { line-height: initial; } 
    .widget.dateField label { font-size: 0.8em; display: inline-block; } 
    .widget.dateField > div { position: relative; -webkit-user-select: none; } 
    .widget.dateField.disabled > div { opacity: 0.7; } 
    .widget.dateField div.control { display: inline-block; vertical-align: top; box-sizing: border-box; margin: 0; padding: 0; height: 27px; border: 1px solid var(--field-border); background: var(--field-background); font-size: 13px; } 
    .widget.dateField div.control input { border: none; background: transparent; font-size: 13px; width: 16px; padding: 4px 2px; text-align: right; line-height: 17.2px /* -webkit-user-select: all*/ } 
    .widget.dateField div.control input.dayField { padding-left: 5px; } 
    .widget.dateField div.control input.yearField { width: 34px; padding-right: 5px; } 
    .widget.dateField.invalid div.control { border-color: var(--field-error-border); background: var(--field-error-background); } 
    .widget.dateField button { position: relative; display: inline-block; vertical-align: top; height: 27px; width: 20px; line-height: 27px; cursor: pointer; border-top: 1px solid var(--button-border); border-left: none; border-bottom: 1px solid var(--button-border); border-right: 1px solid var(--button-border); border-bottom-right-radius: 4px; border-top-right-radius: 4px; background: var(--button-background); color: #666; } 
    .widget.dateField button::before { position: absolute; top: 0; left: 4px; display: block; content: '⌵'; font-family: SalonWidgets; font-size: 12px; } 
    .widget.dateField button.enabled:hover { background: linear-gradient(to top, rgb(222,222,222) 16%, rgb(245,245,245) 100%); } 
    .widget.dateField button:active { background: #eaeaea; } 
    .widget.dateField .widget.dateFieldPanel { display: none; } 
    .widget.dateFieldPanel { position: absolute; z-index: 5000; display: block; border: 1px solid rgba(0,0,0,0.2); border-radius: 5px; clear: both; margin: 0; padding: 3px 10px 0 3px; background: #fff; color: #000; line-height: 120%; font-size: 16px; box-shadow: 0 0 6px rgba(0,0,0,0.25); outline: none;  } 

    [data-input-mode=mouse] .widget.dateField button { outline: none; }
    
    .widget.dateField[data-style=columns] > label { display: block; width: 100px; vertical-align: top; margin: 8px 0 0; } 
    .widget.dateField[data-style=columns] > div { display: block; flex: 1; vertical-align: top; margin: 0 12px; } 
    .widget.dateField[data-style=columns] :last-child { margin-right: 0; } 
    .widget.dateField[data-style=columns] { display: flex; flex-direction: row; justify-content: space-between; } 
    .widget.dateField[data-style=columns] > div span.description { display: block; font-size: 0.7em; color: #888; margin-top: 6px; } 
    
    .widget.dateField[data-style=inline] > div { display: block; flex: 1; vertical-align: top; } 
    .widget.dateField[data-style=inline] :last-child { margin-right: 0; } 
    .widget.dateField[data-style=inline] { display: flex; flex-direction: row; justify-content: space-between; } 
`);

Widgets.Field.Date = Class.create();
Widgets.Field.Date.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            name:			null,
            className:		null,
            style:			null,
            label: 			null,
            description:	null,
            allow:			null,
            onChange:		null,
            value:			null,
            enabled:        true,
            minimum:		null,
            maximum:		null,
            required:       false,
            frequency:		0.4,
            minChars:		1
        }, options || {});

        this._format = 'moment';
        if (this.options.value instanceof Date) this._format = 'date';
        if (this.options.value instanceof String) this._format = 'string';

        this._value = this.options.value ? moment(this.options.value) : null;
        this._complete = this.options.value ? true : false;
        this._valid = this.options.value ? true : false;
        
        this.opened = false;
                    
        if (typeof parent.container != 'undefined') parent = parent.container;
        this.container = new Element('div', { 'class': 'widget dateField' });
        parent.appendChild(this.container);
        
        if (this.options.name) {
            this.container.dataset.name = this.options.name;
        }

        if (this.options.style) {
            this.container.dataset.style = this.options.style;
        }

        if (this.options.label) {
            this.label = new Element('label').update(this.options.label);
            this.container.appendChild(this.label);
        }
        
        if (this.options.className) {
            this.container.classList.add(this.options.className);
        }

        if (!this.options.enabled) {
            this.container.classList.add('disabled');
        }


        this.wrapper = new Element('div');
        this.container.appendChild(this.wrapper);
        
        this.control = new Element('div', { 'class': 'control' });
        this.wrapper.appendChild(this.control);
        
        this.day = new Element('input', { 'class': 'dayField', type: 'text', value: this._value ? this._value.date() : '' });
        this.day.setAttribute('inputmode', 'numeric');
        this.day.disabled = ! this.options.enabled;
        if (this.options.enabled) {
            this.day.observe('keypress', this.onKeyPress.bindAsEventListener(this));
            this.day.observe('keydown', this.onKeyDown.bindAsEventListener(this));
        }

        this.control.appendChild(this.day);

        this.control.appendChild(new Element('span').update('-'));

        this.month = new Element('input', { 'class': 'monthField', type: 'text', value: this._value ? this._value.month() + 1 : '' });
        this.month.setAttribute('inputmode', 'numeric');
        this.month.disabled = ! this.options.enabled;
        if (this.options.enabled) {
            this.month.observe('keypress', this.onKeyPress.bindAsEventListener(this));
            this.month.observe('keydown', this.onKeyDown.bindAsEventListener(this));
        }

        this.control.appendChild(this.month);

        this.control.appendChild(new Element('span').update('-'));

        this.year = new Element('input', { 'class': 'yearField', type: 'text', value: this._value ? this._value.year() : '' });
        this.year.setAttribute('inputmode', 'numeric');
        this.year.disabled = ! this.options.enabled;
        if (this.options.enabled) {
            this.year.observe('keypress', this.onKeyPress.bindAsEventListener(this));
            this.year.observe('keydown', this.onKeyDown.bindAsEventListener(this));
        }

        this.control.appendChild(this.year);
        
        this.button = new Element('button');
        this.button.enabled = this.options.enabled;
        if (this.options.enabled) {
            this.button.observe('click', this.toggle.bindAsEventListener(this));
        }

        this.wrapper.appendChild(this.button);

        if (this.options.description) {
            this.description = new Element('span', { 'class': 'description' }).update(this.options.description);
            this.wrapper.appendChild(this.description);
        }


        document.body.observe('click', this.cancel.bindAsEventListener(this))

        this.panel = new Element('div', { 'class': 'widget dateFieldPanel' });
        this.panel.tabIndex = -1;
        this.panel.observe('click', function(e) { e.stop(); });
        this.panel.observe('keyup', function(e) { if (e.key == 'Escape') { this.close(); }}.bind(this));
        this.wrapper.appendChild(this.panel);

        this.calendar = new Widgets.Calendar(this.panel, { 
            showWeekNumber: true, 
            showControls: true, 
            showYearNavigation: true, 
            date: this._value ? this._value : null, 
            active: this._value ? this._value : null, 
            minDate: this.options.minimum ? moment(this.options.minimum).subtract(1, 'days') : null,        /* There is a bug in Widgets.Calendar, where minDate is not included in the valid range to be selected */
            maxDate: this.options.maximum ? moment(this.options.maximum) : null
        });

        this.calendar.addEventListener("dayclick", this.onSelect.bind(this));
    },

    destroy: function() {
        this.day.stopObserving();
        this.month.stopObserving();
        this.year.stopObserving();
        this.container.remove();
    },

    toggle: function(e) {
        e.stop();
        
        this[this.opened ? 'close' : 'open']();
    },

    open: function() {
        if (!this.options.enabled) {
            return;
        }

        this.opened = true;
        
        var pos = this.wrapper.viewportOffset();
        
        this.panel.setStyle({
            top:	pos.top + 'px',
            left:	pos.left + 'px'
        });
        
        document.body.appendChild(this.panel);

        this.panel.focus();
    },

    cancel: function() {
        this.opened = false;
        this.wrapper.appendChild(this.panel);
    },

    close: function() {
        this.opened = false;
        this.wrapper.appendChild(this.panel);

        this.button.focus();
    },

    focus: function() {
        if (!this.options.enabled) {
            return;
        }

        this.day.focus();
    },

    blur: function() {
        this.day.blur();
        this.month.blur();
        this.year.blur();
    },

    getPreviousField: function(field) {
        switch(field) {
            case this.day:		return null;
            case this.month:	return this.day;
            case this.year:		return this.month;			
        }

        return null;
    },

    getNextField: function(field) {
        switch(field) {
            case this.day:		return this.month;
            case this.month:	return this.year;
            case this.year:		return null;			
        }
        
        return null;
    },

    onSelect: function(date) {
        this.calendar.setActive(moment(date));
        this.value = moment(date);
        
        if (this.options.onChange) {
            this.options.onChange(this.value);
        }
        
        this.close();
    },

    onKeyDown: function(event) {
        if (event.keyCode == Event.KEY_TAB) {
            this.onCheck();
        }

        if (event.keyCode == Event.KEY_BACKSPACE) {
            if (event.target == this.year || event.target == this.month) {
                if (event.target.value == '') {
                    event.stop();

                    var previous = this.getPreviousField(event.target);
                    previous.focus();
                    previous.value = previous.value.substring(0, previous.value.length - 1);
                    previous.selectionStart = previous.selectionEnd = previous.value.length;
                }				

                this.onCheck();
            }
        }
        
        if (event.keyCode == Event.KEY_LEFT) {
            if (event.target == this.year || event.target == this.month) {
                if (event.target.selectionStart == 0 && !event.targetSelectionEnd) {
                    event.stop();

                    var previous = this.getPreviousField(event.target);
                    previous.focus();
                    previous.selectionStart = previous.selectionEnd = previous.value.length;
                }				

                this.onCheck();
            }
        }
        
        if (event.keyCode == Event.KEY_RIGHT) {
            if (event.target == this.day || event.target == this.month) {
                if (event.target.selectionStart == event.target.value.length && !event.targetSelectionEnd) {
                    event.stop();

                    var next = this.getNextField(event.target);
                    next.focus();
                    next.selectionStart = next.selectionEnd = 0;
                }	
                
                this.onCheck();
            }
        }

        if (this.observer) 
            clearTimeout(this.observer);
    
        this.observer = setTimeout(this.onCheck.bind(this), this.options.frequency * 1000);
    },

    onKeyPress: function(event) {
        if (String('-/').indexOf(String.fromCharCode(event.charCode)) >= 0) {
            event.stop();
            
            if (event.target == this.day || event.target == this.month) {
                var next = this.getNextField(event.target);
                
                next.focus();
                next.selectionStart = 0;
                next.selectionEnd = next.value.length;
            }
            
            return;
        }

        if (String('0123456789').indexOf(String.fromCharCode(event.charCode)) < 0) {
            event.stop();
            return;
        }

        if (event.target == this.day) {
            if (this.day.value.length == 2 && this.day.selectionStart == this.day.selectionEnd) {
                event.stop();
                
                this.month.focus();
                this.month.value = String.fromCharCode(event.charCode);
                return;
            }
        }
        
        if (event.target == this.month) {
            if (this.month.value.length == 2 && this.month.selectionStart == this.month.selectionEnd) {
                event.stop();

                this.year.focus();
                this.year.value = String.fromCharCode(event.charCode);
                return;
            }
        }
        
        if (event.target == this.year) {
            if (this.year.value.length == 4 && this.year.selectionStart == this.year.selectionEnd) {
                event.stop();
                return;
            }
        }
        
        if (this.observer) 
            clearTimeout(this.observer);
    
        this.observer = setTimeout(this.onCheck.bind(this), this.options.frequency * 1000);
    },

    onCheck: function() {
        this._complete = false;

        var value = null;
        var valid = false;
        
        var day = parseInt(this.day.value, 10);
        var month = parseInt(this.month.value, 10);
        var year = parseInt(this.year.value, 10);

        if (day > 0 && day < 32 && month > 0 && month < 13 && year > 1900 && year < 2100) {
            var date = moment({ year: year, month: month - 1, day: day });
            if (date.isValid()) {
                valid = true;
                value = date;
            }

            this._complete = true;
        }

		if (this.options.maximum && value) {
			if (value.isAfter(moment(this.options.maximum), 'day')) {
				valid = false;
			}
		}
		
		if (this.options.minimum && value) {
			if (value.isBefore(moment(this.options.minimum), 'day')) {
				valid = false;
			}
        }

        if (this.options.required && !value) {
            valid = false;
        }
        
        if (valid) {
            this.container.classList.remove('invalid');
        } else {
            this.container.classList.add('invalid');
        }

        this.valid = valid;

        if (value != this._value) {
            this._value = value;

            this.calendar.rebuildTable(value ? value : moment());
            this.calendar.setActive(value ? value: null);
            
            if (this.options.onChange) {
                this.options.onChange(this.value);
            }
        }
    },

    get valid() { return this._valid; },
    set valid(value) {
        this._valid = value;
    },

    get value() { 
        if (!this._valid) return null;

        if (this._format == 'date') return this._value.toDate();
        if (this._format == 'string') return this._value.format('YYYY-MM-DD');
        return this._value;
    },
    set value(value) { 
        this._format = 'moment';
        if (value instanceof Date) this._format = 'date';
        if (value instanceof String) this._format = 'string';

        this._value = moment(value);

        this.day.value = this._value.date();
        this.month.value = this._value.month() + 1;
        this.year.value = this._value.year();

        this.calendar.rebuildTable(this._value);
        this.calendar.setActive(this._value);

        this._complete = true;
        this.valid = true;
    }
};	
