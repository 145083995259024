
CSS.insert(`
    .widget.label { display: flex; flex-direction: row; justify-content: space-between; margin-bottom: 0; }
    .widget.label > label { display: block; width: 100px; vertical-align: top; margin: 8px 0 0; font-size: 0.8em; }
    .widget.label > div {  display: block; flex: 1; vertical-align: top; margin: 0 0 0 12px; }
    .widget.label div.box > div.content { display: inline-block; vertical-align: top; margin: 8px 8px 0; font-size: 0.8em; }
    .widget.label div.box:first-child > div.content { margin-left: 0; }
    .widget.label div.box:last-child > div.content { margin-right: 0; }
`);

Widgets.Label = Class.create();
Widgets.Label.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            name:           null,
            className:		null,
            label: 			null,
            items:			null,
            pack:			'justify',
        }, options || {});

        if (typeof parent.container != 'undefined') parent = parent.container;
        this.element = new Element('div', { 'class': 'widget label' });
        parent.appendChild(this.element);

		if (this.options.name) {
			this.element.dataset.name = this.options.name;
		}

        if (this.options.label) {
            this.label = new Element('label').update(this.options.label);
            this.element.appendChild(this.label);
        }

        if (this.options.className) {
            this.element.classList.add(this.options.className);
        }

        this.container = new Element('div');
        this.element.appendChild(this.container);

        if (this.options.items) {
            var flex = new Layout.Flex(this.container, {
                orientation:	'horizontal',
                pack:			this.options.pack,
                items:			this.options.items
            })

            this.items = flex.items;

            for (var i = 0; i < this.options.items.length; i++) {
                if (typeof this.options.items[i].value != 'undefined') {
                    this.items[this.options.items[i].name || 'unnamed-' + i].innerHTML = "<div class='content'>" + this.options.items[i].value + "</div>";
                }
            }
        }
    },

    destroy: function() {
        this.element.remove();
    }
};

