CSS.insert(`
    .overlay { 
        --header-height: calc(var(--titlebar-height) + var(--navigation-height) + var(--banner-height));

        background: rgba(0,0,0,0); 
        -webkit-backface-visibility: hidden; 
    }
    .overlay:focus { 
        outline: none; 
    }

    .window[data-stack="0"] + .overlay,
    .widget.context + .overlay { 
        background: var(--overlay-backdrop);
    }

    body.overlay .window[data-stack="0"] + .overlay,
    body.overlay .widget.context + .overlay,
    body.overlay #sidebar.visible + .overlay { 
        background: linear-gradient(
            to bottom, 
            transparent var(--header-height), 
            var(--overlay-backdrop) var(--header-height)
        );
    }
        
    body.overlay.Capacitor.iOS .window[data-stack="0"] + .overlay,
    body.overlay.Capacitor.iOS .widget.context + .overlay,
    body.overlay.Capacitor.iOS #sidebar.visible + .overlay { 
        background: linear-gradient(
            to bottom, 
            transparent var(--header-height), 
            var(--overlay-backdrop) var(--header-height), 
            calc(100% - var(--footer-height)), 
            transparent calc(100% - var(--footer-height))
        );
    }

    body.overlay .window[data-stack="0"] + .overlay[data-stack="1"],
    body.overlay .widget.context + .overlay[data-stack="1"] { 
        background: none !important;
    }

`);

var Overlay = Class.create();
Overlay.count = 0;
Overlay.prototype = {

    initialize: function(options) {
        this.options = Object.assign({
            animate:	true,
            onHide:		null,
            zIndex:		999,
            owner:      null
        }, options || {});

        this.destroyed = false;

        // Overlay with opacity
        this.overlay = new Element("div", { className: 'overlay' });
        this.overlay.tabIndex = -1;
        this.overlay.dataset.stack = Overlay.count;
        this.overlay.addEventListener('focus', this.onFocus.bind(this));
        this.overlay.hide();

        if (this.options.owner) {
            this.options.owner.insertAdjacentElement('afterend', this.overlay);
        }
        else {
            document.body.appendChild(this.overlay);
        }

        Overlay.count++;

        window.setTimeout(() => {
            let height = '100vh';
            let width = '100vw';

            if (System.Type.Mobile && !System.Type.Tablet) {
                height = document.body.clientHeight + 'px';
                width = '1000px'
            }

            this.overlay.setStyle({
                position: 			'absolute',
                height: 			height,
                width: 				width,
                left: 				'0px',
                top: 				'0px',
                zIndex: 			this.options.zIndex
            });

            this.overlay.show();

            if (Overlay.count == 1) {
                if (typeof Application != "undefined" && Application.enableOverlay) {
                    Application.enableOverlay();
                } else {
                    document.body.classList.add('overlay');
                }
            }    
        }, 0)

        this.startInspection();

        window.setTimeout(function() {
            if (this.options.onHide) {
                this.overlay.observe('click', this.options.onHide);
            }
        }.bind(this), 300);
    },

    resize: function() {
        var size = document.body.getBoundingClientRect();
        this.overlay.style.height = size.height + 'px';
    },

    destroy: function() {
        if (this.destroyed) {
            return;
        }

        this.destroyed = true;

        this.stopInspection();

        if (this.overlay.parentNode) this.overlay.remove();

        Overlay.count--;

        if (Overlay.count == 0) {
            if (typeof Application != "undefined" && Application.disableOverlay) {
                Application.disableOverlay();
            }
            else {
                document.body.classList.remove('overlay');
            }
        }
    },


    startInspection: function() {
        window.addEventListener('resize', this.viewPortHasResized.bind(this));
        window.addEventListener('orientationchange', this.viewPortHasRotated.bind(this));
    },

    stopInspection: function() {
        window.removeEventListener('resize', this.viewPortHasResized.bind(this));
        window.removeEventListener('orientationchange', this.viewPortHasRotated.bind(this));
    },

    viewPortHasResized: function() {
        this.resize();
    },

    viewPortHasRotated: function() {
        this.resize();
    },

    onFocus: function() {
        let window = Window.list[Window.list.length - 1];
        if (window) {
            window.focus();
        }
    },

    set onHide(value) {
        if (this.options.onHide) {
            this.overlay.stopObserving('click', this.options.onHide);
        }

        this.options.onHide = value;

        if (this.options.onHide) {
            this.overlay.observe('click', this.options.onHide);
        }
    }
};
