
CSS.insert(`
    .widget.field.code {
        margin: 0;
    }

    .widget.field.code label {
        margin-bottom: 14px;
    }

    .widget.field.code input { 
        opacity: 0.0001;
        height: 40px;
        position: absolute;
        cursor: pointer;
    }

    .widget.field.code .digits {
        display: flex;
        justify-content: space-between;
    }

    .widget.field.code .digits div {
        width: 40px;
        height: 40px;
        border: 1px solid var(--field-border);
        background: var(--field-background);
        border-radius: 5px;
        margin: 1px;
        text-align: center;
        line-height: 34px;
        font-size: 36px;
    }

    .widget.field.code input:focus + .digits[data-digits='0'] div.one,
    .widget.field.code input:focus + .digits[data-digits='1'] div.two,
    .widget.field.code input:focus + .digits[data-digits='2'] div.three,
    .widget.field.code input:focus + .digits[data-digits='3'] div.four,
    .widget.field.code input:focus + .digits[data-digits='4'] div.four {
            border: 2px solid var(--colors-named-focus);
        margin: 0;
    }

    .widget.field.code .digits[data-digits='1'] div.one::after,
    .widget.field.code .digits[data-digits='2'] div.one::after,
    .widget.field.code .digits[data-digits='3'] div.one::after,
    .widget.field.code .digits[data-digits='4'] div.one::after {
        content: '•';
    }

    .widget.field.code .digits[data-digits='2'] div.two::after,
    .widget.field.code .digits[data-digits='3'] div.two::after,
    .widget.field.code .digits[data-digits='4'] div.two::after {
        content: '•';
    }

    .widget.field.code .digits[data-digits='3'] div.three::after,
    .widget.field.code .digits[data-digits='4'] div.three::after {
        content: '•';
    }

    .widget.field.code .digits[data-digits='4'] div.four::after {
        content: '•';
    }

`);

Widgets.CodeField = Class.create();
Widgets.CodeField.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            className:		null,
            style:			null,
            label: 			null,
            description:	null,
            onChange:		null,
            onEnter:		null,
            onComplete:     null,
            value:			'',
            frequency:		0.4,
            minChars:		1
        }, options || {});

        this._value = this.options.value != null ? String(this.options.value) : '';
        this._valid = true;

        if (typeof parent.container != 'undefined') parent = parent.container;
        this.container = new Element('div', { 'class': 'widget field code' });
        parent.appendChild(this.container);

        if (this.options.style) {
            this.container.classList.add(this.options.style + 'Style');
        }

        if (this.options.label) {
            this.label = new Element('label').update(this.options.label);
            this.container.appendChild(this.label);
        }

        if (this.options.className) {
            this.container.classList.add(this.options.className);
        }


        var wrapper = new Element('div');
        this.container.appendChild(wrapper);

        this.field = new Element('input', { type: 'text', value: this._value, maxLength: 4 });
        this.field.setAttribute('inputmode', 'numeric');
        this.field.autocomplete = 'off';
        this.field.observe('click', () => this.focus());
        this.field.observe('keypress', this.onKeyPress.bindAsEventListener(this));
        this.field.observe('keyup', this.onKeyUp.bindAsEventListener(this));
        this.field.observe('keydown', (event) => {
            if (event.keyCode === 37 || event.keyCode === 39) {
                event.preventDefault();
            }
        });

        wrapper.appendChild(this.field);

        this.digits = new Element('div', { 'class': 'digits' });
        this.digits.observe('click', () => this.focus());
        this.digits.dataset.digits = 0;
        this.digits.innerHTML = `
            <div class='one'></div>
            <div class='two'></div>
            <div class='three'></div>
            <div class='four'></div>
        `;

        wrapper.appendChild(this.digits);

        if (this.options.description) {
            this.description = new Element('span', { 'class': 'description' }).update(this.options.description);
            wrapper.appendChild(this.description);
        }
    },

    destroy: function() {
        this.field.stopObserving();
        this.container.remove();
    },

    focus: function() {
        this.field.focus();
        this.field.selectionStart = this.field.value.length;
        this.field.selectionEnd = this.field.value.length;
    },

    blur: function() {
        this.field.blur();
    },

    onKeyPress: function(event) {
        if (event.keyCode == Event.KEY_RETURN) {
            if (this.options.onEnter) {
                this.options.onEnter();
            }
        }

        if (String('01234567890').indexOf(String.fromCharCode(event.charCode)) < 0) {
            event.stop();
            return;
        }

        if (this.observer)
            clearTimeout(this.observer);

        this.observer = setTimeout(this.onObserverEvent.bind(this), this.options.frequency * 1000);
    },

    onKeyUp: function() {
        this.digits.dataset.digits = this.field.value.length;
    },

    onObserverEvent: function() {
        var value = this.field.value;

        if (value != this.last) {
            this._value = this.field.value;

            if (this.field.value.length == 4) {
                if (this.options.onComplete) {
                    this.options.onComplete(this.value);
                }
            }

            if (this.options.onChange) {
                this.options.onChange(this.value);
            }
        }
    },

    get value() { return this._valid ? this._value : null; },
    set value(value) {
        this._value = value;
        this.field.value = value;
        this.digits.dataset.digits = value.length;
    }
};
