Widgets.Loader = Class.create(Widgets.Base, {
    name:		'loading',

    css: `
        .widget.loading { height: 100px; background-image: url(${_ROOT}images/loader-regular.gif); background-repeat: no-repeat; background-position: center center; background-size: 24px 24px; position: relative; }
        @media (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) { .widget.loading { background-image: url(${_ROOT}images/loader-regular@2x.gif); } }
        .widget.loading div { position: relative; top: 50%; padding-top: 30px; text-align: center; text-transform: var(--font-transform); color: #777; }
    `,

    initWidget: function() {
        if (this.options.height) {
            var height = this.options.height;

            if (height == 'window') {
                height = window.innerHeight - 100 + 'px';
            }

            this.setStyle({ height: height });
        }

        if (this.options.title) {
            this.title = new Element('div').update(this.options.title);
            this.insert(this.title);
        }
    },

    close: function() {
        this.destroy();
    }
});